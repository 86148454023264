import { useEffect, useState } from 'react';
import { useExecutePackage, useGenerateResource } from '@data/hooks';
import { WarningOctagonFilled } from '@sharefiledev/icons';
import { sendPendoTrackEvent } from '@tracking';
import { useResourceGenContext } from '@utils/context/useResourceGenerationContext';
import { t } from '@utils/localization';
import { Alert, Button, Flex, Space, theme } from 'antd';
import axios from 'axios';
import { AiSparklesBanner } from '../AiSparklesBanner/AiSparklesBanner';
import { PackageEditor } from '../PackageEditor/PackageEditor';
import { AiCaution } from './AiCaution';
import { GenerationProgress } from './GenerationProgress';
import { UserFeedback } from './UserFeedback';

export const ReviewPage = () => {
	const { token } = theme.useToken();

	const [editedYaml, setEditedYaml] = useState('');

	const {
		onClose,
		onNavigate,
		containerRID: targetRID,
		generationRequest,
		setPage,
		resourceType,
		setGenerationTaskId,
	} = useResourceGenContext();

	const {
		yaml,
		generationTaskId,
		loading: loadingGeneration,
		error: generationError,
		refetch: retryGeneration,
	} = useGenerateResource(generationRequest);

	const [isInvalidGeneratedContent, setIsInvalidGeneratedContent] = useState(false);

	const {
		loading: executingPackage,
		executePackage,
		packageExecutionError,
	} = useExecutePackage({
		onError: (_e: Error) => {},
		onSuccess: (rid: string) => {
			onClose();
			onNavigate(rid);
		},
		packageYamlDefinition: editedYaml,
		resourceRef: 'generatedResource',
		targetRID,
	});

	useEffect(() => {
		setGenerationTaskId(generationTaskId);
	}, [generationTaskId, setGenerationTaskId]);

	return (
		<Flex
			vertical
			justify="space-between"
			style={{ height: '100%', overflow: 'inherit' }}
		>
			{!loadingGeneration && (
				<Flex vertical gap={token.sizeLG} style={{ height: '100%', overflow: 'inherit' }}>
					{!!!packageExecutionError &&
						!!!generationError &&
						!!!isInvalidGeneratedContent && (
							<AiSparklesBanner>
								{executingPackage ? (
									<>
										{t('use_resourcegen_drawer.sharefile_requestlist.creation_progress')}
									</>
								) : (
									<>{t('use_resourcegen_drawer.sharefile_requestlist.review_banner')}</>
								)}
							</AiSparklesBanner>
						)}
					{
						<Flex vertical style={{ overflow: 'auto', height: '100%' }} gap={token.size}>
							{(!!generationError || isInvalidGeneratedContent) &&
								(axios.isAxiosError(generationError) &&
								generationError.response.status === 429 ? (
									<Alert
										message={t(
											'use_resourcegen_drawer.sharefile_requestlist.rate_limit_error.message'
										)}
										description={t(
											'use_resourcegen_drawer.sharefile_requestlist.rate_limit_error.description'
										)}
										type="error"
										icon={<WarningOctagonFilled />}
										showIcon
										action={
											<Space>
												<Button
													type="primary"
													danger
													size="large"
													onClick={() => {
														setIsInvalidGeneratedContent(false);
														retryGeneration();
													}}
												>
													{t('common.retry')}
												</Button>
											</Space>
										}
									/>
								) : (
									<Alert
										message={t(
											'use_resourcegen_drawer.sharefile_requestlist.generation_error.message'
										)}
										description={t(
											'use_resourcegen_drawer.sharefile_requestlist.generation_error.description'
										)}
										type="error"
										icon={<WarningOctagonFilled />}
										showIcon
										action={
											<Space>
												<Button
													type="primary"
													danger
													size="large"
													onClick={() => {
														setIsInvalidGeneratedContent(false);
														retryGeneration();
													}}
												>
													{t('common.retry')}
												</Button>
											</Space>
										}
									/>
								))}
							{!executingPackage && !!packageExecutionError && (
								<Alert
									message={t(
										'use_resourcegen_drawer.sharefile_requestlist.creation_error.message'
									)}
									description={t(
										'use_resourcegen_drawer.sharefile_requestlist.creation_error.description'
									)}
									type="error"
									icon={<WarningOctagonFilled />}
									showIcon
									action={
										<Space>
											<Button type="primary" danger size="large" onClick={executePackage}>
												{t('common.retry')}
											</Button>
										</Space>
									}
								/>
							)}
							{!!!generationError && (
								<PackageEditor
									disabled={loadingGeneration || executingPackage}
									errorComponent={() => {
										return null;
									}}
									onUpdate={payload => {
										if (!!payload.editorState.error) {
											setIsInvalidGeneratedContent(true);
										} else {
											setEditedYaml(payload.editorState.computedYamlPackage);
											setIsInvalidGeneratedContent(false);
										}
									}}
									packageYamlDefinition={yaml}
								/>
							)}
						</Flex>
					}
				</Flex>
			)}
			{loadingGeneration && !!!generationError && (
				<GenerationProgress onCancel={() => setPage('form')} />
			)}
			<Flex
				vertical
				style={{ height: '100%', flexBasis: 'max-content', paddingTop: token.sizeLG }}
				gap={token.sizeLG}
			>
				{!loadingGeneration && (
					<>
						<UserFeedback
							resourceType={resourceType}
							onBack={() => {
								setPage('form');
							}}
						/>
						<AiCaution />
					</>
				)}
				<Flex
					gap={token.sizeXS}
					style={{ borderTop: `1px solid ${token.colorSplit}`, paddingTop: token.size }}
				>
					<Button
						type="primary"
						onClick={() => {
							executePackage();
							sendPendoTrackEvent({
								eventKey: 'createResourceAction',
								metadata: {
									resourceType,
								},
							});
						}}
						loading={executingPackage}
						disabled={
							loadingGeneration ||
							!!generationError ||
							isInvalidGeneratedContent ||
							executingPackage
						}
					>
						{t('use_resourcegen_drawer.sharefile_requestlist.create_btn')}
					</Button>
					<Button onClick={onClose}>{t('common.cancel')}</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
