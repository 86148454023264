import React from 'react';
import { convertCustomOrLocaleKeyToLabel } from '@common/hooks/useCustomAndSearchableTextSelectionOptions';
import { ShareFileSpinner } from '@common/icons';
import { GeneratableResourceType, GenerationFormParams, GenerationParams } from '@sdk';
import { useQuery } from '@tanstack/react-query';
import { sendPendoTrackEvent } from '@tracking';
import { useResourceGenContext } from '@utils/context/useResourceGenerationContext';
import { t } from '@utils/localization';
import { Button, Flex, Form, theme } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AiSparklesBanner } from '../AiSparklesBanner/AiSparklesBanner';
import { AiCaution } from './AiCaution';
import CreateRequestListInformationForm from './GenerationForm/GenerationForm';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

interface FormPageProps {}

export const persistedDataKey = (resourceType: GeneratableResourceType) =>
	`resourcegen-pilet-formdata-create-${resourceType}`;

type PersistedFormFieldMap<T extends GeneratableResourceType> = Record<
	T,
	(keyof GenerationFormParams<T>)[]
>;

const persistedFormFields: PersistedFormFieldMap<GeneratableResourceType> = {
	'sharefile:requestlist': [
		'remember_industry_role',
		'employment_industry_locale_key_or_custom',
		'employment_role_locale_key_or_custom',
	],
};

const persistFormData = (
	resourceType: GeneratableResourceType,
	data: GenerationFormParams<GeneratableResourceType>
) => {
	if (data.remember_industry_role) {
		const persistedData = persistedFormFields[resourceType].reduce((acc, fieldName) => {
			acc[fieldName] = data[fieldName];
			return acc;
		}, {});
		localStorage.setItem(persistedDataKey(resourceType), JSON.stringify(persistedData));
	} else {
		localStorage.removeItem(persistedDataKey(resourceType));
	}
	formPageInMemoryStore.formData = { ...data };
};

const validate = (
	data: GenerationFormParams<GeneratableResourceType>,
	resourceType: GeneratableResourceType
): boolean => {
	switch (resourceType) {
		case 'sharefile:requestlist':
			return !!data.request_name && !!data.request_purpose;
		default:
			return false;
	}
};

function toGenerationParams<T extends GeneratableResourceType>(
	resourceType: T,
	formParams: GenerationFormParams<T>,
	defaults?: Partial<GenerationFormParams<T>>
): GenerationParams<T> {
	switch (resourceType) {
		case 'sharefile:requestlist':
			const {
				remember_industry_role,
				employment_industry_locale_key_or_custom: employment_industry_locale_key,
				employment_role_locale_key_or_custom: employment_role,
				...rest
			} = formParams;
			return {
				...(!!defaults ? defaults : {}),
				...rest,
				...(rest.due_date
					? {
							due_date: dayjs(rest.due_date, dayjs.localeData().longDateFormat('L'))
								.endOf('day')
								.format(),
					  }
					: {}), // convert date picker's formatted value to ISO string with timezone offset (e.g. "2024-07-26T23:59:59+01:00" if someone in UK was trying this at any time on local date July 26th 2024) and forces end of day (23:59:59)
				employment_industry: convertCustomOrLocaleKeyToLabel(
					formParams.employment_industry_locale_key_or_custom
				),
				employment_role: convertCustomOrLocaleKeyToLabel(
					formParams.employment_role_locale_key_or_custom
				),
			};
		default:
			return {} as any;
	}
}

const formPageInMemoryStore = {
	formData: {},
}; // when we navigate to the review page, we need to remember the non-persisted fields for this form, in case the user navigates back to this form

export const FormPage: React.FC<FormPageProps> = _props => {
	const { token } = theme.useToken();
	const {
		onClose,
		setPage,
		setGenerationRequest,
		resourceType,
		generationFormDefaults: defaults,
	} = useResourceGenContext();
	const [form] = Form.useForm<GenerationFormParams<GeneratableResourceType>>();
	const { isLoading, data: initialFormData } = useQuery<
		GenerationFormParams<GeneratableResourceType>
	>(
		[persistedDataKey],
		async () => {
			switch (resourceType) {
				case 'sharefile:requestlist':
					const persistedData = localStorage.getItem(persistedDataKey(resourceType));
					if (persistedData) {
						const data = JSON.parse(persistedData);
						return {
							...(!!defaults ? defaults : {}),
							...formPageInMemoryStore.formData,
							...data,
						};
					} else if (!!defaults) {
						return { remember_industry_role: true, ...defaults };
					}
					return { remember_industry_role: true, ...formPageInMemoryStore.formData };
				default:
					return {} as any;
			}
		},
		{
			onSuccess: data => {
				form.setFieldsValue(data);
			},
			refetchOnWindowFocus: false,
		}
	);
	return (
		<Flex
			vertical
			justify="space-between"
			style={{ height: '100%', overflow: 'inherit' }}
		>
			<Flex vertical gap={24} style={{ height: '100%', overflow: 'inherit' }}>
				{/* Content */}
				<AiSparklesBanner>
					{t('use_resourcegen_drawer.sharefile_requestlist.prompt_banner')}
				</AiSparklesBanner>
				{isLoading && (
					<Flex justify="center">
						<ShareFileSpinner />
					</Flex>
				)}
				<CreateRequestListInformationForm
					form={form}
					initialValues={{ request_purpose: '', request_name: '', ...initialFormData }}
					disabled={isLoading}
					initialFormData={{ ...initialFormData }}
				/>
				<AiCaution />
			</Flex>
			<Flex
				vertical
				style={{ height: '100%', flexBasis: 'max-content', paddingTop: token.sizeLG }}
				gap={token.sizeLG}
			>
				{/* Footer */}
				<Flex
					gap={token.sizeXS}
					style={{ borderTop: `1px solid ${token.colorSplit}`, paddingTop: token.size }}
				>
					<Button
						type="primary"
						onClick={() => {
							form.submit();
							const formData = form.getFieldsValue();
							persistFormData(resourceType, formData);
							if (validate(formData, resourceType)) {
								setGenerationRequest(
									toGenerationParams(resourceType, formData, defaults)
								);
								sendPendoTrackEvent({
									eventKey: 'generateResourceAction',
									metadata: {
										resourceType,
									},
								});
								setPage('review');
							}
						}}
					>
						{t('use_resourcegen_drawer.sharefile_requestlist.generate_btn')}
					</Button>
					<Button onClick={onClose}>{t('common.cancel')}</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
