import { getApiBaseUrl } from '@data/helpers';
import { GeneratableResourceType, GenerationParams } from '@sdk';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { pollWithConstantBackOff } from '@utils/poller';
import { initializeClient, ResourceGenClient } from './client';
import { StatusRecord, StatusState } from './types';

export class ResourceGenSdk {
	private initializedClient: ResourceGenClient;

	public Initialize(client: ResourceGenClient) {
		this.initializedClient = client;
	}

	/**
	 *
	 * @param resourceType
	 * @param params
	 * @returns
	 * @throws @see {AxiosError} if generateResource or polling fails with an error
	 */
	public async generateResource(
		resourceType: GeneratableResourceType,
		params: GenerationParams<GeneratableResourceType>
	): Promise<StatusRecord> {
		const task = await this.initializedClient.generateResource(resourceType, params);
		const result = await pollWithConstantBackOff<StatusRecord>({
			pollFn: async () => {
				return await this.initializedClient.getStatus(task.task_id);
			},
			validateFn: (statusRecord: StatusRecord) => {
				if (
					statusRecord.state === StatusState.Failed ||
					statusRecord.state === StatusState.Unknown
				) {
					throw new Error('Resource generation failed');
				}
				return statusRecord.state === StatusState.Completed;
			},
			delayInMilliSeconds: 1500,
			maxAttempts: 120,
		});
		return result;
	}
}

export const resourceGenSdk = new ResourceGenSdk();

export function configureResourceGenerationSdk(app: PiletApi) {
	resourceGenSdk.Initialize(
		initializeClient({
			baseUrl: getApiBaseUrl(app, 'resourcegen'),
		})
	);
}
