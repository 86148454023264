/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useResourceGenModal } from '@common/hooks/useResourceGenModal';
import { GeneratableResourceType, GenerationFormParams } from '@sdk/extensionTypes';
import { withModals, WithModalsProps } from '@sharefiledev/flow-web';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { useShareFileContext } from '@utils/context';
import {
	Alert,
	Button,
	Checkbox,
	Collapse,
	Flex,
	Form,
	Input,
	Slider,
	Typography,
} from 'antd';
import { useExecutePackage } from '../data/hooks/packaging/useExecutePackage';
import { ExtensionWrapper } from '../extensions/extensionWrapper';
import { AiSparklesBanner } from './AiSparklesBanner/AiSparklesBanner';
import { defaultYaml } from './defaultYaml';
import { useDefaultDrawer } from './Drawer';
import { persistedDataKey } from './GenerationDrawerContents/FormPage';
import { ResourceGenerationContents } from './GenerationDrawerContents/GenerationDrawerContents';
import GenerationForm from './GenerationDrawerContents/GenerationForm/GenerationForm';
import { ReviewPage } from './GenerationDrawerContents/ReviewPage';
import { PackageEditor } from './PackageEditor/PackageEditor';
import {
	PackageEditorErrorProps,
	PackageEditorState,
} from './PackageEditor/types/editorTypes';
import { ResourceGenDrawer } from './ResourceGenDrawer';

export interface Props extends WithModalsProps {
	piletApi: PiletApi;
}

const _DevelopmentPage: React.FC<Props> = ({ piletApi }: Props) => {
	const { account } = useShareFileContext();

	const [yaml, setYaml] = React.useState(defaultYaml);
	const [editorState, setEditorState] = React.useState<PackageEditorState>({} as any);

	const [targetRID, setTargetRID] = React.useState(
		`sharefile:account-${account.Id}/project-20b035a2-37ec-4270-9f12-97c0fc77578a`
	);
	const [resourceRef, setResourceRef] = React.useState('resourceRef');
	const [createdRID, setCreatedRID] = React.useState('');

	const { showResourceGenModal } = useResourceGenModal(
		piletApi,
		targetRID,
		'sharefile:requestlist'
	);

	const [bannerWidth, setBannerWidth] = React.useState(300);
	const {
		executePackage,
		loading: executingPackage,
		packageExecutionError,
	} = useExecutePackage({
		packageYamlDefinition: yaml,
		targetRID,
		resourceRef,
		onSuccess: (rid: string) => {
			setCreatedRID(rid);
		},
		onError: (error: Error) => {
			console.error('Error executing package:', error);
		},
	});

	const [defaultFormValues, setDefaultFormValues] = React.useState<
		Partial<GenerationFormParams<GeneratableResourceType>>
	>({
		employment_industry_locale_key_or_custom: '',
		employment_role_locale_key_or_custom: '',
	});

	const [defaultsForm] = Form.useForm<GenerationFormParams<'sharefile:requestlist'>>();

	return (
		<div>
			<Collapse
				defaultActiveKey={['1']}
				items={[
					{
						key: '1',
						label: 'Overlay development',
						children: (
							<Flex vertical gap={16}>
								<div>
									<Button type="primary" onClick={() => showResourceGenModal()}>
										Modal Button
									</Button>
								</div>
								<p>
									Check the <b>Package editor demo</b> section to edit the YAML package
									and then come back to this <b>Overlay development section</b> and click
									the Drawer Button to see the editable package get rendered inside of the
									drawer.
								</p>
								<div>
									<Typography.Title level={3}>
										Default generation form values
									</Typography.Title>
									<Form.Item label="Resource type">
										<Input value="sharefile:requestlist" disabled />
									</Form.Item>
									<GenerationForm
										disabled={false}
										form={defaultsForm}
										initialValues={{ request_name: '', request_purpose: '' }}
										initialFormData={{ request_name: '', request_purpose: '' }}
									/>
									<Form.Item label="enable_file_browser">
										<Checkbox
											value={defaultsForm.getFieldValue('enable_file_browser')}
											onChange={e => {
												defaultsForm.setFieldValue(
													'enable_file_browser',
													e.target.checked
												);
											}}
										>
											Enable file browser
										</Checkbox>
									</Form.Item>
									<div>
										<Typography.Text>
											Click the <b>Apply defaults</b> button below to set the form
											defaults. Then click <b>Open Drawer Page 1</b> to see the form with
											the defaults applied. If you already had some values persisted to
											localstorage for this resourceType, they get used vs any defaults
											you might set. Thus we also provide a handy button below to clear
											the localstorage for this resourceType.
										</Typography.Text>
									</div>
									<Button
										type="primary"
										onClick={() => {
											defaultsForm.submit();
											const updatedValueForDefaults = {
												...defaultsForm.getFieldsValue(),
												enable_file_browser:
													defaultsForm.getFieldValue('enable_file_browser'),
											};
											console.log('updatedValueForDefaults:', updatedValueForDefaults);
											setDefaultFormValues(updatedValueForDefaults);
										}}
									>
										Apply defaults
									</Button>
									<Button
										type="primary"
										onClick={() => {
											localStorage.removeItem(persistedDataKey('sharefile:requestlist'));
										}}
									>
										Clear form localstorage for resourceType
									</Button>
								</div>
								<div>
									<DevDrawer
										buttonText={'Open Drawer Page 1'}
										piletApi={piletApi}
										formDefaults={defaultFormValues}
									>
										<ResourceGenerationContents />
									</DevDrawer>
								</div>
								<div>
									<DevDrawer
										buttonText={'Open Drawer Page 2'}
										piletApi={piletApi}
										formDefaults={defaultFormValues}
									>
										<ReviewPage />
									</DevDrawer>
								</div>
							</Flex>
						),
					},
					{
						key: '2',
						label: 'Package editor demo',
						children: (
							<Flex vertical gap={16}>
								<div>
									<Flex vertical gap={8}>
										<h2>Enter Package YAML here</h2>
										<Input.TextArea
											onChange={e => {
												setYaml(e.target.value);
											}}
											value={yaml}
											rows={20}
										/>

										<PackageEditor
											errorComponent={(props: PackageEditorErrorProps) => {
												return <div>Package Editor Error: {props.error.message}</div>;
											}}
											onUpdate={p => {
												setEditorState(p.editorState);
											}}
											packageYamlDefinition={yaml}
										/>

										<h2>Editor State</h2>
										<Input.TextArea
											value={JSON.stringify(editorState.editablePackage, null, 4)}
											rows={20}
										/>
										<h2>Computed Package</h2>
										<Input.TextArea value={editorState.computedYamlPackage} rows={20} />
									</Flex>
								</div>
							</Flex>
						),
					},
					{
						key: '3',
						label: 'Package Execution Button Demo',
						children: (
							<Flex vertical gap={16}>
								<div>
									<Button
										type="primary"
										onClick={executePackage}
										loading={executingPackage}
										disabled={executingPackage}
									>
										Execute package
									</Button>
								</div>
								<Input
									type="text"
									placeholder="Target RID"
									value={targetRID}
									onChange={e => setTargetRID(e.target.value)}
								/>
								<Input
									type="text"
									placeholder="Resource Ref"
									value={resourceRef}
									onChange={e => setResourceRef(e.target.value)}
								/>
								{!!packageExecutionError && (
									<Alert
										message="Package Execution Error"
										description={packageExecutionError.message}
										type="error"
									/>
								)}
								{!!createdRID && (
									<Alert message="Created RID" description={createdRID} type="info" />
								)}
							</Flex>
						),
					},
					{
						key: '4',
						label: 'Ai Sparkles Banner Demo',
						children: (
							<Flex vertical gap={16}>
								<p>With no width constraints:</p>
								<AiSparklesBanner>
									Based on the details provided, here are recommended items for your
									request. Select the ones you'd like to add.
								</AiSparklesBanner>

								<div>
									<p>Set width constraint for banner below:</p>
									<Slider
										min={100}
										max={1500}
										step={50}
										defaultValue={400}
										onChange={value => setBannerWidth(value)}
									/>
									<p>width: {bannerWidth}px</p>
								</div>
								<div style={{ width: bannerWidth }}>
									<AiSparklesBanner>
										Based on the details provided, here are recommended items for your
										request. Select the ones you'd like to add.
									</AiSparklesBanner>
								</div>
							</Flex>
						),
					},
				]}
			/>
		</div>
	);
};

const DevDrawer = ({ piletApi, children, buttonText, formDefaults }) => {
	const drawer = useDefaultDrawer({});
	useEffect(() => {
		console.log('piletApi:', piletApi);
	}, [piletApi]);

	return (
		<ExtensionWrapper app={piletApi}>
			<Button type="primary" onClick={() => drawer.setIsDrawerOpen(true)}>
				{buttonText}
			</Button>
			<ResourceGenDrawer
				isOpen={drawer.isDrawerOpen}
				onClose={() => drawer.setIsDrawerOpen(false)}
				title="Create document request with AI"
				resourceType="sharefile:requestlist"
				containerRID={`sharefile:account-${
					(piletApi as PiletApi).sf.piletUserContext.get().account.Id
				}/project-20b035a2-37ec-4270-9f12-97c0fc77578a`}
				onNavigate={(rid: string) => {
					console.log('Navigating to:', rid);
				}}
				generationFormDefaults={formDefaults}
			>
				{children}
			</ResourceGenDrawer>
		</ExtensionWrapper>
	);
};

export const DevelopmentPage = withModals(_DevelopmentPage);
