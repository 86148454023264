import { logError } from '@logger';
import { GeneratableResourceType, GenerationParams } from '@sdk';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { GenerateResourceResponse, StatusRecord } from './types';

export interface ResourceGenClient {
	getStatus(id: string): Promise<StatusRecord>;
	generateResource<T extends GeneratableResourceType>(
		resourceType: T,
		params: GenerationParams<T>
	): Promise<GenerateResourceResponse>;
}

export interface ResourceGenClientConfiguration {
	baseUrl: string;
}

export function initializeClient(
	config: ResourceGenClientConfiguration
): ResourceGenClient {
	const sharedConfig: AxiosRequestConfig = {
		baseURL: config.baseUrl,
		timeout: 60000,
		headers: {
			'x-bff-csrf': 'true',
		},
	};

	const resourceGenApi = axios.create(sharedConfig);

	return {
		async getStatus(task_id: string): Promise<StatusRecord> {
			return apiProxy(async () => {
				return await resourceGenApi.get(
					`api/v1/generated-resources/status/${encodeURIComponent(task_id)}`
				);
			}, 'getStatus');
		},
		async generateResource<T extends GeneratableResourceType>(
			resourceType: T,
			params: GenerationParams<T>
		): Promise<GenerateResourceResponse> {
			return apiProxy(async () => {
				return await resourceGenApi.post('api/v1/generated-resources', {
					payload: params,
					resource_type: resourceType,
				});
			}, 'generateResource');
		},
	};
}

async function apiProxy<T>(
	proxyFn: () => Promise<AxiosResponse<T>>,
	proxyLabel: string
): Promise<T> {
	let response: AxiosResponse<T, any>;
	try {
		response = await proxyFn();
	} catch (error) {
		logError(error, {
			customMessage: `Resource Generation API failure for ${proxyLabel}`,
		});
		throw error;
	}

	return response.data;
}
