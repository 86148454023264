/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "common": {
        "actions": "Aktionen",
        "ai_caution": "KI-Empfehlungen sind möglicherweise nicht genau. Überprüfen Sie die generierten Elemente.",
        "all_categories": "Alle Kategorien",
        "back": "Zurück",
        "beta": "Beta",
        "type_or_select_one": "Eingeben oder auswählen",
        "cancel": "Abbrechen",
        "close": "Schließen",
        "create": "Erstellen",
        "created_by": "Erstellt von",
        "created_on": "Erstellt am",
        "delete": "Löschen",
        "description": "Beschreibung",
        "details": "Details",
        "edit_details": "Details bearbeiten",
        "more": "Mehr",
        "my_templates": "Eigene Vorlagen",
        "name": "Name",
        "oops": "Hoppla!",
        "optional": "Optional",
        "preview": "Vorschau",
        "retry": "Wiederholen",
        "search": "Suche",
        "select": "Auswählen",
        "shared_with_me": "Für mich freigegeben",
        "start_over": "Von vorne",
        "stop": "Beenden",
        "template": "Vorlage",
        "templates": "Vorlagen",
        "unknown": "Unbekannt",
        "was_this_helpful": "War dies hilfreich?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "Beim Laden der Inhalte ist ein Fehler aufgetreten. Versuchen Sie es noch einmal.",
          "no_resources": "Keine anzuzeigenden Inhalte."
        }
      },
      "industries": {
        "accounting": {
          "label": "Buchhaltung",
          "roles": {
            "auditor": "Wirtschaftsprüfer_in",
            "tax_accountant": "Steuerbuchhalter_in",
            "financial_analyst": "Finanzanalyst_in",
            "chief_financial_officer__cfo_": "Finanzvorstand (CFO)",
            "forensic_accountant": "Forensische_r Buchhalter_in",
            "accounts_payable_specialist": "Kreditorenbuchhalter_in",
            "accounts_receivable_specialist": "Debitorenbuchhalter_in",
            "internal_auditor": "Innenrevisor_in",
            "controller": "Controller",
            "budget_analyst": "Budgetanalyst_in"
          }
        },
        "agriculture": {
          "label": "Landwirtschaft",
          "roles": {
            "farm_manager": "Landwirtschaftliche_r Betriebsleiter_in",
            "agronomist": "Agronom_in",
            "agricultural_engineer": "Landwirtschaftstechniker_in",
            "crop_scientist": "Nutzpflanzenforscher_in",
            "livestock_manager": "Viehbestandverwalter_in",
            "soil_scientist": "Bodenkundler_in",
            "agricultural_consultant": "Agrarberater_in",
            "irrigation_specialist": "Bewässerungsspezialist_in",
            "agribusiness_manager": "Agrarwirtschaftsleiter_in",
            "agricultural_technician": "Agrartechniker_in"
          }
        },
        "apparel": {
          "label": "Bekleidung",
          "roles": {
            "fashion_designer": "Modedesigner_in",
            "merchandiser": "Merchandiser_in",
            "production_manager": "Produktionsleiter_in",
            "textile_technologist": "Textiltechniker_in",
            "fashion_buyer": "Moderinkäufer_in",
            "pattern_maker": "Musterzeichner_in",
            "apparel_product_developer": "Entwickler_in von Bekleidungsprdukten",
            "fashion_illustrator": "Modeillustrator_in",
            "quality_control_inspector": "Qualitätskontroll-Inspekteur_in",
            "fashion_marketing_specialist": "Spezialist_in für Modemarketing"
          }
        },
        "banking": {
          "label": "Bankwesen",
          "roles": {
            "investment_banker": "Investmentbanker_in",
            "loan_officer": "Kreditsachbearbeiter_in",
            "financial_advisor": "Finanzberater_in",
            "branch_manager": "Abteilungleiter_in",
            "risk_manager": "Risikomanager_in",
            "credit_analyst": "Kreditanalyst_in",
            "relationship_manager": "Manager_in für Kundenbeziehungen",
            "compliance_officer": "Compliance-Beauftragte",
            "wealth_manager": "Vermögensverwalter_in",
            "treasury_analyst": "Treasury-Analyst_in"
          }
        },
        "biotechnology": {
          "label": "Biotechnologie",
          "roles": {
            "research_scientist": "Forschungswissenschaftler_in",
            "bioprocess_engineer": "Bioprozessingenieur_in",
            "regulatory_affairs_specialist": "Spezialist_in für regulatorische Angelegenheiten",
            "bioinformatics_specialist": "Spezialist_in für Bioinformatik",
            "clinical_research_associate": "Klinische_r Forschungsmitarbeiter_in",
            "biotechnology_technician": "Biotechnologietechniker_in",
            "quality_assurance_specialist": "Spezialist_in für Qualitätssicherung",
            "biomedical_engineer": "Biomedizintechniker_in",
            "genetic_counselor": "Genetikberater_in",
            "biostatistician": "Biostatistiker_in"
          }
        },
        "chemicals": {
          "label": "Chemietechnik",
          "roles": {
            "chemical_engineer": "Chemieingenieur_in",
            "process_chemist": "Prozesschemiker_in",
            "environmental_health_and_safety_specialist": "Spezialist_in für Umweltgesundheit und Sicherheit",
            "quality_control_manager": "Leiter_in der Qualitätskontrolle",
            "production_supervisor": "Produktionsleiter_in",
            "r_d_scientist": "FuE-Wissenschafler_in",
            "regulatory_compliance_specialist": "Spezialist_in für die Einhaltung gesetzlicher Vorschriften",
            "analytical_chemist": "Chemieanalytiker_in",
            "chemical_plant_operator": "Chemieanlagenbediener_in",
            "formulation_chemist": "Formulierungschemiker_in"
          }
        },
        "communications": {
          "label": "Kommunikation",
          "roles": {
            "public_relations_specialist": "PR-Spezialist_in",
            "marketing_manager": "Marketingmanager_in",
            "social_media_manager": "Manager_in für soziale Medien",
            "corporate_communications_manager": "Manager_in für Unternehmenskommunikation",
            "content_strategist": "Inhaltsstratege/in",
            "communications_coordinator": "Kommunikationskoordinator_in",
            "media_relations_specialist": "Spezialist_in für Medienarbeit",
            "internal_communications_specialist": "Spezialist_in für interne Kommunikation",
            "speechwriter": "Redenschreiber_in",
            "copywriter": "Texter_in"
          }
        },
        "construction": {
          "label": "Baugewerbe",
          "roles": {
            "project_manager": "Projektleiter_in",
            "civil_engineer": "Bauingenieur_in",
            "architect": "Architekt_in",
            "construction_manager": "Bauleiter_in",
            "quantity_surveyor": "Mengengutachter_in",
            "site_supervisor": "Baustellenleiter_in",
            "structural_engineer": "Statiker_in",
            "building_inspector": "Bauinspektor_in",
            "safety_manager": "Sicherheitsbeauftragte/r",
            "estimator": "Schätzer_in"
          }
        },
        "consulting": {
          "label": "Beratung",
          "roles": {
            "management_consultant": "Unternehmensberater_in",
            "strategy_analyst": "Strategieanalyst_in",
            "it_consultant": "IT Berater_in",
            "human_resources_consultant": "Personalberater_in",
            "financial_consultant": "Finanzberater_in",
            "business_analyst": "Business-Analyst_in",
            "operations_consultant": "Betriebsberater_in",
            "marketing_consultant": "Marketingberater_in",
            "environmental_consultant": "Umweltberater_in",
            "legal_consultant": "Rechtsberater_in"
          }
        },
        "e_commerce": {
          "label": "E-Commerce",
          "roles": {
            "e_commerce_manager": "E-Commerce-Manager",
            "digital_marketing_specialist": "Spezialist_in für digitales Marketing",
            "customer_experience_manager": "Kundenerfahrungsmanager_in",
            "marketplace_analyst": "Marktplatzanalyst_in",
            "supply_chain_manager": "Lieferkettenmanager_in",
            "product_manager": "Produktmanager_in",
            "seo_specialist": "SEO-Spezialist_in",
            "web_developer": "Webentwickler_in",
            "data_analyst": "Datenanalyst_in",
            "content_manager": "Inhaltsmanager_in"
          }
        },
        "education": {
          "label": "Bildungseinrichtung",
          "roles": {
            "teacher___educator": "Lehrer_in/Pädagoge/Pädagogin",
            "school_administrator": "Schuldirektor_in",
            "curriculum_developer": "Lehrplanentwickler_in",
            "education_consultant": "Bildungsberater_in",
            "instructional_designer": "Unterrichtsplaner_in",
            "academic_advisor": "Unterrichtsberater_in",
            "educational_psychologist": "Schulpsychologe/in",
            "special_education_teacher": "Sonderschullehrer_in",
            "school_counselor": "Schulberater_in",
            "librarian": "Bibliothekar_in"
          }
        },
        "electronics": {
          "label": "Elektronik",
          "roles": {
            "electrical_engineer": "Elektroingenieur_in",
            "hardware_designer": "Hardwaredesigner_in",
            "semiconductor_technician": "Halbleitertechniker_in",
            "embedded_systems_engineer": "Ingenieur_in für eingebettete Systeme",
            "robotics_engineer": "Robotikingenieur_in",
            "electronics_technician": "Elektroniker_in",
            "pcb_designer": "Leiterplattendesigner_in",
            "systems_engineer": "Systemingenieur_in",
            "quality_assurance_engineer": "Qualitätssicherungsingenieur_in",
            "product_development_engineer": "Produktentwicklungsingenieur_in"
          }
        },
        "energy": {
          "label": "Energie",
          "roles": {
            "energy_analyst": "Energieanalyst_in",
            "power_plant_engineer": "Kraftwerksingenieur_in",
            "renewable_energy_specialist": "Spezialist_in für erneuerbare Energien",
            "energy_trader": "Energiehändler_in",
            "environmental_specialist": "Umweltspezialist_in",
            "energy_consultant": "Energieberater_in",
            "energy_manager": "Energiemanager_in",
            "petroleum_engineer": "Erdölingenieur_in",
            "solar_energy_technician": "Solarenergietechniker_in",
            "wind_turbine_technician": "Windturbinentechniker_in"
          }
        },
        "engineering": {
          "label": "Engineering",
          "roles": {
            "mechanical_engineer": "Maschinenbauingenieur_in",
            "civil_engineer": "Bauingenieur_in",
            "aerospace_engineer": "Luftfahrtingenieur_in",
            "chemical_engineer": "Chemieingenieur_in",
            "electrical_engineer": "Elektroingenieur_in",
            "industrial_engineer": "Industrie-Ingenieur_in",
            "software_engineer": "Softwareentwickler_in",
            "structural_engineer": "Statiker_in",
            "environmental_engineer": "Umweltingenieur_in",
            "biomedical_engineer": "Biomedizintechniker_in"
          }
        },
        "entertainment": {
          "label": "Unterhaltung",
          "roles": {
            "actor___actress": "Schauspieler / Schauspielerin",
            "film_director": "Filmregisseur_in",
            "producer": "Produzent_in",
            "screenwriter": "Drehbuchautor_in",
            "entertainment_lawyer": "Anwalt / Anwältin für Unterhaltungsbranche",
            "casting_director": "Casting-Direktor_in",
            "cinematographer": "Kameramann / Kamerafrau",
            "sound_engineer": "Toningenieur_in",
            "production_designer": "Produktionsdesigner_in",
            "talent_agent": "Talentagent_in"
          }
        },
        "environmental": {
          "label": "Umwelt",
          "roles": {
            "environmental_scientist": "Umweltwissenschaftler_in",
            "environmental_engineer": "Umweltingenieur_in",
            "sustainability_consultant": "Nachhaltigkeitsberater_in",
            "ecologist": "Ökologe / Ökologin",
            "environmental_compliance_manager": "Beauftragte_r für Umweltverträglichkeit",
            "conservation_scientist": "Naturschutzwissenschaftler_in",
            "environmental_health_and_safety_specialist": "Spezialist_in für Umweltgesundheit und Sicherheit",
            "environmental_planner": "Umweltplaner_in",
            "environmental_educator": "Umweltpädagoge / Umweltpädagogin",
            "wildlife_biologist": "Wildtierbiologe/in"
          }
        },
        "finance": {
          "label": "Finanzen",
          "roles": {
            "financial_analyst": "Finanzanalyst_in",
            "investment_banker": "Investmentbanker_in",
            "portfolio_manager": "Portfoliomanager_in",
            "financial_planner": "Finanzplaner_in",
            "risk_analyst": "Risikoanalyst_in",
            "credit_analyst": "Kreditanalyst_in",
            "compliance_officer": "Compliance-Beauftragte",
            "treasury_analyst": "Treasury-Analyst_in",
            "financial_controller": "Finanzkontrolleur_in",
            "budget_analyst": "Budgetanalyst_in"
          }
        },
        "food___beverage": {
          "label": "Lebensmittel und Getränke",
          "roles": {
            "chef": "Koch/Köchin",
            "food_scientist": "Nahrungswissenschaftler",
            "nutritionist": "Ernährungsberater_in",
            "food_safety_manager": "Leiter_in der Lebensmittelsicherheit",
            "restaurant_manager": "Restaurantleiter_in",
            "beverage_manager": "Getränkemanager_in",
            "pastry_chef": "Konditor_in",
            "sommelier": "Sommelier/Sommelière",
            "food_technologist": "Lebensmitteltechnologe/in",
            "quality_assurance_manager": "Qualitätssicherungsmanager_in"
          }
        },
        "government": {
          "label": "Behörden",
          "roles": {
            "policy_analyst": "Richtlinienanalyst_in",
            "civil_servant": "Beamter/Beamtin",
            "diplomat": "Diplomat_in",
            "government_relations_specialist": "Spezialist_in für Regierungsbeziehungen",
            "legislative_assistant": "Gesetzgebungsassistent_in",
            "public_administrator": "Leiter_in im Verwaltungsbereich",
            "city_planner": "Stadtplaner_in",
            "budget_analyst": "Budgetanalyst_in",
            "intelligence_analyst": "Geheimdienstanalyst_in",
            "public_affairs_specialist": "Spezialist_in für öffentliche Angelegenheiten"
          }
        },
        "graphic_design": {
          "label": "Grafikdesigner",
          "roles": {
            "graphic_designer": "Grafikdesigner_in",
            "art_director": "Art-Director_in",
            "illustrator": "Illustrator_in",
            "ux_ui_designer": "UX/UI Designer_in",
            "branding_specialist": "Brandingspezialist_in",
            "creative_director": "Kreativdirektor_in",
            "web_designer": "Webdesigner_in",
            "motion_graphics_designer": "Animationsgrafikdesigner_in",
            "package_designer": "Paketdesigner_in",
            "visual_designer": "Bilddesigner_in"
          }
        },
        "healthcare": {
          "label": "Gesundheitswesen",
          "roles": {
            "physician": "Arzt/Ärztin",
            "nurse_practitioner": "Krankenpfleger_in",
            "healthcare_administrator": "Verwaltungsleiter_in im Gesundheitswesen",
            "medical_researcher": "Medizinforscher_in",
            "pharmacist": "Apotheker_in",
            "medical_technologist": "Medizintechniker_in",
            "physical_therapist": "Physiotherapeut_in",
            "occupational_therapist": "Ergotherapeut_in",
            "registered_nurse": "Krankenschwester / Krankenpfleger",
            "radiologic_technologist": "Radiologietechnologe / Radiologietechnologin"
          }
        },
        "hospitality": {
          "label": "Hotellerie",
          "roles": {
            "hotel_manager": "Site Manager_in",
            "event_planner": "Veranstaltungsplaner_in",
            "restaurant_manager": "Restaurantleiter_in",
            "hospitality_consultant": "Berater_in im Gastgewerbe",
            "concierge": "Concierge",
            "front_desk_manager": "Empfangschef_in",
            "housekeeping_manager": "Hauswirtschaftsmanager_in",
            "food_and_beverage_manager": "Lebensmittel- und Getränkemanager_in",
            "banquet_manager": "Bankettleiter_in",
            "guest_services_manager": "Leiter_in der Gästebetreuung"
          }
        },
        "insurance": {
          "label": "Versicherung",
          "roles": {
            "actuary": "Aktuar_in",
            "insurance_underwriter": "Versicherungsmakler_in",
            "claims_adjuster": "Schadenregulierer_in",
            "risk_manager": "Risikomanager_in",
            "insurance_broker": "Versicherungsmakler_in",
            "insurance_agent": "Versicherungsvertreter_in",
            "loss_control_specialist": "Leiter_in der Schadensbegrenzung",
            "claims_examiner": "Schadensprüfer_in",
            "compliance_officer": "Compliance-Beauftragte",
            "customer_service_representative": "Kundendienstmitarbeiter_in"
          }
        },
        "legal": {
          "label": "Legal",
          "roles": {
            "lawyer": "Anwalt / Anwältin",
            "legal_secretary": "Rechtsanwaltssekretär_in",
            "paralegal": "Rechtsanwaltsfachangestellte_r",
            "judge": "Richter_in",
            "legal_counsel": "Rechtsbeistand",
            "corporate_attorney": "Unternehmenanwalt / Unternehmensanwältin",
            "litigation_attorney": "Prozessanwalt/-anwältin",
            "legal_assistant": "Juristische/r Mitarbeiter_in",
            "compliance_officer": "Compliance-Beauftragte",
            "court_clerk": "Gerichtsbedienstete/r"
          }
        },
        "machinery": {
          "label": "Maschinen",
          "roles": {
            "mechanical_engineer": "Maschinenbauingenieur_in",
            "maintenance_technician": "Wartungstechniker_in",
            "production_manager": "Produktionsleiter_in",
            "quality_control_engineer": "Qualitätskontrollingenieur_in",
            "operations_manager": "Betriebsleiter_in",
            "cnc_machinist": "CNC-Maschinenbediener_in",
            "manufacturing_engineer": "Fertigungsingenieur_in",
            "field_service_technician": "Außendiensttechniker_in",
            "tool_and_die_maker": "Werkzeug- und Formenbauer_in",
            "equipment_operator": "Anlagenbediener_in"
          }
        },
        "manufacturing": {
          "label": "Fertigung",
          "roles": {
            "production_manager": "Produktionsleiter_in",
            "quality_assurance_manager": "Qualitätssicherungsmanager_in",
            "supply_chain_manager": "Lieferkettenmanager_in",
            "industrial_engineer": "Industrie-Ingenieur_in",
            "manufacturing_engineer": "Fertigungsingenieur_in",
            "operations_manager": "Betriebsleiter_in",
            "maintenance_manager": "Wartungsmanager_in",
            "process_engineer": "Prozessingenieur_in",
            "plant_manager": "Werksleiter_in",
            "production_planner": "Produktionsplaner_in"
          }
        },
        "marketing": {
          "label": "Marketing",
          "roles": {
            "marketing_manager": "Marketingmanager_in",
            "digital_marketing_specialist": "Spezialist_in für digitales Marketing",
            "market_research_analyst": "Marktforschungsanalyst_in",
            "brand_manager": "Markenmanager_in",
            "public_relations_specialist": "PR-Spezialist_in",
            "social_media_manager": "Manager_in für soziale Medien",
            "content_marketing_specialist": "Spezialist_in für Inhaötsmarketing",
            "marketing_coordinator": "Marketingkoordinator_in",
            "seo_specialist": "SEO-Spezialist_in",
            "email_marketing_specialist": "Spezialist_in für E-Mail-Marketing"
          }
        },
        "media": {
          "label": "Medien",
          "roles": {
            "journalist": "Journalist_in",
            "editor": "Redakteur_in",
            "tv_producer": "TV-Produzent_in",
            "media_buyer": "Medienkäufer_in",
            "multimedia_artist": "Multimediakünstler_in",
            "broadcast_technician": "Rundfunktechniker_in",
            "news_anchor": "Nachrichtenpräsentator_in",
            "copywriter": "Texter_in",
            "video_editor": "Videobearbeiter_in",
            "social_media_manager": "Manager_in für soziale Medien"
          }
        },
        "mining": {
          "label": "Bergbau",
          "roles": {
            "geologist": "Geologe/Geologin",
            "mining_engineer": "Bergbauingenieur_in",
            "environmental_specialist__mining_": "Umweltspezialist_in (Bergbau)",
            "safety_manager__mining_": "Sicherheitsmanager_in (Bergbau)",
            "mine_operations_manager": "Bergbaubetriebsleiter_in",
            "drilling_engineer": "Bohringenieur_in",
            "metallurgist": "Metallurg_in",
            "mine_surveyor": "Minenvermesser_in",
            "mineral_processing_engineer": "Verarbeitungsingenieur_in für Bergbauprodukte",
            "maintenance_planner": "Wartungsplaner_in"
          }
        },
        "not_for_profit": {
          "label": "Gemeinnützig",
          "roles": {
            "program_manager": "Programm-Manager",
            "fundraiser": "Fundraiser_in",
            "advocacy_coordinator": "Koordinator_in für Interessenvertretung",
            "grant_writer": "Spendenaufrufautor_in",
            "volunteer_coordinator": "Freiwilligenkoordinator_in",
            "community_outreach_manager": "Communitybetreuer_in",
            "development_director": "Entwicklungsleiter_in",
            "nonprofit_administrator": "Gemeinnützigkeitsverwalter_in",
            "social_worker": "Sozialarbeiter_in",
            "communications_director": "Kommunikationsleiter_in"
          }
        },
        "pharmaceuticals": {
          "label": "Pharmazeutika",
          "roles": {
            "pharmaceutical_sales_representative": "Handelsvertreter_in für Pharmazeutika",
            "research_scientist": "Forschungswissenschaftler_in",
            "regulatory_affairs_specialist": "Spezialist_in für regulatorische Angelegenheiten",
            "clinical_trial_manager": "Leiter_in klinischer Studien",
            "pharmacologist": "Pharmakologe/Pharmakologin",
            "quality_control_analyst": "Analyst_in der Qualitätskontrolle",
            "formulation_scientist": "Formulierungwissenschaftler_in",
            "medical_science_liaison": "Medizinwissenschaftliche Kontaktperson",
            "drug_safety_specialist": "Spezialist_in für Arzneimittelsicherheit",
            "biostatistician": "Biostatistiker_in"
          }
        },
        "real_estate": {
          "label": "Immobilien",
          "roles": {
            "real_estate_agent": "Immobilienmakler_in",
            "property_manager": "Objektverwalter_in",
            "real_estate_developer": "Immobilienentwickler_in",
            "appraiser": "Gutachter_in",
            "leasing_consultant": "Mietberater_in",
            "real_estate_broker": "Immobilienmakler_in",
            "commercial_real_estate_agent": "Gewerbeimmobilienmakler_in",
            "real_estate_analyst": "Immobilienanalyst_in",
            "real_estate_marketing_specialist": "Spezialist_in für E-Mail-Marketing",
            "title_examiner": "Titelprüfer_in"
          }
        },
        "recreation": {
          "label": "Freizeit",
          "roles": {
            "recreation_director": "Direktor_in für Freizeitgestaltung",
            "event_coordinator": "Eventkoordinator_in",
            "park_ranger": "Naturparkwächter_in",
            "fitness_instructor": "Fitnesstrainer_in",
            "sports_coach": "Sporttrainer_in",
            "recreation_therapist": "Freizeittherapeut_in",
            "camp_director": "Campleiter_in",
            "outdoor_education_instructor": "Ausbilder_in für Outdoor-Bildung",
            "aquatics_director": "Wassersportleiter_in",
            "adventure_guide": "Abenteuerführer_in"
          }
        },
        "retail": {
          "label": "Retail",
          "roles": {
            "store_manager": "Filialleiter_in",
            "sales_associate": "Verkaufsmitarbeiter_in",
            "merchandiser": "Merchandiser_in",
            "retail_buyer": "Einzelhandelseinkäufer_in",
            "visual_merchandiser": "Visual Merchandiser_in",
            "inventory_manager": "Bestandsverwalter_in",
            "customer_service_representative": "Kundendienstmitarbeiter_in",
            "cashier": "Kassierer_in",
            "retail_manager": "Einzelhandelverkäufer_in",
            "loss_prevention_specialist": "Spezialist_in für Schadensbegrenzung"
          }
        },
        "shipping": {
          "label": "Versand",
          "roles": {
            "logistics_manager": "Logistikmanager_in",
            "shipping_coordinator": "Versandkoordinator_in",
            "port_operations_manager": "Hafenbetriebsleiter_in",
            "freight_forwarder": "Spediteur_in",
            "customs_broker": "Zollagent_in",
            "shipping_clerk": "Versandmitarbeiter_in",
            "marine_engineer": "Schiffsingenieur_in",
            "cargo_inspector": "Frachtsprüfer_in",
            "supply_chain_analyst": "Lieferkettenanalyst_in",
            "deck_officer": "Deckoffizier_in"
          }
        },
        "technology": {
          "label": "Technologie",
          "roles": {
            "software_engineer": "Softwareentwickler_in",
            "data_scientist": "Datenwissenschaftler_in",
            "systems_analyst": "Systemanalyst_in",
            "it_project_manager": "IT-Projektmanager_in",
            "network_administrator": "Netzwerkadministrator_in",
            "cybersecurity_analyst": "Cybersicherheitsanalyst_in",
            "devops_engineer": "DevOps-Ingenieur_in",
            "product_manager": "Produktmanager_in",
            "technical_support_specialist": "Kundendiensttechniker_in",
            "cloud_solutions_architect": "Architekt_in für Cloudlösungen"
          }
        },
        "telecommunications": {
          "label": "Telekommunikation",
          "roles": {
            "network_engineer": "Netzwerktechniker_in",
            "telecommunications_technician": "Telekommunikationstechniker_in",
            "systems_administrator": "Sitzungsadministrator_in",
            "rf_engineer": "HF-Ingenieur_in",
            "telecom_analyst": "Telekommunikationsanalyst_in",
            "customer_support_specialist": "Kundensupportspezialist_in",
            "sales_engineer": "Verkaufsingenieur_in",
            "telecommunications_manager": "Telekommunikationsmanager_in",
            "voip_engineer": "VoIP-Ingenieur_in",
            "field_service_technician": "Außendiensttechniker_in"
          }
        },
        "transportation": {
          "label": "Transport",
          "roles": {
            "transportation_manager": "Transportleiter_in",
            "fleet_manager": "Flottenmanager_in",
            "logistics_coordinator": "Logistikkoordinator_in",
            "traffic_analyst": "Verkehrsanalyst_in",
            "transportation_planner": "Transportplaner_in",
            "dispatch_supervisor": "Disponent_in",
            "supply_chain_manager": "Lieferkettenmanager_in",
            "safety_coordinator": "Sicherheitskoordinator_in",
            "public_transit_manager": "ÖNV-Leiter_in",
            "vehicle_maintenance_manager": "Leiter_in Fahrzeugwartung"
          }
        },
        "utilities": {
          "label": "Hilfsprogramme",
          "roles": {
            "utility_manager": "Versorgungsbetriebsleiter_in",
            "electrical_engineer": "Elektroingenieur_in",
            "water_treatment_specialist": "Wasseraufbereitungsspezialist_in",
            "utility_analyst": "Versorgungsanalyst_in",
            "energy_efficiency_consultant": "Berater_in für Energieeffizienz",
            "environmental_compliance_specialist": "Spezialist_in für Umweltverträglichkeit",
            "utility_field_technician": "Außendiensttechniker_in für Versorgungsunternehmen",
            "gas_controller": "Gaskontrolleur_in",
            "operations_manager": "Betriebsleiter_in",
            "utility_planner": "Versorgungsbetriebsplaner_in"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Dokumentanforderung mit KI erstellen",
          "prompt_banner": "Geben Sie die Details Ihrer Anfrage ein, damit wir eine Liste mit empfohlenen Artikeln für Sie erstellen können.",
          "generate_btn": "Elemente generieren",
          "review_banner": "Basierend auf den bereitgestellten Details werden hier für Ihre Anforderung empfohlene Elemente angezeigt. Wählen Sie die Elemente aus, die Sie hinzufügen möchten.",
          "creation_progress": "Dokumentanforderung wird erstellt...",
          "generation_progress": "Elemente für Ihre Anforderung werden generiert...",
          "creation_error": {
            "message": "Fehler beim Erstellen der Dokumentanforderung.",
            "description": "Versuchen Sie es erneut."
          },
          "generation_error": {
            "message": "Fehler beim Erstellen der Elemente für Ihre Anforderung.",
            "description": "Versuchen Sie es erneut."
          },
          "generation_form": {
            "request_name": "Anforderungsname",
            "due_date": "Fälligkeitsdatum",
            "request_purpose": "Was ist der Zweck dieser Anforderung?",
            "request_purpose_placeholder": "Was Sie sagen können:\n- Sammeln Sie Dokumente für die Einarbeitung neuer Mitarbeiter.\n- Fordern Sie persönliche Dokumente wie einen amtlichen Ausweis, Sozialversicherungsunterlagen und andere relevante Dokumente an.\n Vermeiden Sie die Angabe personenbezogener Daten (z. B. Name, E-Mail-Adresse).",
            "employment_industry": "In welcher Branche arbeiten Sie?",
            "employment_role": "Welche Rolle spielen Sie in der Branche?",
            "remember_industry_role": "Branche und Rolle merken"
          },
          "create_btn": "Anforderung erstellen"
        }
      }
    }
  },
  {
    "key": "en",
    "content": {
      "common": {
        "actions": "Actions",
        "ai_caution": "AI recommendations may not be accurate. Please verify generated items.",
        "all_categories": "All categories",
        "back": "Back",
        "beta": "Beta",
        "type_or_select_one": "Type or select one",
        "cancel": "Cancel",
        "close": "Close",
        "create": "Create",
        "created_by": "Created by",
        "created_on": "Created on",
        "delete": "Delete",
        "description": "Description",
        "details": "Details",
        "edit_details": "Edit details",
        "more": "More",
        "my_templates": "My templates",
        "name": "Name",
        "oops": "Oops!",
        "optional": "optional",
        "preview": "Preview",
        "retry": "Retry",
        "search": "Search",
        "select": "Select",
        "shared_with_me": "Shared with me",
        "start_over": "Start over",
        "stop": "Stop",
        "template": "Template",
        "templates": "Templates",
        "unknown": "unknown",
        "was_this_helpful": "Was this helpful?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "An error occurred while loading contents. Please try again.",
          "no_resources": "No contents to show."
        }
      },
      "industries": {
        "accounting": {
          "label": "Accounting",
          "roles": {
            "auditor": "Auditor",
            "tax_accountant": "Tax Accountant",
            "financial_analyst": "Financial Analyst",
            "chief_financial_officer__cfo_": "Chief Financial Officer (CFO)",
            "forensic_accountant": "Forensic Accountant",
            "accounts_payable_specialist": "Accounts Payable Specialist",
            "accounts_receivable_specialist": "Accounts Receivable Specialist",
            "internal_auditor": "Internal Auditor",
            "controller": "Controller",
            "budget_analyst": "Budget Analyst"
          }
        },
        "agriculture": {
          "label": "Agriculture",
          "roles": {
            "farm_manager": "Farm Manager",
            "agronomist": "Agronomist",
            "agricultural_engineer": "Agricultural Engineer",
            "crop_scientist": "Crop Scientist",
            "livestock_manager": "Livestock Manager",
            "soil_scientist": "Soil Scientist",
            "agricultural_consultant": "Agricultural Consultant",
            "irrigation_specialist": "Irrigation Specialist",
            "agribusiness_manager": "Agribusiness Manager",
            "agricultural_technician": "Agricultural Technician"
          }
        },
        "apparel": {
          "label": "Apparel",
          "roles": {
            "fashion_designer": "Fashion Designer",
            "merchandiser": "Merchandiser",
            "production_manager": "Production Manager",
            "textile_technologist": "Textile Technologist",
            "fashion_buyer": "Fashion Buyer",
            "pattern_maker": "Pattern Maker",
            "apparel_product_developer": "Apparel Product Developer",
            "fashion_illustrator": "Fashion Illustrator",
            "quality_control_inspector": "Quality Control Inspector",
            "fashion_marketing_specialist": "Fashion Marketing Specialist"
          }
        },
        "banking": {
          "label": "Banking",
          "roles": {
            "investment_banker": "Investment Banker",
            "loan_officer": "Loan Officer",
            "financial_advisor": "Financial Advisor",
            "branch_manager": "Branch Manager",
            "risk_manager": "Risk Manager",
            "credit_analyst": "Credit Analyst",
            "relationship_manager": "Relationship Manager",
            "compliance_officer": "Compliance Officer",
            "wealth_manager": "Wealth Manager",
            "treasury_analyst": "Treasury Analyst"
          }
        },
        "biotechnology": {
          "label": "Biotechnology",
          "roles": {
            "research_scientist": "Research Scientist",
            "bioprocess_engineer": "Bioprocess Engineer",
            "regulatory_affairs_specialist": "Regulatory Affairs Specialist",
            "bioinformatics_specialist": "Bioinformatics Specialist",
            "clinical_research_associate": "Clinical Research Associate",
            "biotechnology_technician": "Biotechnology Technician",
            "quality_assurance_specialist": "Quality Assurance Specialist",
            "biomedical_engineer": "Biomedical Engineer",
            "genetic_counselor": "Genetic Counselor",
            "biostatistician": "Biostatistician"
          }
        },
        "chemicals": {
          "label": "Chemicals",
          "roles": {
            "chemical_engineer": "Chemical Engineer",
            "process_chemist": "Process Chemist",
            "environmental_health_and_safety_specialist": "Environmental Health and Safety Specialist",
            "quality_control_manager": "Quality Control Manager",
            "production_supervisor": "Production Supervisor",
            "r_d_scientist": "R&D Scientist",
            "regulatory_compliance_specialist": "Regulatory Compliance Specialist",
            "analytical_chemist": "Analytical Chemist",
            "chemical_plant_operator": "Chemical Plant Operator",
            "formulation_chemist": "Formulation Chemist"
          }
        },
        "communications": {
          "label": "Communications",
          "roles": {
            "public_relations_specialist": "Public Relations Specialist",
            "marketing_manager": "Marketing Manager",
            "social_media_manager": "Social Media Manager",
            "corporate_communications_manager": "Corporate Communications Manager",
            "content_strategist": "Content Strategist",
            "communications_coordinator": "Communications Coordinator",
            "media_relations_specialist": "Media Relations Specialist",
            "internal_communications_specialist": "Internal Communications Specialist",
            "speechwriter": "Speechwriter",
            "copywriter": "Copywriter"
          }
        },
        "construction": {
          "label": "Construction",
          "roles": {
            "project_manager": "Project Manager",
            "civil_engineer": "Civil Engineer",
            "architect": "Architect",
            "construction_manager": "Construction Manager",
            "quantity_surveyor": "Quantity Surveyor",
            "site_supervisor": "Site Supervisor",
            "structural_engineer": "Structural Engineer",
            "building_inspector": "Building Inspector",
            "safety_manager": "Safety Manager",
            "estimator": "Estimator"
          }
        },
        "consulting": {
          "label": "Consulting",
          "roles": {
            "management_consultant": "Management Consultant",
            "strategy_analyst": "Strategy Analyst",
            "it_consultant": "IT Consultant",
            "human_resources_consultant": "Human Resources Consultant",
            "financial_consultant": "Financial Consultant",
            "business_analyst": "Business Analyst",
            "operations_consultant": "Operations Consultant",
            "marketing_consultant": "Marketing Consultant",
            "environmental_consultant": "Environmental Consultant",
            "legal_consultant": "Legal Consultant"
          }
        },
        "e_commerce": {
          "label": "E-Commerce",
          "roles": {
            "e_commerce_manager": "E-commerce Manager",
            "digital_marketing_specialist": "Digital Marketing Specialist",
            "customer_experience_manager": "Customer Experience Manager",
            "marketplace_analyst": "Marketplace Analyst",
            "supply_chain_manager": "Supply Chain Manager",
            "product_manager": "Product Manager",
            "seo_specialist": "SEO Specialist",
            "web_developer": "Web Developer",
            "data_analyst": "Data Analyst",
            "content_manager": "Content Manager"
          }
        },
        "education": {
          "label": "Education",
          "roles": {
            "teacher___educator": "Teacher / Educator",
            "school_administrator": "School Administrator",
            "curriculum_developer": "Curriculum Developer",
            "education_consultant": "Education Consultant",
            "instructional_designer": "Instructional Designer",
            "academic_advisor": "Academic Advisor",
            "educational_psychologist": "Educational Psychologist",
            "special_education_teacher": "Special Education Teacher",
            "school_counselor": "School Counselor",
            "librarian": "Librarian"
          }
        },
        "electronics": {
          "label": "Electronics",
          "roles": {
            "electrical_engineer": "Electrical Engineer",
            "hardware_designer": "Hardware Designer",
            "semiconductor_technician": "Semiconductor Technician",
            "embedded_systems_engineer": "Embedded Systems Engineer",
            "robotics_engineer": "Robotics Engineer",
            "electronics_technician": "Electronics Technician",
            "pcb_designer": "PCB Designer",
            "systems_engineer": "Systems Engineer",
            "quality_assurance_engineer": "Quality Assurance Engineer",
            "product_development_engineer": "Product Development Engineer"
          }
        },
        "energy": {
          "label": "Energy",
          "roles": {
            "energy_analyst": "Energy Analyst",
            "power_plant_engineer": "Power Plant Engineer",
            "renewable_energy_specialist": "Renewable Energy Specialist",
            "energy_trader": "Energy Trader",
            "environmental_specialist": "Environmental Specialist",
            "energy_consultant": "Energy Consultant",
            "energy_manager": "Energy Manager",
            "petroleum_engineer": "Petroleum Engineer",
            "solar_energy_technician": "Solar Energy Technician",
            "wind_turbine_technician": "Wind Turbine Technician"
          }
        },
        "engineering": {
          "label": "Engineering",
          "roles": {
            "mechanical_engineer": "Mechanical Engineer",
            "civil_engineer": "Civil Engineer",
            "aerospace_engineer": "Aerospace Engineer",
            "chemical_engineer": "Chemical Engineer",
            "electrical_engineer": "Electrical Engineer",
            "industrial_engineer": "Industrial Engineer",
            "software_engineer": "Software Engineer",
            "structural_engineer": "Structural Engineer",
            "environmental_engineer": "Environmental Engineer",
            "biomedical_engineer": "Biomedical Engineer"
          }
        },
        "entertainment": {
          "label": "Entertainment",
          "roles": {
            "actor___actress": "Actor / Actress",
            "film_director": "Film Director",
            "producer": "Producer",
            "screenwriter": "Screenwriter",
            "entertainment_lawyer": "Entertainment Lawyer",
            "casting_director": "Casting Director",
            "cinematographer": "Cinematographer",
            "sound_engineer": "Sound Engineer",
            "production_designer": "Production Designer",
            "talent_agent": "Talent Agent"
          }
        },
        "environmental": {
          "label": "Environmental",
          "roles": {
            "environmental_scientist": "Environmental Scientist",
            "environmental_engineer": "Environmental Engineer",
            "sustainability_consultant": "Sustainability Consultant",
            "ecologist": "Ecologist",
            "environmental_compliance_manager": "Environmental Compliance Manager",
            "conservation_scientist": "Conservation Scientist",
            "environmental_health_and_safety_specialist": "Environmental Health and Safety Specialist",
            "environmental_planner": "Environmental Planner",
            "environmental_educator": "Environmental Educator",
            "wildlife_biologist": "Wildlife Biologist"
          }
        },
        "finance": {
          "label": "Finance",
          "roles": {
            "financial_analyst": "Financial Analyst",
            "investment_banker": "Investment Banker",
            "portfolio_manager": "Portfolio Manager",
            "financial_planner": "Financial Planner",
            "risk_analyst": "Risk Analyst",
            "credit_analyst": "Credit Analyst",
            "compliance_officer": "Compliance Officer",
            "treasury_analyst": "Treasury Analyst",
            "financial_controller": "Financial Controller",
            "budget_analyst": "Budget Analyst"
          }
        },
        "food___beverage": {
          "label": "Food & Beverage",
          "roles": {
            "chef": "Chef",
            "food_scientist": "Food Scientist",
            "nutritionist": "Nutritionist",
            "food_safety_manager": "Food Safety Manager",
            "restaurant_manager": "Restaurant Manager",
            "beverage_manager": "Beverage Manager",
            "pastry_chef": "Pastry Chef",
            "sommelier": "Sommelier",
            "food_technologist": "Food Technologist",
            "quality_assurance_manager": "Quality Assurance Manager"
          }
        },
        "government": {
          "label": "Government",
          "roles": {
            "policy_analyst": "Policy Analyst",
            "civil_servant": "Civil Servant",
            "diplomat": "Diplomat",
            "government_relations_specialist": "Government Relations Specialist",
            "legislative_assistant": "Legislative Assistant",
            "public_administrator": "Public Administrator",
            "city_planner": "City Planner",
            "budget_analyst": "Budget Analyst",
            "intelligence_analyst": "Intelligence Analyst",
            "public_affairs_specialist": "Public Affairs Specialist"
          }
        },
        "graphic_design": {
          "label": "Graphic Design",
          "roles": {
            "graphic_designer": "Graphic Designer",
            "art_director": "Art Director",
            "illustrator": "Illustrator",
            "ux_ui_designer": "UX/UI Designer",
            "branding_specialist": "Branding Specialist",
            "creative_director": "Creative Director",
            "web_designer": "Web Designer",
            "motion_graphics_designer": "Motion Graphics Designer",
            "package_designer": "Package Designer",
            "visual_designer": "Visual Designer"
          }
        },
        "healthcare": {
          "label": "Healthcare",
          "roles": {
            "physician": "Physician",
            "nurse_practitioner": "Nurse Practitioner",
            "healthcare_administrator": "Healthcare Administrator",
            "medical_researcher": "Medical Researcher",
            "pharmacist": "Pharmacist",
            "medical_technologist": "Medical Technologist",
            "physical_therapist": "Physical Therapist",
            "occupational_therapist": "Occupational Therapist",
            "registered_nurse": "Registered Nurse",
            "radiologic_technologist": "Radiologic Technologist"
          }
        },
        "hospitality": {
          "label": "Hospitality",
          "roles": {
            "hotel_manager": "Hotel Manager",
            "event_planner": "Event Planner",
            "restaurant_manager": "Restaurant Manager",
            "hospitality_consultant": "Hospitality Consultant",
            "concierge": "Concierge",
            "front_desk_manager": "Front Desk Manager",
            "housekeeping_manager": "Housekeeping Manager",
            "food_and_beverage_manager": "Food and Beverage Manager",
            "banquet_manager": "Banquet Manager",
            "guest_services_manager": "Guest Services Manager"
          }
        },
        "insurance": {
          "label": "Insurance",
          "roles": {
            "actuary": "Actuary",
            "insurance_underwriter": "Insurance Underwriter",
            "claims_adjuster": "Claims Adjuster",
            "risk_manager": "Risk Manager",
            "insurance_broker": "Insurance Broker",
            "insurance_agent": "Insurance Agent",
            "loss_control_specialist": "Loss Control Specialist",
            "claims_examiner": "Claims Examiner",
            "compliance_officer": "Compliance Officer",
            "customer_service_representative": "Customer Service Representative"
          }
        },
        "legal": {
          "label": "Legal",
          "roles": {
            "lawyer": "Lawyer",
            "legal_secretary": "Legal Secretary",
            "paralegal": "Paralegal",
            "judge": "Judge",
            "legal_counsel": "Legal Counsel",
            "corporate_attorney": "Corporate Attorney",
            "litigation_attorney": "Litigation Attorney",
            "legal_assistant": "Legal Assistant",
            "compliance_officer": "Compliance Officer",
            "court_clerk": "Court Clerk"
          }
        },
        "machinery": {
          "label": "Machinery",
          "roles": {
            "mechanical_engineer": "Mechanical Engineer",
            "maintenance_technician": "Maintenance Technician",
            "production_manager": "Production Manager",
            "quality_control_engineer": "Quality Control Engineer",
            "operations_manager": "Operations Manager",
            "cnc_machinist": "CNC Machinist",
            "manufacturing_engineer": "Manufacturing Engineer",
            "field_service_technician": "Field Service Technician",
            "tool_and_die_maker": "Tool and Die Maker",
            "equipment_operator": "Equipment Operator"
          }
        },
        "manufacturing": {
          "label": "Manufacturing",
          "roles": {
            "production_manager": "Production Manager",
            "quality_assurance_manager": "Quality Assurance Manager",
            "supply_chain_manager": "Supply Chain Manager",
            "industrial_engineer": "Industrial Engineer",
            "manufacturing_engineer": "Manufacturing Engineer",
            "operations_manager": "Operations Manager",
            "maintenance_manager": "Maintenance Manager",
            "process_engineer": "Process Engineer",
            "plant_manager": "Plant Manager",
            "production_planner": "Production Planner"
          }
        },
        "marketing": {
          "label": "Marketing",
          "roles": {
            "marketing_manager": "Marketing Manager",
            "digital_marketing_specialist": "Digital Marketing Specialist",
            "market_research_analyst": "Market Research Analyst",
            "brand_manager": "Brand Manager",
            "public_relations_specialist": "Public Relations Specialist",
            "social_media_manager": "Social Media Manager",
            "content_marketing_specialist": "Content Marketing Specialist",
            "marketing_coordinator": "Marketing Coordinator",
            "seo_specialist": "SEO Specialist",
            "email_marketing_specialist": "Email Marketing Specialist"
          }
        },
        "media": {
          "label": "Media",
          "roles": {
            "journalist": "Journalist",
            "editor": "Editor",
            "tv_producer": "TV Producer",
            "media_buyer": "Media Buyer",
            "multimedia_artist": "Multimedia Artist",
            "broadcast_technician": "Broadcast Technician",
            "news_anchor": "News Anchor",
            "copywriter": "Copywriter",
            "video_editor": "Video Editor",
            "social_media_manager": "Social Media Manager"
          }
        },
        "mining": {
          "label": "Mining",
          "roles": {
            "geologist": "Geologist",
            "mining_engineer": "Mining Engineer",
            "environmental_specialist__mining_": "Environmental Specialist (Mining)",
            "safety_manager__mining_": "Safety Manager (Mining)",
            "mine_operations_manager": "Mine Operations Manager",
            "drilling_engineer": "Drilling Engineer",
            "metallurgist": "Metallurgist",
            "mine_surveyor": "Mine Surveyor",
            "mineral_processing_engineer": "Mineral Processing Engineer",
            "maintenance_planner": "Maintenance Planner"
          }
        },
        "not_for_profit": {
          "label": "Not For Profit",
          "roles": {
            "program_manager": "Program Manager",
            "fundraiser": "Fundraiser",
            "advocacy_coordinator": "Advocacy Coordinator",
            "grant_writer": "Grant Writer",
            "volunteer_coordinator": "Volunteer Coordinator",
            "community_outreach_manager": "Community Outreach Manager",
            "development_director": "Development Director",
            "nonprofit_administrator": "Nonprofit Administrator",
            "social_worker": "Social Worker",
            "communications_director": "Communications Director"
          }
        },
        "pharmaceuticals": {
          "label": "Pharmaceuticals",
          "roles": {
            "pharmaceutical_sales_representative": "Pharmaceutical Sales Representative",
            "research_scientist": "Research Scientist",
            "regulatory_affairs_specialist": "Regulatory Affairs Specialist",
            "clinical_trial_manager": "Clinical Trial Manager",
            "pharmacologist": "Pharmacologist",
            "quality_control_analyst": "Quality Control Analyst",
            "formulation_scientist": "Formulation Scientist",
            "medical_science_liaison": "Medical Science Liaison",
            "drug_safety_specialist": "Drug Safety Specialist",
            "biostatistician": "Biostatistician"
          }
        },
        "real_estate": {
          "label": "Real Estate",
          "roles": {
            "real_estate_agent": "Real Estate Agent",
            "property_manager": "Property Manager",
            "real_estate_developer": "Real Estate Developer",
            "appraiser": "Appraiser",
            "leasing_consultant": "Leasing Consultant",
            "real_estate_broker": "Real Estate Broker",
            "commercial_real_estate_agent": "Commercial Real Estate Agent",
            "real_estate_analyst": "Real Estate Analyst",
            "real_estate_marketing_specialist": "Real Estate Marketing Specialist",
            "title_examiner": "Title Examiner"
          }
        },
        "recreation": {
          "label": "Recreation",
          "roles": {
            "recreation_director": "Recreation Director",
            "event_coordinator": "Event Coordinator",
            "park_ranger": "Park Ranger",
            "fitness_instructor": "Fitness Instructor",
            "sports_coach": "Sports Coach",
            "recreation_therapist": "Recreation Therapist",
            "camp_director": "Camp Director",
            "outdoor_education_instructor": "Outdoor Education Instructor",
            "aquatics_director": "Aquatics Director",
            "adventure_guide": "Adventure Guide"
          }
        },
        "retail": {
          "label": "Retail",
          "roles": {
            "store_manager": "Store Manager",
            "sales_associate": "Sales Associate",
            "merchandiser": "Merchandiser",
            "retail_buyer": "Retail Buyer",
            "visual_merchandiser": "Visual Merchandiser",
            "inventory_manager": "Inventory Manager",
            "customer_service_representative": "Customer Service Representative",
            "cashier": "Cashier",
            "retail_manager": "Retail Manager",
            "loss_prevention_specialist": "Loss Prevention Specialist"
          }
        },
        "shipping": {
          "label": "Shipping",
          "roles": {
            "logistics_manager": "Logistics Manager",
            "shipping_coordinator": "Shipping Coordinator",
            "port_operations_manager": "Port Operations Manager",
            "freight_forwarder": "Freight Forwarder",
            "customs_broker": "Customs Broker",
            "shipping_clerk": "Shipping Clerk",
            "marine_engineer": "Marine Engineer",
            "cargo_inspector": "Cargo Inspector",
            "supply_chain_analyst": "Supply Chain Analyst",
            "deck_officer": "Deck Officer"
          }
        },
        "technology": {
          "label": "Technology",
          "roles": {
            "software_engineer": "Software Engineer",
            "data_scientist": "Data Scientist",
            "systems_analyst": "Systems Analyst",
            "it_project_manager": "IT Project Manager",
            "network_administrator": "Network Administrator",
            "cybersecurity_analyst": "Cybersecurity Analyst",
            "devops_engineer": "DevOps Engineer",
            "product_manager": "Product Manager",
            "technical_support_specialist": "Technical Support Specialist",
            "cloud_solutions_architect": "Cloud Solutions Architect"
          }
        },
        "telecommunications": {
          "label": "Telecommunications",
          "roles": {
            "network_engineer": "Network Engineer",
            "telecommunications_technician": "Telecommunications Technician",
            "systems_administrator": "Systems Administrator",
            "rf_engineer": "RF Engineer",
            "telecom_analyst": "Telecom Analyst",
            "customer_support_specialist": "Customer Support Specialist",
            "sales_engineer": "Sales Engineer",
            "telecommunications_manager": "Telecommunications Manager",
            "voip_engineer": "VoIP Engineer",
            "field_service_technician": "Field Service Technician"
          }
        },
        "transportation": {
          "label": "Transportation",
          "roles": {
            "transportation_manager": "Transportation Manager",
            "fleet_manager": "Fleet Manager",
            "logistics_coordinator": "Logistics Coordinator",
            "traffic_analyst": "Traffic Analyst",
            "transportation_planner": "Transportation Planner",
            "dispatch_supervisor": "Dispatch Supervisor",
            "supply_chain_manager": "Supply Chain Manager",
            "safety_coordinator": "Safety Coordinator",
            "public_transit_manager": "Public Transit Manager",
            "vehicle_maintenance_manager": "Vehicle Maintenance Manager"
          }
        },
        "utilities": {
          "label": "Utilities",
          "roles": {
            "utility_manager": "Utility Manager",
            "electrical_engineer": "Electrical Engineer",
            "water_treatment_specialist": "Water Treatment Specialist",
            "utility_analyst": "Utility Analyst",
            "energy_efficiency_consultant": "Energy Efficiency Consultant",
            "environmental_compliance_specialist": "Environmental Compliance Specialist",
            "utility_field_technician": "Utility Field Technician",
            "gas_controller": "Gas Controller",
            "operations_manager": "Operations Manager",
            "utility_planner": "Utility Planner"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Create document request with AI",
          "prompt_banner": "Enter the details for your request so we can generate a list of recommended items for you.",
          "generate_btn": "Generate items",
          "review_banner": "Based on the details provided, here are recommended items for your request. Select the ones you'd like to add.",
          "creation_progress": "Creating document request...",
          "generation_progress": "Generating items for your request...",
          "creation_error": {
            "message": "An error occurred while creating the document request.",
            "description": "Please try again."
          },
          "generation_error": {
            "message": "An error occurred while generating the items for your request.",
            "description": "Please try again."
          },
          "rate_limit_error": {
            "message": "You've reached the limit for generating items at this time.",
            "description": "Please wait a while before trying again."
          },
          "generation_form": {
            "request_name": "Request name",
            "due_date": "Due date",
            "request_purpose": "What is the purpose for this request?",
            "request_purpose_placeholder": "Things you can say:\n- Collect documents to onboard a new hire\n- Request personal documents like government ID, social security and other relevant documents.\n Avoid including any personal data (e.g., name, email address).",
            "employment_industry": "What industry do you work in?",
            "employment_role": "What is your role in the industry?",
            "remember_industry_role": "Remember industry and role"
          },
          "create_btn": "Create request"
        }
      }
    }
  },
  {
    "key": "es",
    "content": {
      "common": {
        "actions": "Acciones",
        "ai_caution": "Es posible que las recomendaciones de IA no sean precisas. Verifique los elementos generados.",
        "all_categories": "Todas las categorias",
        "back": "Atrás",
        "beta": "Beta",
        "type_or_select_one": "Escriba o seleccione una opción",
        "cancel": "Cancelar",
        "close": "Cerrar",
        "create": "Crear",
        "created_by": "Creado por",
        "created_on": "Creadas el",
        "delete": "Eliminar",
        "description": "Descripción",
        "details": "Detalles",
        "edit_details": "Modificar detalles",
        "more": "Más",
        "my_templates": "Mis plantillas",
        "name": "Nombre",
        "oops": "¡Lo sentimos!",
        "optional": "opcional",
        "preview": "Vista previa",
        "retry": "Reintentar",
        "search": "Buscar",
        "select": "Seleccionar",
        "shared_with_me": "Compartidos conmigo",
        "start_over": "Volver a empezar",
        "stop": "Detener",
        "template": "Plantilla",
        "templates": "Plantillas",
        "unknown": "desconocido",
        "was_this_helpful": "¿Le resultó útil?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "Se ha producido un error al cargar el contenido. Inténtelo de nuevo.",
          "no_resources": "No hay contenido que mostrar."
        }
      },
      "industries": {
        "accounting": {
          "label": "Contabilidad",
          "roles": {
            "auditor": "Auditor",
            "tax_accountant": "Asesor fiscal",
            "financial_analyst": "Analista financiero",
            "chief_financial_officer__cfo_": "Director financiero (CFO)",
            "forensic_accountant": "Contable forense",
            "accounts_payable_specialist": "Especialista en cuentas por pagar",
            "accounts_receivable_specialist": "Especialista en cuentas por cobrar",
            "internal_auditor": "Auditor interno",
            "controller": "Controller",
            "budget_analyst": "Analista de presupuestos"
          }
        },
        "agriculture": {
          "label": "Agricultura",
          "roles": {
            "farm_manager": "Administrador agrícola",
            "agronomist": "Agrónomo",
            "agricultural_engineer": "Ingeniero agrónomo",
            "crop_scientist": "Científico de cultivos",
            "livestock_manager": "Administrador ganadero",
            "soil_scientist": "Edafólogo",
            "agricultural_consultant": "Consultor agrícola",
            "irrigation_specialist": "Especialista en riego",
            "agribusiness_manager": "Gerente de agronegocios",
            "agricultural_technician": "Técnico agropecuario"
          }
        },
        "apparel": {
          "label": "Vestimenta",
          "roles": {
            "fashion_designer": "Diseñador de moda",
            "merchandiser": "Comerciante",
            "production_manager": "Gerente de producción",
            "textile_technologist": "Tecnólogo textil",
            "fashion_buyer": "Comprador de moda",
            "pattern_maker": "Creador de patrones",
            "apparel_product_developer": "Desarrollador de productos de indumentaria",
            "fashion_illustrator": "Ilustrador de moda",
            "quality_control_inspector": "Inspector de control de calidad",
            "fashion_marketing_specialist": "Especialista en marketing de moda"
          }
        },
        "banking": {
          "label": "Banca",
          "roles": {
            "investment_banker": "Banquero de inversión",
            "loan_officer": "Oficial de préstamos",
            "financial_advisor": "Asesor financiero",
            "branch_manager": "Gerente de sucursal",
            "risk_manager": "Administrador de riesgos",
            "credit_analyst": "Analista de crédito",
            "relationship_manager": "Gerente de relaciones",
            "compliance_officer": "Oficial de cumplimiento",
            "wealth_manager": "Gestor de patrimonio",
            "treasury_analyst": "Analista de tesorería"
          }
        },
        "biotechnology": {
          "label": "Biotecnología",
          "roles": {
            "research_scientist": "Investigador científico",
            "bioprocess_engineer": "Ingeniero de bioprocesos",
            "regulatory_affairs_specialist": "Especialista en asuntos regulatorios",
            "bioinformatics_specialist": "Especialista en bioinformática",
            "clinical_research_associate": "Asociado de investigación clínica",
            "biotechnology_technician": "Técnico en biotecnología",
            "quality_assurance_specialist": "Especialista en control de calidad",
            "biomedical_engineer": "Ingeniero biomédico",
            "genetic_counselor": "Asesor genético",
            "biostatistician": "Bioestadístico"
          }
        },
        "chemicals": {
          "label": "Productos químicos",
          "roles": {
            "chemical_engineer": "Ingeniero químico",
            "process_chemist": "Químico de procesos",
            "environmental_health_and_safety_specialist": "Especialista en salud y seguridad ambiental",
            "quality_control_manager": "Gerente de control de calidad",
            "production_supervisor": "Supervisor de producción",
            "r_d_scientist": "Científico de I+D",
            "regulatory_compliance_specialist": "Especialista en cumplimiento normativo",
            "analytical_chemist": "Químico analítico",
            "chemical_plant_operator": "Operario de planta química",
            "formulation_chemist": "Químico de formulación"
          }
        },
        "communications": {
          "label": "Comunicaciones",
          "roles": {
            "public_relations_specialist": "Especialista en relaciones públicas",
            "marketing_manager": "Gerente de marketing",
            "social_media_manager": "Gestor de redes sociales",
            "corporate_communications_manager": "Gerente de comunicaciones corporativas",
            "content_strategist": "Estratega de contenidos",
            "communications_coordinator": "Coordinador de comunicaciones",
            "media_relations_specialist": "Especialista en relaciones con los medios",
            "internal_communications_specialist": "Especialista en comunicaciones internas",
            "speechwriter": "Redactor de discursos",
            "copywriter": "Redactor"
          }
        },
        "construction": {
          "label": "Construcción",
          "roles": {
            "project_manager": "Gestor de proyectos",
            "civil_engineer": "Ingeniero civil",
            "architect": "Arquitecto",
            "construction_manager": "Gerente de construcción",
            "quantity_surveyor": "Topógrafo cuantitativo",
            "site_supervisor": "Supervisor de obra",
            "structural_engineer": "Ingeniero estructural",
            "building_inspector": "Inspector de construcción",
            "safety_manager": "Gestor de seguridad",
            "estimator": "Estimador"
          }
        },
        "consulting": {
          "label": "Consultoría",
          "roles": {
            "management_consultant": "Consultor de gestión",
            "strategy_analyst": "Analista de estrategia",
            "it_consultant": "Consultor de TI",
            "human_resources_consultant": "Consultor de recursos humanos",
            "financial_consultant": "Consultor financiero",
            "business_analyst": "Analista de negocios",
            "operations_consultant": "Consultor de operaciones",
            "marketing_consultant": "Consultor de marketing",
            "environmental_consultant": "Consultor ambiental",
            "legal_consultant": "Asesor jurídico"
          }
        },
        "e_commerce": {
          "label": "Comercio electrónico",
          "roles": {
            "e_commerce_manager": "Gerente de comercio electrónico",
            "digital_marketing_specialist": "Especialista en marketing digital",
            "customer_experience_manager": "Gestor de experiencia del cliente",
            "marketplace_analyst": "Analista de mercado",
            "supply_chain_manager": "Gestor de cadena de suministro",
            "product_manager": "Gerente de producto",
            "seo_specialist": "Especialista en SEO",
            "web_developer": "Desarrollador web",
            "data_analyst": "Analista de datos",
            "content_manager": "Gestor de contenidos"
          }
        },
        "education": {
          "label": "Educación",
          "roles": {
            "teacher___educator": "Profesor/educador",
            "school_administrator": "Administrador escolar",
            "curriculum_developer": "Desarrollador de planes de estudios",
            "education_consultant": "Asesor educativo",
            "instructional_designer": "Diseñador instruccional",
            "academic_advisor": "Asesor académico",
            "educational_psychologist": "Psicólogo educacional",
            "special_education_teacher": "Profesor de educación especial",
            "school_counselor": "Orientador educativo",
            "librarian": "Bibliotecario"
          }
        },
        "electronics": {
          "label": "Electrónica",
          "roles": {
            "electrical_engineer": "Ingeniero eléctrico",
            "hardware_designer": "Diseñador de hardware",
            "semiconductor_technician": "Técnico en semiconductores",
            "embedded_systems_engineer": "Ingeniero de sistemas embebidos",
            "robotics_engineer": "Ingeniero de robótica",
            "electronics_technician": "Técnico en electrónica",
            "pcb_designer": "Diseñador de PCB",
            "systems_engineer": "Ingeniero de sistemas",
            "quality_assurance_engineer": "Ingeniero de control de calidad",
            "product_development_engineer": "Ingeniero de desarrollo de productos"
          }
        },
        "energy": {
          "label": "Energía",
          "roles": {
            "energy_analyst": "Analista de energía",
            "power_plant_engineer": "Ingeniero de centrales eléctricas",
            "renewable_energy_specialist": "Especialista en energías renovables",
            "energy_trader": "Comerciante de energía",
            "environmental_specialist": "Especialista ambiental",
            "energy_consultant": "Consultor energético",
            "energy_manager": "Gestor energético",
            "petroleum_engineer": "Ingeniero petrolero",
            "solar_energy_technician": "Técnico en energía solar",
            "wind_turbine_technician": "Técnico en turbinas eólicas"
          }
        },
        "engineering": {
          "label": "Ingeniería",
          "roles": {
            "mechanical_engineer": "Ingeniero mecánico",
            "civil_engineer": "Ingeniero civil",
            "aerospace_engineer": "Ingeniero aeroespacial",
            "chemical_engineer": "Ingeniero químico",
            "electrical_engineer": "Ingeniero eléctrico",
            "industrial_engineer": "Ingeniero industrial",
            "software_engineer": "Ingeniero de software",
            "structural_engineer": "Ingeniero estructural",
            "environmental_engineer": "Ingeniero ambiental",
            "biomedical_engineer": "Ingeniero biomédico"
          }
        },
        "entertainment": {
          "label": "Entretenimiento",
          "roles": {
            "actor___actress": "Actor/Actriz",
            "film_director": "Director de cine",
            "producer": "Productor",
            "screenwriter": "Guionista",
            "entertainment_lawyer": "Abogado del sector del entretenimiento",
            "casting_director": "Director de casting",
            "cinematographer": "Cinematógrafo",
            "sound_engineer": "Ingeniero de sonido",
            "production_designer": "Diseñador de producción",
            "talent_agent": "Agente de talentos"
          }
        },
        "environmental": {
          "label": "Medio ambiente",
          "roles": {
            "environmental_scientist": "Científico ambiental",
            "environmental_engineer": "Ingeniero ambiental",
            "sustainability_consultant": "Consultor de sostenibilidad",
            "ecologist": "Ecologista",
            "environmental_compliance_manager": "Gestor de cumplimiento ambiental",
            "conservation_scientist": "Científico especializado en conservación",
            "environmental_health_and_safety_specialist": "Especialista en salud y seguridad ambiental",
            "environmental_planner": "Planificador ambiental",
            "environmental_educator": "Educador ambiental",
            "wildlife_biologist": "Biólogo de fauna silvestre"
          }
        },
        "finance": {
          "label": "Finanzas",
          "roles": {
            "financial_analyst": "Analista financiero",
            "investment_banker": "Banquero de inversión",
            "portfolio_manager": "Gestor de carteras",
            "financial_planner": "Planificador financiero",
            "risk_analyst": "Analista de riesgos",
            "credit_analyst": "Analista de crédito",
            "compliance_officer": "Oficial de cumplimiento",
            "treasury_analyst": "Analista de tesorería",
            "financial_controller": "Controlador financiero",
            "budget_analyst": "Analista de presupuestos"
          }
        },
        "food___beverage": {
          "label": "Alimentación y bebidas",
          "roles": {
            "chef": "Cocinero",
            "food_scientist": "Científico alimentario",
            "nutritionist": "Nutricionista",
            "food_safety_manager": "Gestor de seguridad alimentaria",
            "restaurant_manager": "Gerente de restaurante",
            "beverage_manager": "Gerente de bebidas",
            "pastry_chef": "Repostero",
            "sommelier": "Sumiller",
            "food_technologist": "Tecnólogo alimentario",
            "quality_assurance_manager": "Gestor de control de calidad"
          }
        },
        "government": {
          "label": "Gubernamental",
          "roles": {
            "policy_analyst": "Analista de directivas",
            "civil_servant": "Funcionario público",
            "diplomat": "Diplomático",
            "government_relations_specialist": "Especialista en relaciones gubernamentales",
            "legislative_assistant": "Asistente legislativo",
            "public_administrator": "Administrador público",
            "city_planner": "Planificador urbano",
            "budget_analyst": "Analista de presupuestos",
            "intelligence_analyst": "Analista de inteligencia",
            "public_affairs_specialist": "Especialista en asuntos públicos"
          }
        },
        "graphic_design": {
          "label": "Diseño gráfico",
          "roles": {
            "graphic_designer": "Diseñador gráfico",
            "art_director": "Director de arte",
            "illustrator": "Ilustrador",
            "ux_ui_designer": "Diseñador de UX/IU",
            "branding_specialist": "Especialista en marcas",
            "creative_director": "Director creativo",
            "web_designer": "Diseñador web",
            "motion_graphics_designer": "Diseñador de gráficos animados",
            "package_designer": "Diseñador de paquetes",
            "visual_designer": "Diseñador visual"
          }
        },
        "healthcare": {
          "label": "Salud",
          "roles": {
            "physician": "Médico",
            "nurse_practitioner": "Práctico de enfermería",
            "healthcare_administrator": "Administrador de servicios de salud",
            "medical_researcher": "Investigador médico",
            "pharmacist": "Farmacéutico",
            "medical_technologist": "Tecnólogo médico",
            "physical_therapist": "Fisioterapeuta",
            "occupational_therapist": "Terapeuta ocupacional",
            "registered_nurse": "Enfermero titulado",
            "radiologic_technologist": "Tecnólogo radiológico"
          }
        },
        "hospitality": {
          "label": "Hospitalidad",
          "roles": {
            "hotel_manager": "Gerente de hotel",
            "event_planner": "Organizador de eventos",
            "restaurant_manager": "Gerente de restaurante",
            "hospitality_consultant": "Consultor hotelero",
            "concierge": "Conserje",
            "front_desk_manager": "Gerente de recepción",
            "housekeeping_manager": "Gerente de limpieza",
            "food_and_beverage_manager": "Gerente de alimentos y bebidas",
            "banquet_manager": "Gerente de banquetes",
            "guest_services_manager": "Gerente de servicios al huésped"
          }
        },
        "insurance": {
          "label": "Seguros",
          "roles": {
            "actuary": "Actuario",
            "insurance_underwriter": "Suscriptor de seguros",
            "claims_adjuster": "Ajustador de reclamaciones",
            "risk_manager": "Gestor de riesgos",
            "insurance_broker": "Corredor de seguros",
            "insurance_agent": "Agente de seguros",
            "loss_control_specialist": "Especialista en control de pérdidas",
            "claims_examiner": "Examinador de reclamaciones",
            "compliance_officer": "Oficial de cumplimiento",
            "customer_service_representative": "Representante de servicio al cliente"
          }
        },
        "legal": {
          "label": "Oficio",
          "roles": {
            "lawyer": "Abogado",
            "legal_secretary": "Secretario jurídico",
            "paralegal": "Auxiliar jurídico",
            "judge": "Juez",
            "legal_counsel": "Asesor jurídico",
            "corporate_attorney": "Abogado corporativo",
            "litigation_attorney": "Abogado litigante",
            "legal_assistant": "Asistente legal",
            "compliance_officer": "Oficial de cumplimiento",
            "court_clerk": "Secretario judicial"
          }
        },
        "machinery": {
          "label": "Maquinaria",
          "roles": {
            "mechanical_engineer": "Ingeniero mecánico",
            "maintenance_technician": "Técnico de mantenimiento",
            "production_manager": "Gerente de producción",
            "quality_control_engineer": "Ingeniero de control de calidad",
            "operations_manager": "Gestor de operaciones",
            "cnc_machinist": "Maquinista de CNC",
            "manufacturing_engineer": "Ingeniero de fabricación",
            "field_service_technician": "Técnico de servicios de campo",
            "tool_and_die_maker": "Fabricante de herramientas y troqueles",
            "equipment_operator": "Operador de equipo"
          }
        },
        "manufacturing": {
          "label": "Fabricación",
          "roles": {
            "production_manager": "Gerente de producción",
            "quality_assurance_manager": "Gestor de control de calidad",
            "supply_chain_manager": "Gestor de cadena de suministro",
            "industrial_engineer": "Ingeniero industrial",
            "manufacturing_engineer": "Ingeniero de fabricación",
            "operations_manager": "Gestor de operaciones",
            "maintenance_manager": "Gerente de mantenimiento",
            "process_engineer": "Ingeniero de procesos",
            "plant_manager": "Gerente de planta",
            "production_planner": "Planificador de producción"
          }
        },
        "marketing": {
          "label": "Márketing",
          "roles": {
            "marketing_manager": "Gerente de marketing",
            "digital_marketing_specialist": "Especialista en marketing digital",
            "market_research_analyst": "Analista de investigación de mercado",
            "brand_manager": "Gerente de marca",
            "public_relations_specialist": "Especialista en relaciones públicas",
            "social_media_manager": "Gestor de redes sociales",
            "content_marketing_specialist": "Especialista en marketing de contenidos",
            "marketing_coordinator": "Coordinador de marketing",
            "seo_specialist": "Especialista en SEO",
            "email_marketing_specialist": "Especialista en marketing por correo electrónico"
          }
        },
        "media": {
          "label": "Archivos multimedia",
          "roles": {
            "journalist": "Periodista",
            "editor": "Editor",
            "tv_producer": "Productor de TV",
            "media_buyer": "Comprador de medios",
            "multimedia_artist": "Artista multimedia",
            "broadcast_technician": "Técnico de radiodifusión",
            "news_anchor": "Presentador de noticias",
            "copywriter": "Redactor",
            "video_editor": "Editor de vídeo",
            "social_media_manager": "Gestor de redes sociales"
          }
        },
        "mining": {
          "label": "Minería",
          "roles": {
            "geologist": "Geólogo",
            "mining_engineer": "Ingeniero de minas",
            "environmental_specialist__mining_": "Especialista ambiental (minería)",
            "safety_manager__mining_": "Gestor de seguridad (minería)",
            "mine_operations_manager": "Gestor de operaciones mineras",
            "drilling_engineer": "Ingeniero de perforación",
            "metallurgist": "Metalúrgico",
            "mine_surveyor": "Topógrafo de minas",
            "mineral_processing_engineer": "Ingeniero de procesamiento de minerales",
            "maintenance_planner": "Planificador de mantenimiento"
          }
        },
        "not_for_profit": {
          "label": "Sin ánimo de lucro",
          "roles": {
            "program_manager": "Administrador de programas",
            "fundraiser": "Recaudador de fondos",
            "advocacy_coordinator": "Coordinador de campañas",
            "grant_writer": "Redactor de propuestas para subvenciones",
            "volunteer_coordinator": "Coordinador de voluntarios",
            "community_outreach_manager": "Responsable de relaciones con la comunidad",
            "development_director": "Director de desarrollo",
            "nonprofit_administrator": "Administrador de organizaciones sin ánimo de lucro",
            "social_worker": "Trabajador social",
            "communications_director": "Director de comunicaciones"
          }
        },
        "pharmaceuticals": {
          "label": "Productos farmacéuticos",
          "roles": {
            "pharmaceutical_sales_representative": "Representante de ventas farmacéuticas",
            "research_scientist": "Investigador científico",
            "regulatory_affairs_specialist": "Especialista en asuntos regulatorios",
            "clinical_trial_manager": "Director de ensayos clínicos",
            "pharmacologist": "Farmacólogo",
            "quality_control_analyst": "Analista de control de calidad",
            "formulation_scientist": "Científico de formulación",
            "medical_science_liaison": "Enlace médico científico (MSL)",
            "drug_safety_specialist": "Especialista en seguridad de medicamentos",
            "biostatistician": "Bioestadístico"
          }
        },
        "real_estate": {
          "label": "Bien inmueble",
          "roles": {
            "real_estate_agent": "Agente inmobiliario",
            "property_manager": "Administrador de inmuebles",
            "real_estate_developer": "Promotor Inmobiliario",
            "appraiser": "Tasador",
            "leasing_consultant": "Consultor de arrendamientos",
            "real_estate_broker": "Corredor inmobiliario",
            "commercial_real_estate_agent": "Agente inmobiliario comercial",
            "real_estate_analyst": "Analista inmobiliario",
            "real_estate_marketing_specialist": "Especialista en marketing inmobiliario",
            "title_examiner": "Examinador de títulos"
          }
        },
        "recreation": {
          "label": "Recreación",
          "roles": {
            "recreation_director": "Director de ocio y tiempo libre",
            "event_coordinator": "Coordinador de eventos",
            "park_ranger": "Guardabosques",
            "fitness_instructor": "Instructor de fitness",
            "sports_coach": "Entrenador deportivo",
            "recreation_therapist": "Terapeuta de recreación",
            "camp_director": "Director de campamento",
            "outdoor_education_instructor": "Instructor de actividades al aire libre",
            "aquatics_director": "Director de actividades acuáticas",
            "adventure_guide": "Guía de aventuras"
          }
        },
        "retail": {
          "label": "Retail",
          "roles": {
            "store_manager": "Gerente de tienda",
            "sales_associate": "Asociado de ventas",
            "merchandiser": "Comerciante",
            "retail_buyer": "Comprador minorista",
            "visual_merchandiser": "Escaparatista",
            "inventory_manager": "Gestor de inventario",
            "customer_service_representative": "Representante de servicio al cliente",
            "cashier": "Cajero",
            "retail_manager": "Gerente de ventas",
            "loss_prevention_specialist": "Especialista en prevención de pérdidas"
          }
        },
        "shipping": {
          "label": "Envíos",
          "roles": {
            "logistics_manager": "Gestor de logística",
            "shipping_coordinator": "Coordinador de envíos",
            "port_operations_manager": "Gestor de operaciones portuarias",
            "freight_forwarder": "Transitario",
            "customs_broker": "Agente de aduanas",
            "shipping_clerk": "Empleado de envíos",
            "marine_engineer": "Ingeniero naval",
            "cargo_inspector": "Inspector de carga",
            "supply_chain_analyst": "Analista de cadena de suministro",
            "deck_officer": "Oficial de cubierta"
          }
        },
        "technology": {
          "label": "Tecnología",
          "roles": {
            "software_engineer": "Ingeniero de software",
            "data_scientist": "Científico de datos",
            "systems_analyst": "Analista de sistemas",
            "it_project_manager": "Gestor de proyectos de TI",
            "network_administrator": "Administrador de red",
            "cybersecurity_analyst": "Analista de ciberseguridad",
            "devops_engineer": "Ingeniero de DevOps",
            "product_manager": "Gerente de producto",
            "technical_support_specialist": "Especialista de asistencia técnica",
            "cloud_solutions_architect": "Arquitecto de soluciones de nube"
          }
        },
        "telecommunications": {
          "label": "Telecomunicaciones",
          "roles": {
            "network_engineer": "Ingeniero de redes",
            "telecommunications_technician": "Técnico de telecomunicaciones",
            "systems_administrator": "Administrador de sistemas",
            "rf_engineer": "Ingeniero de RF",
            "telecom_analyst": "Analista de telecomunicaciones",
            "customer_support_specialist": "Especialista en atención al cliente",
            "sales_engineer": "Ingeniero de ventas",
            "telecommunications_manager": "Gerente de telecomunicaciones",
            "voip_engineer": "Ingeniero de VoIP",
            "field_service_technician": "Técnico de servicios de campo"
          }
        },
        "transportation": {
          "label": "Transporte",
          "roles": {
            "transportation_manager": "Gestor de transporte",
            "fleet_manager": "Gestor de flota",
            "logistics_coordinator": "Coordinador de logística",
            "traffic_analyst": "Analista de tráfico",
            "transportation_planner": "Planificador de transporte",
            "dispatch_supervisor": "Supervisor de despacho",
            "supply_chain_manager": "Gestor de cadena de suministro",
            "safety_coordinator": "Coordinador de seguridad",
            "public_transit_manager": "Gestor de traánsito público",
            "vehicle_maintenance_manager": "Gerente de mantenimiento de vehículos"
          }
        },
        "utilities": {
          "label": "Utilidades",
          "roles": {
            "utility_manager": "Gestor de servicios públicos",
            "electrical_engineer": "Ingeniero eléctrico",
            "water_treatment_specialist": "Especialista en tratamiento de aguas",
            "utility_analyst": "Analista de servicios públicos",
            "energy_efficiency_consultant": "Consultor en eficiencia energética",
            "environmental_compliance_specialist": "Especialista en cumplimiento ambiental",
            "utility_field_technician": "Técnico de campo de servicios públicos",
            "gas_controller": "Controlador de gas",
            "operations_manager": "Gestor de operaciones",
            "utility_planner": "Planificador de servicios públicos"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Crear solicitud de documento con IA",
          "prompt_banner": "Introduzca los detalles de su solicitud para que podamos generar una lista de elementos recomendados para usted.",
          "generate_btn": "Generar elementos",
          "review_banner": "En función de los detalles proporcionados, estos son los elementos recomendados para su solicitud. Seleccione los que quiera agregar.",
          "creation_progress": "Creando solicitud de documento...",
          "generation_progress": "Generando elementos para su solicitud...",
          "creation_error": {
            "message": "Se ha producido un error al crear la solicitud de documento.",
            "description": "Vuelva a intentarlo."
          },
          "generation_error": {
            "message": "Se ha producido un error al generar los elementos para su solicitud.",
            "description": "Vuelva a intentarlo."
          },
          "generation_form": {
            "request_name": "Nombre de la solicitud",
            "due_date": "Fecha de entrega",
            "request_purpose": "¿Cuál es el propósito de esta solicitud?",
            "request_purpose_placeholder": "Cosas que puede decir:\n- Recopilar documentos para incorporar a un nuevo empleado.\n- Solicitar documentos personales como DNI, número de la seguridad social y otros documentos relevantes.\n Evite incluir datos personales (por ejemplo, nombre o dirección de correo electrónico).",
            "employment_industry": "¿En qué sector trabaja?",
            "employment_role": "¿Qué cargo desempeña en el sector?",
            "remember_industry_role": "Recordar sector y rol"
          },
          "create_btn": "Crear solicitud"
        }
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "common": {
        "actions": "Actions",
        "ai_caution": "Les recommandations IA peuvent ne pas être exactes. Vérifiez les éléments générés.",
        "all_categories": "Toutes les catégories",
        "back": "Précédent",
        "beta": "Bêta",
        "type_or_select_one": "Tapez ou sélectionnez un élément",
        "cancel": "Annuler",
        "close": "Fermer",
        "create": "Créer",
        "created_by": "Créé par",
        "created_on": "Créé le",
        "delete": "Supprimer",
        "description": "Description",
        "details": "Détails",
        "edit_details": "Modifier les détails",
        "more": "Plus",
        "my_templates": "Mes modèles",
        "name": "Nom",
        "oops": "Désolé !",
        "optional": "facultatif",
        "preview": "Aperçu",
        "retry": "Réessayer",
        "search": "Rechercher",
        "select": "Sélectionner",
        "shared_with_me": "Partagés avec moi",
        "start_over": "Redémarrer",
        "stop": "Arrêter",
        "template": "Modèle",
        "templates": "Modèles",
        "unknown": "inconnu",
        "was_this_helpful": "Cela vous a-t-il été utile ?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "Une erreur s'est produite lors du chargement des contenus. Réessayez.",
          "no_resources": "Aucun contenu à afficher."
        }
      },
      "industries": {
        "accounting": {
          "label": "Comptabilité",
          "roles": {
            "auditor": "Auditeur",
            "tax_accountant": "Conseiller fiscal",
            "financial_analyst": "Analyste financier",
            "chief_financial_officer__cfo_": "Directeur financier (CFO)",
            "forensic_accountant": "Expert judiciaire",
            "accounts_payable_specialist": "Spécialiste des comptes créditeurs",
            "accounts_receivable_specialist": "Spécialiste des comptes client",
            "internal_auditor": "Auditeur interne",
            "controller": "Contrôleur",
            "budget_analyst": "Analyste du budget"
          }
        },
        "agriculture": {
          "label": "Agriculture",
          "roles": {
            "farm_manager": "Gérant d'exploitation agricole",
            "agronomist": "Agronome",
            "agricultural_engineer": "Ingénieur agricole",
            "crop_scientist": "Scientifique des cultures",
            "livestock_manager": "Responsable de l'élevage",
            "soil_scientist": "Scientifique des sols",
            "agricultural_consultant": "Consultant agricole",
            "irrigation_specialist": "Spécialiste de l'irrigation",
            "agribusiness_manager": "Responsable agroalimentaire",
            "agricultural_technician": "Technicien agricole"
          }
        },
        "apparel": {
          "label": "Habillement",
          "roles": {
            "fashion_designer": "Styliste de mode",
            "merchandiser": "Marchandiseur",
            "production_manager": "Directeur de production",
            "textile_technologist": "Technologue textile",
            "fashion_buyer": "Acheteur de mode",
            "pattern_maker": "Créateur de patrons",
            "apparel_product_developer": "Créateur de vêtements",
            "fashion_illustrator": "Illustrateur de mode",
            "quality_control_inspector": "Contrôleur de la qualité",
            "fashion_marketing_specialist": "Spécialiste en marketing de mode"
          }
        },
        "banking": {
          "label": "Services bancaires",
          "roles": {
            "investment_banker": "Spécialiste des services de banque d'investissement",
            "loan_officer": "Agent de prêts",
            "financial_advisor": "Conseiller financier",
            "branch_manager": "Directeur de succursale",
            "risk_manager": "Gestionnaire des risques",
            "credit_analyst": "Analyste du crédit",
            "relationship_manager": "Responsable des relations",
            "compliance_officer": "Agent de conformité",
            "wealth_manager": "Gestionnaire de fortune",
            "treasury_analyst": "Analyste de trésorerie"
          }
        },
        "biotechnology": {
          "label": "Biotechnologie",
          "roles": {
            "research_scientist": "Chercheur scientifique",
            "bioprocess_engineer": "Ingénieur en bioprocédés",
            "regulatory_affairs_specialist": "Spécialiste des affaires réglementaires",
            "bioinformatics_specialist": "Spécialiste en bioinformatique",
            "clinical_research_associate": "Associé de recherche clinique",
            "biotechnology_technician": "Technicien en biotechnologie",
            "quality_assurance_specialist": "Spécialiste de l'assurance qualité",
            "biomedical_engineer": "Ingénieur biomédical",
            "genetic_counselor": "Conseiller en génétique",
            "biostatistician": "Biostatisticien"
          }
        },
        "chemicals": {
          "label": "Produits chimiques",
          "roles": {
            "chemical_engineer": "Ingénieur chimiste",
            "process_chemist": "Chimiste des procédés",
            "environmental_health_and_safety_specialist": "Spécialiste de la santé et de la sécurité environnementales",
            "quality_control_manager": "Responsable du contrôle qualité",
            "production_supervisor": "Surveillant de la production",
            "r_d_scientist": "Spécialiste recherche & développement scientifique",
            "regulatory_compliance_specialist": "Spécialiste conformité réglementaire",
            "analytical_chemist": "Chimiste analytique",
            "chemical_plant_operator": "Opérateur d'usine chimique",
            "formulation_chemist": "Ingénieur en chimie de formulation"
          }
        },
        "communications": {
          "label": "Communications",
          "roles": {
            "public_relations_specialist": "Spécialiste des relations publiques",
            "marketing_manager": "Responsable marketing",
            "social_media_manager": "Responsable des réseaux sociaux",
            "corporate_communications_manager": "Responsable des communications d'entreprise",
            "content_strategist": "Stratège de contenu",
            "communications_coordinator": "Coordinateur des communications",
            "media_relations_specialist": "Spécialiste des relations avec les médias",
            "internal_communications_specialist": "Spécialiste des communications internes",
            "speechwriter": "Rédacteur de discours",
            "copywriter": "Concepteur-rédacteur"
          }
        },
        "construction": {
          "label": "Construction",
          "roles": {
            "project_manager": "Responsable de projet",
            "civil_engineer": "Ingénieur civil",
            "architect": "Architecte",
            "construction_manager": "Responsable de la construction",
            "quantity_surveyor": "Métreur",
            "site_supervisor": "Superviseur de chantier",
            "structural_engineer": "Ingénieur de structures",
            "building_inspector": "Inspecteur en bâtiment",
            "safety_manager": "Responsable de la sécurité",
            "estimator": "Estimateur"
          }
        },
        "consulting": {
          "label": "Expertise-conseil",
          "roles": {
            "management_consultant": "Consultant en gestion",
            "strategy_analyst": "Analyste de stratégie",
            "it_consultant": "Consultant informatique",
            "human_resources_consultant": "Consultant en ressources humaines",
            "financial_consultant": "Consultant financier",
            "business_analyst": "Analyste d'affaires",
            "operations_consultant": "Consultant en opérations",
            "marketing_consultant": "Consultant en marketing",
            "environmental_consultant": "Consultant en environnement",
            "legal_consultant": "Consultant juridique"
          }
        },
        "e_commerce": {
          "label": "Commerce électronique",
          "roles": {
            "e_commerce_manager": "Responsable du commerce électronique",
            "digital_marketing_specialist": "Spécialiste en marketing numérique",
            "customer_experience_manager": "Responsable de l'expérience client",
            "marketplace_analyst": "Analyste du marché",
            "supply_chain_manager": "Responsable de la chaîne logistique",
            "product_manager": "Chef de produit",
            "seo_specialist": "Spécialiste du référencement",
            "web_developer": "Développeur Web",
            "data_analyst": "Analyste de données",
            "content_manager": "Responsable du contenu"
          }
        },
        "education": {
          "label": "Éducation",
          "roles": {
            "teacher___educator": "Enseignant/Éducateur",
            "school_administrator": "Administrateur d'école",
            "curriculum_developer": "Élaborateur de programmes d'études",
            "education_consultant": "Conseiller en éducation",
            "instructional_designer": "Concepteur pédagogique",
            "academic_advisor": "Conseiller universitaire",
            "educational_psychologist": "Psychologue scolaire",
            "special_education_teacher": "Enseignant spécialisé",
            "school_counselor": "Conseiller scolaire",
            "librarian": "Bibliothécaire"
          }
        },
        "electronics": {
          "label": "Électronique",
          "roles": {
            "electrical_engineer": "Ingénieur électricien",
            "hardware_designer": "Concepteur de matériel",
            "semiconductor_technician": "Technicien en semi-conducteurs",
            "embedded_systems_engineer": "Ingénieur des systèmes embarqués",
            "robotics_engineer": "Ingénieur en robotique",
            "electronics_technician": "Technicien en électronique",
            "pcb_designer": "Concepteur de circuits imprimés",
            "systems_engineer": "Ingénieur de systèmes",
            "quality_assurance_engineer": "Ingénieur en assurance qualité",
            "product_development_engineer": "Ingénieur en développement de produits"
          }
        },
        "energy": {
          "label": "Énergie",
          "roles": {
            "energy_analyst": "Analyste en énergie",
            "power_plant_engineer": "Ingénieur de centrale électrique",
            "renewable_energy_specialist": "Spécialiste des énergies renouvelables",
            "energy_trader": "Négociant en énergie",
            "environmental_specialist": "Spécialiste de l'environnement",
            "energy_consultant": "Consultant en énergie",
            "energy_manager": "Responsable de l'énergie",
            "petroleum_engineer": "Ingénieur pétrolier",
            "solar_energy_technician": "Technicien en énergie solaire",
            "wind_turbine_technician": "Technicien en éoliennes"
          }
        },
        "engineering": {
          "label": "Ingénierie",
          "roles": {
            "mechanical_engineer": "Ingénieur mécanique",
            "civil_engineer": "Ingénieur civil",
            "aerospace_engineer": "Ingénieur en aérospatiale",
            "chemical_engineer": "Ingénieur chimiste",
            "electrical_engineer": "Ingénieur électricien",
            "industrial_engineer": "Ingénieur industriel",
            "software_engineer": "Ingénieur logiciel",
            "structural_engineer": "Ingénieur de structures",
            "environmental_engineer": "Ingénieur environnemental",
            "biomedical_engineer": "Ingénieur biomédical"
          }
        },
        "entertainment": {
          "label": "Divertissement",
          "roles": {
            "actor___actress": "Acteur/Actrice",
            "film_director": "Réalisateur de films",
            "producer": "Producteur",
            "screenwriter": "Scénariste",
            "entertainment_lawyer": "Avocat du divertissement",
            "casting_director": "Directeur de casting",
            "cinematographer": "Cinéaste",
            "sound_engineer": "Ingénieur du son",
            "production_designer": "Concepteur de production",
            "talent_agent": "Agent artistique"
          }
        },
        "environmental": {
          "label": "Environnemental",
          "roles": {
            "environmental_scientist": "Scientifique de l'environnement",
            "environmental_engineer": "Ingénieur environnemental",
            "sustainability_consultant": "Consultant en développement durable",
            "ecologist": "Écologiste",
            "environmental_compliance_manager": "Responsable de la conformité environnementale",
            "conservation_scientist": "Scientifique en conservation",
            "environmental_health_and_safety_specialist": "Spécialiste de la santé et de la sécurité environnementales",
            "environmental_planner": "Planificateur environnemental",
            "environmental_educator": "Éducateur environnemental",
            "wildlife_biologist": "Biologiste de la faune"
          }
        },
        "finance": {
          "label": "Finances",
          "roles": {
            "financial_analyst": "Analyste financier",
            "investment_banker": "Spécialiste des services de banque d'investissement",
            "portfolio_manager": "Responsable de portefeuille",
            "financial_planner": "Planificateur financier",
            "risk_analyst": "Analyste des risques",
            "credit_analyst": "Analyste du crédit",
            "compliance_officer": "Agent de conformité",
            "treasury_analyst": "Analyste de trésorerie",
            "financial_controller": "Contrôleur financier",
            "budget_analyst": "Analyste du budget"
          }
        },
        "food___beverage": {
          "label": "Alimentation & boissons",
          "roles": {
            "chef": "Chef",
            "food_scientist": "Spécialiste en recherche scientifique alimentaire",
            "nutritionist": "Nutritionniste",
            "food_safety_manager": "Responsable de la sécurité alimentaire",
            "restaurant_manager": "Gérant de restaurant",
            "beverage_manager": "Responsable des boissons",
            "pastry_chef": "Chef pâtissier",
            "sommelier": "Sommelier",
            "food_technologist": "Technologue alimentaire",
            "quality_assurance_manager": "Responsable de l'assurance qualité"
          }
        },
        "government": {
          "label": "Gouvernement",
          "roles": {
            "policy_analyst": "Analyste de politiques",
            "civil_servant": "Fonctionnaire",
            "diplomat": "Diplomate",
            "government_relations_specialist": "Spécialiste des relations gouvernementales",
            "legislative_assistant": "Adjoint législatif",
            "public_administrator": "Administrateur public",
            "city_planner": "Urbaniste",
            "budget_analyst": "Analyste du budget",
            "intelligence_analyst": "Analyste du renseignement",
            "public_affairs_specialist": "Spécialiste des affaires publiques"
          }
        },
        "graphic_design": {
          "label": "Conception graphique",
          "roles": {
            "graphic_designer": "Concepteur graphique",
            "art_director": "Directeur artistique",
            "illustrator": "Illustrateur",
            "ux_ui_designer": "Concepteur UX/UI",
            "branding_specialist": "Spécialiste de l’image de marque",
            "creative_director": "Directeur de création",
            "web_designer": "Concepteur Web",
            "motion_graphics_designer": "Concepteur d'animations graphiques",
            "package_designer": "Concepteur d'emballages",
            "visual_designer": "Concepteur visuel"
          }
        },
        "healthcare": {
          "label": "Santé",
          "roles": {
            "physician": "Médecin",
            "nurse_practitioner": "Infirmier praticien",
            "healthcare_administrator": "Administrateur de soins de santé",
            "medical_researcher": "Chercheur médical",
            "pharmacist": "Pharmacien",
            "medical_technologist": "Technologue médical",
            "physical_therapist": "Physiothérapeute",
            "occupational_therapist": "Ergothérapeute",
            "registered_nurse": "Infirmier immatriculé",
            "radiologic_technologist": "Technologue en radiologie"
          }
        },
        "hospitality": {
          "label": "Hôtellerie",
          "roles": {
            "hotel_manager": "Directeur d'hôtel",
            "event_planner": "Organisateur d'événements",
            "restaurant_manager": "Gérant de restaurant",
            "hospitality_consultant": "Consultant en hôtellerie",
            "concierge": "Concierge",
            "front_desk_manager": "Responsable de la réception",
            "housekeeping_manager": "Responsable de l'entretien ménager",
            "food_and_beverage_manager": "Responsable de l'alimentation et des boissons",
            "banquet_manager": "Responsable des banquets",
            "guest_services_manager": "Responsable des services à la clientèle"
          }
        },
        "insurance": {
          "label": "Assurance",
          "roles": {
            "actuary": "Actuariat",
            "insurance_underwriter": "Souscripteur d'assurance",
            "claims_adjuster": "Expert en sinistres",
            "risk_manager": "Gestionnaire des risques",
            "insurance_broker": "Courtier d'assurance",
            "insurance_agent": "Agent d'assurance",
            "loss_control_specialist": "Spécialiste en prévention de sinistres",
            "claims_examiner": "Examinateur des réclamations",
            "compliance_officer": "Agent de conformité",
            "customer_service_representative": "Représentant du service à la clientèle"
          }
        },
        "legal": {
          "label": "Légal US",
          "roles": {
            "lawyer": "Avocat",
            "legal_secretary": "Secrétaire juridique",
            "paralegal": "Parajuriste",
            "judge": "Juge",
            "legal_counsel": "Conseiller juridique",
            "corporate_attorney": "Avocat d'entreprise",
            "litigation_attorney": "Avocat en contentieux",
            "legal_assistant": "Assistant juridique",
            "compliance_officer": "Agent de conformité",
            "court_clerk": "Greffier de tribunal"
          }
        },
        "machinery": {
          "label": "Machinerie",
          "roles": {
            "mechanical_engineer": "Ingénieur mécanique",
            "maintenance_technician": "Technicien de maintenance",
            "production_manager": "Directeur de production",
            "quality_control_engineer": "Ingénieur en contrôle qualité",
            "operations_manager": "Responsable des opérations",
            "cnc_machinist": "Machiniste CNC",
            "manufacturing_engineer": "Ingénieur en fabrication",
            "field_service_technician": "Technicien de service sur le terrain",
            "tool_and_die_maker": "Fabricant d'outils et de matrices",
            "equipment_operator": "Opérateur d'équipement"
          }
        },
        "manufacturing": {
          "label": "Fabrication",
          "roles": {
            "production_manager": "Directeur de production",
            "quality_assurance_manager": "Responsable de l'assurance qualité",
            "supply_chain_manager": "Responsable de la chaîne logistique",
            "industrial_engineer": "Ingénieur industriel",
            "manufacturing_engineer": "Ingénieur en fabrication",
            "operations_manager": "Responsable des opérations",
            "maintenance_manager": "Responsable de la maintenance",
            "process_engineer": "Ingénieur des procédés",
            "plant_manager": "Responsable d'usine",
            "production_planner": "Planificateur de la production"
          }
        },
        "marketing": {
          "label": "Marketing",
          "roles": {
            "marketing_manager": "Responsable marketing",
            "digital_marketing_specialist": "Spécialiste en marketing numérique",
            "market_research_analyst": "Analyste d'études de marché",
            "brand_manager": "Responsable de la marque",
            "public_relations_specialist": "Spécialiste des relations publiques",
            "social_media_manager": "Responsable des réseaux sociaux",
            "content_marketing_specialist": "Spécialiste en marketing de contenu",
            "marketing_coordinator": "Coordinateur marketing",
            "seo_specialist": "Spécialiste du référencement",
            "email_marketing_specialist": "Spécialiste en marketing par courrier électronique"
          }
        },
        "media": {
          "label": "Média",
          "roles": {
            "journalist": "Journaliste",
            "editor": "Rédacteur en chef",
            "tv_producer": "Producteur de télévision",
            "media_buyer": "Acheteur de médias",
            "multimedia_artist": "Artiste multimédia",
            "broadcast_technician": "Technicien de diffusion",
            "news_anchor": "Présentateur de journal télévisé",
            "copywriter": "Concepteur-rédacteur",
            "video_editor": "Contrôleur de montage",
            "social_media_manager": "Responsable des réseaux sociaux"
          }
        },
        "mining": {
          "label": "Exploitation minière",
          "roles": {
            "geologist": "Géologue",
            "mining_engineer": "Ingénieur des mines",
            "environmental_specialist__mining_": "Spécialiste de l'environnement (mines)",
            "safety_manager__mining_": "Responsable de la sécurité (mines)",
            "mine_operations_manager": "Responsable d'exploitation minière",
            "drilling_engineer": "Ingénieur de forage",
            "metallurgist": "Métallurgiste",
            "mine_surveyor": "Géomètre de mines",
            "mineral_processing_engineer": "Ingénieur en traitement des minéraux",
            "maintenance_planner": "Planificateur de maintenance"
          }
        },
        "not_for_profit": {
          "label": "Sans but lucratif",
          "roles": {
            "program_manager": "Gestionnaire de programmes",
            "fundraiser": "Levée de fonds",
            "advocacy_coordinator": "Coordinateur de plaidoyer",
            "grant_writer": "Rédacteur de subventions",
            "volunteer_coordinator": "Coordinateur du bénévolat",
            "community_outreach_manager": "Responsable de l'approche communautaire",
            "development_director": "Directeur du développement",
            "nonprofit_administrator": "Administrateur d'organisation à but non lucratif",
            "social_worker": "Travailleur social",
            "communications_director": "Directeur des communications"
          }
        },
        "pharmaceuticals": {
          "label": "Industrie pharmaceutique",
          "roles": {
            "pharmaceutical_sales_representative": "Représentant commercial en pharmacie",
            "research_scientist": "Chercheur scientifique",
            "regulatory_affairs_specialist": "Spécialiste des affaires réglementaires",
            "clinical_trial_manager": "Responsable des essais cliniques",
            "pharmacologist": "Pharmacologue",
            "quality_control_analyst": "Analyste du contrôle qualité",
            "formulation_scientist": "Scientifique en formulation",
            "medical_science_liaison": "Liaison des sciences médicales",
            "drug_safety_specialist": "Spécialiste de la sécurité des médicaments",
            "biostatistician": "Biostatisticien"
          }
        },
        "real_estate": {
          "label": "Immobilier",
          "roles": {
            "real_estate_agent": "Agent immobilier",
            "property_manager": "Gestionnaire immobilier",
            "real_estate_developer": "Promoteur immobilier",
            "appraiser": "Évaluateur",
            "leasing_consultant": "Conseiller en location",
            "real_estate_broker": "Courtier immobilier",
            "commercial_real_estate_agent": "Agent immobilier commercial",
            "real_estate_analyst": "Analyste immobilier",
            "real_estate_marketing_specialist": "Spécialiste en marketing immobilier",
            "title_examiner": "Examinateur de titres"
          }
        },
        "recreation": {
          "label": "Loisirs",
          "roles": {
            "recreation_director": "Directeur des loisirs",
            "event_coordinator": "Coordinateur d'évenements",
            "park_ranger": "Garde forestier",
            "fitness_instructor": "Instructeur de fitness",
            "sports_coach": "Coach sportif",
            "recreation_therapist": "Thérapeute en loisirs",
            "camp_director": "Directeur de camp",
            "outdoor_education_instructor": "Professeur d'éducation en plein air",
            "aquatics_director": "Directeur aquatique",
            "adventure_guide": "Guide d'aventure"
          }
        },
        "retail": {
          "label": "Détail",
          "roles": {
            "store_manager": "Responsable de magasin",
            "sales_associate": "Associé aux ventes",
            "merchandiser": "Marchandiseur",
            "retail_buyer": "Acheteur au détail",
            "visual_merchandiser": "Marchandiseur visuel",
            "inventory_manager": "Responsable de l'inventaire",
            "customer_service_representative": "Représentant du service à la clientèle",
            "cashier": "Caissier",
            "retail_manager": "Responsable des ventes au détail",
            "loss_prevention_specialist": "Spécialiste en prévention de sinistres"
          }
        },
        "shipping": {
          "label": "Expédition",
          "roles": {
            "logistics_manager": "Responsable logistique",
            "shipping_coordinator": "Coordinateur des expéditions",
            "port_operations_manager": "Responsable des opérations portuaires",
            "freight_forwarder": "Transitaire",
            "customs_broker": "Courtier en douane",
            "shipping_clerk": "Commis à l'expédition",
            "marine_engineer": "Ingénieur maritime",
            "cargo_inspector": "Inspecteur de fret",
            "supply_chain_analyst": "Analyste de la chaîne logistique",
            "deck_officer": "Officier de pont"
          }
        },
        "technology": {
          "label": "Technologie",
          "roles": {
            "software_engineer": "Ingénieur logiciel",
            "data_scientist": "Scientifique des données",
            "systems_analyst": "Analyste des systèmes",
            "it_project_manager": "Chef de projet informatique",
            "network_administrator": "Administrateur réseau",
            "cybersecurity_analyst": "Analyste en cybersécurité",
            "devops_engineer": "Ingénieur DevOps",
            "product_manager": "Chef de produit",
            "technical_support_specialist": "Spécialiste du support technique",
            "cloud_solutions_architect": "Architecte de solutions cloud"
          }
        },
        "telecommunications": {
          "label": "Télécommunications",
          "roles": {
            "network_engineer": "Ingénieur réseau",
            "telecommunications_technician": "Technicien en télécommunications",
            "systems_administrator": "Administrateur de systèmes",
            "rf_engineer": "Ingénieur RF",
            "telecom_analyst": "Analyste des télécommunications",
            "customer_support_specialist": "Spécialiste du support client",
            "sales_engineer": "Ingénieur commercial",
            "telecommunications_manager": "Responsable des télécommunications",
            "voip_engineer": "Ingénieur VoIP",
            "field_service_technician": "Technicien de service sur le terrain"
          }
        },
        "transportation": {
          "label": "Transports",
          "roles": {
            "transportation_manager": "Responsable du transport",
            "fleet_manager": "Responsable de flotte",
            "logistics_coordinator": "Coordinateur logistique",
            "traffic_analyst": "Analyste du trafic",
            "transportation_planner": "Planificateur des transports",
            "dispatch_supervisor": "Superviseur des expéditions",
            "supply_chain_manager": "Responsable de la chaîne logistique",
            "safety_coordinator": "Coordinateur de la sécurité",
            "public_transit_manager": "Responsable des transports en commun",
            "vehicle_maintenance_manager": "Responsable de l'entretien des véhicules"
          }
        },
        "utilities": {
          "label": "Utilitaires",
          "roles": {
            "utility_manager": "Responsable des services publics",
            "electrical_engineer": "Ingénieur électricien",
            "water_treatment_specialist": "Spécialiste du traitement de l'eau",
            "utility_analyst": "Analyste des services publics",
            "energy_efficiency_consultant": "Consultant en efficacité énergétique",
            "environmental_compliance_specialist": "Spécialiste de la conformité environnementale",
            "utility_field_technician": "Technicien de terrain des services publics",
            "gas_controller": "Contrôleur de gaz",
            "operations_manager": "Responsable des opérations",
            "utility_planner": "Planificateur de services publics"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Créer une demande de document avec l'IA",
          "prompt_banner": "Entrez les détails de votre demande afin que nous puissions générer une liste d'éléments recommandés.",
          "generate_btn": "Générer des éléments",
          "review_banner": "Sur la base des détails fournis, voici les éléments recommandés pour votre demande. Sélectionnez ceux que vous souhaitez ajouter.",
          "creation_progress": "Création de la demande de document...",
          "generation_progress": "Génération d'éléments pour votre demande...",
          "creation_error": {
            "message": "Une erreur s'est produite lors de la création de la demande de document.",
            "description": "Veuillez réessayer."
          },
          "generation_error": {
            "message": "Une erreur s'est produite lors de la génération des éléments de votre demande.",
            "description": "Veuillez réessayer."
          },
          "generation_form": {
            "request_name": "Nom de la demande",
            "due_date": "Échéance",
            "request_purpose": "Quel est le but de cette demande ?",
            "request_purpose_placeholder": "Choses que vous pouvez dire :\n- Rassemblez les documents d'intégration des nouvelles recrues\n- Demandez des documents personnels tels que les pièces d'identité délivrées par le gouvernement, des documents de sécurité sociale et autres documents pertinents.\n Évitez d'inclure des informations personnelles identifiables (nom et adresse e-mail, par exemple).",
            "employment_industry": "Dans quel secteur travaillez-vous ?",
            "employment_role": "Quelle profession exercez-vous dans ce secteur ?",
            "remember_industry_role": "N'oubliez pas le secteur et la profession"
          },
          "create_btn": "Créer une demande"
        }
      }
    }
  },
  {
    "key": "it",
    "content": {
      "common": {
        "actions": "Azioni",
        "ai_caution": "Le raccomandazioni dell'IA potrebbero non essere accurate. Verificare gli elementi generati.",
        "all_categories": "Tutte le categorie",
        "back": "Indietro",
        "beta": "Beta",
        "type_or_select_one": "Digitare o selezionare un elemento",
        "cancel": "Annulla",
        "close": "Chiudi",
        "create": "Crea",
        "created_by": "Creato da",
        "created_on": "Data di creazione",
        "delete": "Elimina",
        "description": "Descrizione",
        "details": "Dettagli",
        "edit_details": "Modifica dettagli",
        "more": "Altro",
        "my_templates": "Modelli personali",
        "name": "Nome",
        "oops": "Errore.",
        "optional": "facoltativo",
        "preview": "Anteprima",
        "retry": "Riprova",
        "search": "Cerca",
        "select": "Seleziona",
        "shared_with_me": "Condiviso con me",
        "start_over": "Comincia da inizio",
        "stop": "Interrompi",
        "template": "Modello",
        "templates": "Modelli",
        "unknown": "sconosciuto",
        "was_this_helpful": "È stato utile?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "Si è verificato un errore durante il caricamento del contenuto. Riprovare.",
          "no_resources": "Nessun contenuto da mostrare."
        }
      },
      "industries": {
        "accounting": {
          "label": "Contabilità",
          "roles": {
            "auditor": "Revisore",
            "tax_accountant": "Commercialista",
            "financial_analyst": "Analista finanziario",
            "chief_financial_officer__cfo_": "Direttore finanziario (CFO)",
            "forensic_accountant": "Contabile forense",
            "accounts_payable_specialist": "Specialista della contabilità fornitori",
            "accounts_receivable_specialist": "Specialista della contabilità clienti",
            "internal_auditor": "Revisore interno",
            "controller": "Controllore",
            "budget_analyst": "Analista di bilancio"
          }
        },
        "agriculture": {
          "label": "Agricoltura",
          "roles": {
            "farm_manager": "Responsabile dell'azienda agricola",
            "agronomist": "Agronomo",
            "agricultural_engineer": "Ingegnere agrario",
            "crop_scientist": "Scienziato delle colture",
            "livestock_manager": "Responsabile del bestiame",
            "soil_scientist": "Scienziato del suolo",
            "agricultural_consultant": "Consulente agricolo",
            "irrigation_specialist": "Specialista dell'irrigazione",
            "agribusiness_manager": "Responsabile agroalimentare",
            "agricultural_technician": "Tecnico agrario"
          }
        },
        "apparel": {
          "label": "Abbigliamento",
          "roles": {
            "fashion_designer": "Stilista di moda",
            "merchandiser": "Merchandiser",
            "production_manager": "Responsabile di produzione",
            "textile_technologist": "Tecnologo tessile",
            "fashion_buyer": "Acquirente di moda",
            "pattern_maker": "Modellista",
            "apparel_product_developer": "Sviluppatore di prodotti di abbigliamento",
            "fashion_illustrator": "Illustratore di moda",
            "quality_control_inspector": "Ispettore del controllo qualità",
            "fashion_marketing_specialist": "Specialista in marketing della moda"
          }
        },
        "banking": {
          "label": "Bancario",
          "roles": {
            "investment_banker": "Investitore finanziario",
            "loan_officer": "Addetto ai prestiti",
            "financial_advisor": "Consulente finanziario",
            "branch_manager": "Direttore di filiale",
            "risk_manager": "Responsabile del rischio",
            "credit_analyst": "Analista del credito",
            "relationship_manager": "Responsabile delle relazioni",
            "compliance_officer": "Responsabile della conformità",
            "wealth_manager": "Gestore patrimoniale",
            "treasury_analyst": "Analista di tesoreria"
          }
        },
        "biotechnology": {
          "label": "Biotecnologia",
          "roles": {
            "research_scientist": "Ricercatore",
            "bioprocess_engineer": "Ingegnere dei bioprocessi",
            "regulatory_affairs_specialist": "Specialista in affari normativi",
            "bioinformatics_specialist": "Specialista in bioinformatica",
            "clinical_research_associate": "Ricercatore clinico associato",
            "biotechnology_technician": "Tecnico delle biotecnologie",
            "quality_assurance_specialist": "Specialista in garanzia della qualità",
            "biomedical_engineer": "Ingegnere biomedico",
            "genetic_counselor": "Consulente genetico",
            "biostatistician": "Biostatistico"
          }
        },
        "chemicals": {
          "label": "Prodotti chimici",
          "roles": {
            "chemical_engineer": "Ingegnere chimico",
            "process_chemist": "Chimico di processo",
            "environmental_health_and_safety_specialist": "Specialista in salute e sicurezza ambientale",
            "quality_control_manager": "Responsabile del controllo qualità",
            "production_supervisor": "Supervisore della produzione",
            "r_d_scientist": "Scienziato di ricerca e sviluppo",
            "regulatory_compliance_specialist": "Specialista della conformità normativa",
            "analytical_chemist": "Chimico analitico",
            "chemical_plant_operator": "Operatore di impianto chimico",
            "formulation_chemist": "Chimico della formulazione"
          }
        },
        "communications": {
          "label": "Comunicazioni",
          "roles": {
            "public_relations_specialist": "Specialista in pubbliche relazioni",
            "marketing_manager": "Responsabile marketing",
            "social_media_manager": "Responsabile dei social media",
            "corporate_communications_manager": "Responsabile delle comunicazioni aziendali",
            "content_strategist": "Stratega dei contenuti",
            "communications_coordinator": "Coordinatore delle comunicazioni",
            "media_relations_specialist": "Specialista in relazioni con i media",
            "internal_communications_specialist": "Specialista della comunicazione interna",
            "speechwriter": "Scrittore di discorsi",
            "copywriter": "Copywriter"
          }
        },
        "construction": {
          "label": "Costruzioni",
          "roles": {
            "project_manager": "Responsabile di progetto",
            "civil_engineer": "Ingegnere civile",
            "architect": "Architetto",
            "construction_manager": "Capocantiere",
            "quantity_surveyor": "Geometra quantitativo",
            "site_supervisor": "Supervisore del sito",
            "structural_engineer": "Ingegnere strutturale",
            "building_inspector": "Ispettore edile",
            "safety_manager": "Responsabile della sicurezza",
            "estimator": "Estimatore"
          }
        },
        "consulting": {
          "label": "Consulenza",
          "roles": {
            "management_consultant": "Consulente gestionale",
            "strategy_analyst": "Analista di strategia",
            "it_consultant": "Consulente IT",
            "human_resources_consultant": "Consulente per le risorse umane",
            "financial_consultant": "Consulente finanziario",
            "business_analyst": "Analista aziendale",
            "operations_consultant": "Consulente operativo",
            "marketing_consultant": "Consulente di marketing",
            "environmental_consultant": "Consulente ambientale",
            "legal_consultant": "Consulente legale"
          }
        },
        "e_commerce": {
          "label": "E-Commerce",
          "roles": {
            "e_commerce_manager": "Responsabile e-commerce",
            "digital_marketing_specialist": "Specialista di marketing digitale",
            "customer_experience_manager": "Responsabile dell'esperienza del cliente",
            "marketplace_analyst": "Analista di mercato",
            "supply_chain_manager": "Responsabile della catena di fornitura",
            "product_manager": "Responsabile di prodotto",
            "seo_specialist": "Specialista SEO",
            "web_developer": "Sviluppatore Web",
            "data_analyst": "Analista di dati",
            "content_manager": "Gestore di contenuti"
          }
        },
        "education": {
          "label": "Formazione scolastica",
          "roles": {
            "teacher___educator": "Insegnante/Educatore",
            "school_administrator": "Amministratore scolastico",
            "curriculum_developer": "Sviluppatore di programmi di studio",
            "education_consultant": "Consulente per l'istruzione",
            "instructional_designer": "Progettista didattico",
            "academic_advisor": "Consulente accademico",
            "educational_psychologist": "Psicologo dell'educazione",
            "special_education_teacher": "Insegnante di educazione speciale",
            "school_counselor": "Consulente scolastico",
            "librarian": "Bibliotecario"
          }
        },
        "electronics": {
          "label": "Elettronica",
          "roles": {
            "electrical_engineer": "Ingegnere elettrico",
            "hardware_designer": "Progettista hardware",
            "semiconductor_technician": "Tecnico dei semiconduttori",
            "embedded_systems_engineer": "Ingegnere di sistemi integrati",
            "robotics_engineer": "Ingegnere robotico",
            "electronics_technician": "Tecnico elettronico",
            "pcb_designer": "Progettista PCB",
            "systems_engineer": "Ingegnere di sistema",
            "quality_assurance_engineer": "Ingegnere addetto al controllo qualità",
            "product_development_engineer": "Ingegnere di sviluppo del prodotto"
          }
        },
        "energy": {
          "label": "Energia",
          "roles": {
            "energy_analyst": "Analista energetico",
            "power_plant_engineer": "Ingegnere di centrale elettrica",
            "renewable_energy_specialist": "Specialista delle energie rinnovabili",
            "energy_trader": "Commerciante di energia",
            "environmental_specialist": "Specialista ambientale",
            "energy_consultant": "Consulente energetico",
            "energy_manager": "Responsabile energetico",
            "petroleum_engineer": "Ingegnere petrolifero",
            "solar_energy_technician": "Tecnico dell'energia solare",
            "wind_turbine_technician": "Tecnico di turbine eoliche"
          }
        },
        "engineering": {
          "label": "Ingegneria",
          "roles": {
            "mechanical_engineer": "Ingegnere meccanico",
            "civil_engineer": "Ingegnere civile",
            "aerospace_engineer": "Ingegnere aerospaziale",
            "chemical_engineer": "Ingegnere chimico",
            "electrical_engineer": "Ingegnere elettrico",
            "industrial_engineer": "Ingegnere industriale",
            "software_engineer": "Ingegnere del software",
            "structural_engineer": "Ingegnere strutturale",
            "environmental_engineer": "Ingegnere ambientale",
            "biomedical_engineer": "Ingegnere biomedico"
          }
        },
        "entertainment": {
          "label": "Divertimento",
          "roles": {
            "actor___actress": "Attore/attrice",
            "film_director": "Regista cinematografico",
            "producer": "Produttore",
            "screenwriter": "Sceneggiatore",
            "entertainment_lawyer": "Avvocato dello spettacolo",
            "casting_director": "Direttore del casting",
            "cinematographer": "Direttore della fotografia",
            "sound_engineer": "Tecnico del suono",
            "production_designer": "Progettista di produzione",
            "talent_agent": "Agente di talenti"
          }
        },
        "environmental": {
          "label": "Ambientale",
          "roles": {
            "environmental_scientist": "Scienziato Ambientale",
            "environmental_engineer": "Ingegnere ambientale",
            "sustainability_consultant": "Consulente per la sostenibilità",
            "ecologist": "Ecologo",
            "environmental_compliance_manager": "Responsabile della conformità ambientale",
            "conservation_scientist": "Scienziato della tutela dell'ambiente",
            "environmental_health_and_safety_specialist": "Specialista in salute e sicurezza ambientale",
            "environmental_planner": "Pianificatore ambientale",
            "environmental_educator": "Educatore ambientale",
            "wildlife_biologist": "Biologo della fauna selvatica"
          }
        },
        "finance": {
          "label": "Finanza",
          "roles": {
            "financial_analyst": "Analista finanziario",
            "investment_banker": "Investitore finanziario",
            "portfolio_manager": "Responsabile del portafoglio",
            "financial_planner": "Pianificatore finanziario",
            "risk_analyst": "Analista del rischio",
            "credit_analyst": "Analista del credito",
            "compliance_officer": "Responsabile della conformità",
            "treasury_analyst": "Analista di tesoreria",
            "financial_controller": "Controllore finanziario",
            "budget_analyst": "Analista di bilancio"
          }
        },
        "food___beverage": {
          "label": "Alimenti e bevande",
          "roles": {
            "chef": "Capocuoco",
            "food_scientist": "Scienziato dell'alimentazione",
            "nutritionist": "Nutrizionista",
            "food_safety_manager": "Responsabile della sicurezza alimentare",
            "restaurant_manager": "Direttore del ristorante",
            "beverage_manager": "Responsabile delle bevande",
            "pastry_chef": "Pasticcere",
            "sommelier": "Sommelier",
            "food_technologist": "Tecnologo alimentare",
            "quality_assurance_manager": "Responsabile del controllo qualità"
          }
        },
        "government": {
          "label": "Governo",
          "roles": {
            "policy_analyst": "Analista delle politiche",
            "civil_servant": "Funzionario",
            "diplomat": "Diplomatico",
            "government_relations_specialist": "Specialista di relazioni governative",
            "legislative_assistant": "Assistente legislativo",
            "public_administrator": "Amministratore pubblico",
            "city_planner": "Urbanista",
            "budget_analyst": "Analista di bilancio",
            "intelligence_analyst": "Analista dell'intelligence",
            "public_affairs_specialist": "Specialista in affari pubblici"
          }
        },
        "graphic_design": {
          "label": "Progettazione grafica",
          "roles": {
            "graphic_designer": "Grafico",
            "art_director": "Direttore artistico",
            "illustrator": "Illustratore",
            "ux_ui_designer": "Progettista UX/UI",
            "branding_specialist": "Specialista del branding",
            "creative_director": "Direttore creativo",
            "web_designer": "Progettista Web",
            "motion_graphics_designer": "Progettista di grafica animata",
            "package_designer": "Progettista di pacchetti",
            "visual_designer": "Progettista visivo"
          }
        },
        "healthcare": {
          "label": "Sanità",
          "roles": {
            "physician": "Medico",
            "nurse_practitioner": "Infermiere specializzato",
            "healthcare_administrator": "Amministratore sanitario",
            "medical_researcher": "Ricercatore medico",
            "pharmacist": "Farmacista",
            "medical_technologist": "Tecnologo medico",
            "physical_therapist": "Fisioterapista",
            "occupational_therapist": "Terapista occupazionale",
            "registered_nurse": "Infermiere diplomato",
            "radiologic_technologist": "Tecnologo radiologico"
          }
        },
        "hospitality": {
          "label": "Ospitalità",
          "roles": {
            "hotel_manager": "Direttore d'albergo",
            "event_planner": "Organizzatore di eventi",
            "restaurant_manager": "Direttore del ristorante",
            "hospitality_consultant": "Consulente per l'ospitalità",
            "concierge": "Concierge",
            "front_desk_manager": "Responsabile della reception",
            "housekeeping_manager": "Responsabile delle pulizie",
            "food_and_beverage_manager": "Responsabile di alimenti e bevande",
            "banquet_manager": "Responsabile banchetti",
            "guest_services_manager": "Responsabile dei servizi per gli ospiti"
          }
        },
        "insurance": {
          "label": "Assicurazione",
          "roles": {
            "actuary": "Attuario",
            "insurance_underwriter": "Sottoscrittore assicurativo",
            "claims_adjuster": "Perito di sinistri",
            "risk_manager": "Responsabile del rischio",
            "insurance_broker": "Broker assicurativo",
            "insurance_agent": "Agente assicurativo",
            "loss_control_specialist": "Specialista del controllo delle perdite",
            "claims_examiner": "Esaminatore di sinistri",
            "compliance_officer": "Responsabile della conformità",
            "customer_service_representative": "Rappresentante del servizio clienti"
          }
        },
        "legal": {
          "label": "Legale",
          "roles": {
            "lawyer": "Avvocato",
            "legal_secretary": "Segretario legale",
            "paralegal": "Praticante",
            "judge": "Giudice",
            "legal_counsel": "Consulente legale",
            "corporate_attorney": "Avvocato aziendale",
            "litigation_attorney": "Avvocato specializzato in contenziosi",
            "legal_assistant": "Assistente legale",
            "compliance_officer": "Responsabile della conformità",
            "court_clerk": "Cancelliere del tribunale"
          }
        },
        "machinery": {
          "label": "Macchinari",
          "roles": {
            "mechanical_engineer": "Ingegnere meccanico",
            "maintenance_technician": "Tecnico di manutenzione",
            "production_manager": "Responsabile di produzione",
            "quality_control_engineer": "Ingegnere del controllo qualità",
            "operations_manager": "Responsabile delle operazioni",
            "cnc_machinist": "Macchinista CNC",
            "manufacturing_engineer": "Ingegnere di produzione",
            "field_service_technician": "Tecnico dell'assistenza sul campo",
            "tool_and_die_maker": "Costruttore di utensili e stampi",
            "equipment_operator": "Operatore dell'attrezzatura"
          }
        },
        "manufacturing": {
          "label": "Produzione",
          "roles": {
            "production_manager": "Responsabile di produzione",
            "quality_assurance_manager": "Responsabile del controllo qualità",
            "supply_chain_manager": "Responsabile della catena di fornitura",
            "industrial_engineer": "Ingegnere industriale",
            "manufacturing_engineer": "Ingegnere di produzione",
            "operations_manager": "Responsabile delle operazioni",
            "maintenance_manager": "Responsabile della manutenzione",
            "process_engineer": "Ingegnere di processo",
            "plant_manager": "Direttore di stabilimento",
            "production_planner": "Pianificatore della produzione"
          }
        },
        "marketing": {
          "label": "Marketing",
          "roles": {
            "marketing_manager": "Responsabile marketing",
            "digital_marketing_specialist": "Specialista di marketing digitale",
            "market_research_analyst": "Analista di ricerche di mercato",
            "brand_manager": "Responsabile del marchio",
            "public_relations_specialist": "Specialista in pubbliche relazioni",
            "social_media_manager": "Responsabile dei social media",
            "content_marketing_specialist": "Specialista di marketing dei contenuti",
            "marketing_coordinator": "Coordinatore del marketing",
            "seo_specialist": "Specialista SEO",
            "email_marketing_specialist": "Specialista in marketing via e-mail"
          }
        },
        "media": {
          "label": "Media",
          "roles": {
            "journalist": "Giornalista",
            "editor": "Redattore",
            "tv_producer": "Produttore televisivo",
            "media_buyer": "Acquirente multimediale",
            "multimedia_artist": "Artista multimediale",
            "broadcast_technician": "Tecnico di trasmissione",
            "news_anchor": "Conduttore di telegiornale",
            "copywriter": "Copywriter",
            "video_editor": "Editor video",
            "social_media_manager": "Responsabile dei social media"
          }
        },
        "mining": {
          "label": "Estrazione mineraria",
          "roles": {
            "geologist": "Geologo",
            "mining_engineer": "Ingegnere minerario",
            "environmental_specialist__mining_": "Specialista ambientale (minerario)",
            "safety_manager__mining_": "Responsabile della sicurezza (minerario)",
            "mine_operations_manager": "Responsabile delle operazioni minerarie",
            "drilling_engineer": "Ingegnere di perforazione",
            "metallurgist": "Metallurgista",
            "mine_surveyor": "Ispettore minerario",
            "mineral_processing_engineer": "Ingegnere della lavorazione dei minerali",
            "maintenance_planner": "Pianificatore della manutenzione"
          }
        },
        "not_for_profit": {
          "label": "Non a scopo di lucro",
          "roles": {
            "program_manager": "Amministratore del programma",
            "fundraiser": "Fundraiser",
            "advocacy_coordinator": "Coordinatore dell'advocacy",
            "grant_writer": "Redattore di proposte di sovvenzioni",
            "volunteer_coordinator": "Coordinatore dei volontari",
            "community_outreach_manager": "Responsabile della sensibilizzazione della comunità",
            "development_director": "Direttore dello sviluppo",
            "nonprofit_administrator": "Amministratore non profit",
            "social_worker": "Operatore sociale",
            "communications_director": "Direttore delle comunicazioni"
          }
        },
        "pharmaceuticals": {
          "label": "Prodotti farmaceutici",
          "roles": {
            "pharmaceutical_sales_representative": "Rappresentante di vendita farmaceutico",
            "research_scientist": "Ricercatore",
            "regulatory_affairs_specialist": "Specialista in affari normativi",
            "clinical_trial_manager": "Responsabile della sperimentazione clinica",
            "pharmacologist": "Farmacologo",
            "quality_control_analyst": "Analista del controllo qualità",
            "formulation_scientist": "Scienziato della formulazione",
            "medical_science_liaison": "Collegamento con la scienza medica",
            "drug_safety_specialist": "Specialista in sicurezza dei farmaci",
            "biostatistician": "Biostatistico"
          }
        },
        "real_estate": {
          "label": "Beni immobili",
          "roles": {
            "real_estate_agent": "Agente immobiliare",
            "property_manager": "Amministratore di immobili",
            "real_estate_developer": "Promotore immobiliare",
            "appraiser": "Valutatore",
            "leasing_consultant": "Consulente di leasing",
            "real_estate_broker": "Agente immobiliare",
            "commercial_real_estate_agent": "Agente immobiliare commerciale",
            "real_estate_analyst": "Analista immobiliare",
            "real_estate_marketing_specialist": "Specialista in marketing immobiliare",
            "title_examiner": "Esaminatore del titolo"
          }
        },
        "recreation": {
          "label": "Ricreazione",
          "roles": {
            "recreation_director": "Direttore ricreativo",
            "event_coordinator": "Coordinatore di eventi",
            "park_ranger": "Ranger del parco",
            "fitness_instructor": "Istruttore di fitness",
            "sports_coach": "Allenatore sportivo",
            "recreation_therapist": "Terapista della ricreazione",
            "camp_director": "Direttore del campo",
            "outdoor_education_instructor": "Istruttore di educazione all'aperto",
            "aquatics_director": "Direttore acquatico",
            "adventure_guide": "Guida all'avventura"
          }
        },
        "retail": {
          "label": "Commercio al dettaglio",
          "roles": {
            "store_manager": "Responsabile del negozio",
            "sales_associate": "Addetto alle vendite",
            "merchandiser": "Merchandiser",
            "retail_buyer": "Acquirente al dettaglio",
            "visual_merchandiser": "Merchandiser visivo",
            "inventory_manager": "Responsabile dell'inventario",
            "customer_service_representative": "Rappresentante del servizio clienti",
            "cashier": "Cassiere",
            "retail_manager": "Responsabile della vendita al dettaglio",
            "loss_prevention_specialist": "Specialista di prevenzione delle perdite"
          }
        },
        "shipping": {
          "label": "Spedizioni",
          "roles": {
            "logistics_manager": "Responsabile della logistica",
            "shipping_coordinator": "Coordinatore delle spedizioni",
            "port_operations_manager": "Responsabile delle operazioni portuali",
            "freight_forwarder": "Spedizioniere",
            "customs_broker": "Agente doganale",
            "shipping_clerk": "Addetto alle spedizioni",
            "marine_engineer": "Ingegnere navale",
            "cargo_inspector": "Ispettore del carico",
            "supply_chain_analyst": "Analista della catena di fornitura",
            "deck_officer": "Ufficiale di coperta"
          }
        },
        "technology": {
          "label": "Tecnologia",
          "roles": {
            "software_engineer": "Ingegnere del software",
            "data_scientist": "Scienziato dei dati",
            "systems_analyst": "Analista di sistemi",
            "it_project_manager": "Responsabile di progetto IT",
            "network_administrator": "Amministratore di rete",
            "cybersecurity_analyst": "Analista della sicurezza informatica",
            "devops_engineer": "Ingegnere DevOps",
            "product_manager": "Responsabile di prodotto",
            "technical_support_specialist": "Specialista del supporto tecnico",
            "cloud_solutions_architect": "Architetto di soluzioni cloud"
          }
        },
        "telecommunications": {
          "label": "Telecomunicazioni",
          "roles": {
            "network_engineer": "Ingegnere di rete",
            "telecommunications_technician": "Tecnico delle telecomunicazioni",
            "systems_administrator": "Amministratore di sistema",
            "rf_engineer": "Ingegnere RF",
            "telecom_analyst": "Analista delle telecomunicazioni",
            "customer_support_specialist": "Specialista dell'assistenza clienti",
            "sales_engineer": "Ingegnere di vendita",
            "telecommunications_manager": "Responsabile delle telecomunicazioni",
            "voip_engineer": "Ingegnere VoIP",
            "field_service_technician": "Tecnico di assistenza sul campo"
          }
        },
        "transportation": {
          "label": "Trasporto",
          "roles": {
            "transportation_manager": "Responsabile dei trasporti",
            "fleet_manager": "Responsabile della flotta",
            "logistics_coordinator": "Coordinatore della logistica",
            "traffic_analyst": "Analista del traffico",
            "transportation_planner": "Pianificatore dei trasporti",
            "dispatch_supervisor": "Supervisore delle spedizioni",
            "supply_chain_manager": "Responsabile della catena di fornitura",
            "safety_coordinator": "Coordinatore della sicurezza",
            "public_transit_manager": "Responsabile del trasporto pubblico",
            "vehicle_maintenance_manager": "Responsabile della manutenzione dei veicoli"
          }
        },
        "utilities": {
          "label": "Utenze",
          "roles": {
            "utility_manager": "Responsabile delle utenze",
            "electrical_engineer": "Ingegnere elettrico",
            "water_treatment_specialist": "Specialista di trattamento delle acque",
            "utility_analyst": "Analista delle utenze",
            "energy_efficiency_consultant": "Consulente per l'efficienza energetica",
            "environmental_compliance_specialist": "Specialista della conformità ambientale",
            "utility_field_technician": "Tecnico sul campo delle utenze",
            "gas_controller": "Controllore del gas",
            "operations_manager": "Responsabile delle operazioni",
            "utility_planner": "Pianificatore di utenze"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Crea richiesta di documenti con l'intelligenza artificiale",
          "prompt_banner": "Inserire i dettagli della richiesta in modo che possiamo generare un elenco di articoli consigliati personalizzati.",
          "generate_btn": "Genera elementi",
          "review_banner": "In base ai dettagli forniti, ecco gli elementi consigliati per la richiesta. Selezionare quelli che si desidera aggiungere.",
          "creation_progress": "Creazione di una richiesta di documento...",
          "generation_progress": "Generazione di elementi per la richiesta...",
          "creation_error": {
            "message": "Si è verificato un errore durante la creazione della richiesta di documenti.",
            "description": "Riprovare."
          },
          "generation_error": {
            "message": "Si è verificato un errore durante la generazione degli elementi per la richiesta.",
            "description": "Riprovare."
          },
          "generation_form": {
            "request_name": "Nome della richiesta",
            "due_date": "Data di scadenza",
            "request_purpose": "Qual è lo scopo di questa richiesta?",
            "request_purpose_placeholder": "Cose che si possono dire:\n- Raccogli i documenti per l'inserimento di un nuovo assunto\n- Richiedi documenti personali come carta d'identità, previdenza sociale e altri documenti pertinenti.\n Evitare di includere dati personali (ad es. nome, indirizzo e-mail).",
            "employment_industry": "In quale settore lavori?",
            "employment_role": "Qual è il tuo ruolo nel settore?",
            "remember_industry_role": "Ricorda il settore e il ruolo"
          },
          "create_btn": "Crea richiesta"
        }
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "common": {
        "actions": "操作",
        "ai_caution": "AI による推奨は正確ではない可能性があります。生成されたアイテムを確認してください。",
        "all_categories": "すべてのカテゴリ",
        "back": "戻る",
        "beta": "ベータ版",
        "type_or_select_one": "入力または 1 つ選択してください",
        "cancel": "キャンセル",
        "close": "閉じる",
        "create": "作成",
        "created_by": "作成者",
        "created_on": "作成日:",
        "delete": "削除",
        "description": "説明",
        "details": "詳細",
        "edit_details": "詳細を編集する",
        "more": "詳細",
        "my_templates": "自分のテンプレート",
        "name": "名前",
        "oops": "エラー。",
        "optional": "オプション",
        "preview": "プレビュー",
        "retry": "再試行",
        "search": "検索",
        "select": "選択",
        "shared_with_me": "ユーザーと共有",
        "start_over": "最初から",
        "stop": "停止",
        "template": "テンプレート",
        "templates": "テンプレート",
        "unknown": "不明",
        "was_this_helpful": "これは役に立ちましたか?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "コンテンツの読み込み中にエラーが発生しました。再試行してください。",
          "no_resources": "表示するコンテンツがありません。"
        }
      },
      "industries": {
        "accounting": {
          "label": "会計",
          "roles": {
            "auditor": "監査人",
            "tax_accountant": "税理士",
            "financial_analyst": "金融アナリスト",
            "chief_financial_officer__cfo_": "最高財務責任者 (CFO)",
            "forensic_accountant": "法廷会計士",
            "accounts_payable_specialist": "買掛金スペシャリスト",
            "accounts_receivable_specialist": "売掛金スペシャリスト",
            "internal_auditor": "内部監査人",
            "controller": "Controller",
            "budget_analyst": "予算アナリスト"
          }
        },
        "agriculture": {
          "label": "農業",
          "roles": {
            "farm_manager": "農場管理者",
            "agronomist": "農学者",
            "agricultural_engineer": "農業エンジニア",
            "crop_scientist": "作物サイエンティスト",
            "livestock_manager": "家畜管理者",
            "soil_scientist": "土壌サイエンティスト",
            "agricultural_consultant": "農業コンサルタント",
            "irrigation_specialist": "灌漑スペシャリスト",
            "agribusiness_manager": "アグリビジネス マネージャー",
            "agricultural_technician": "農業技術者"
          }
        },
        "apparel": {
          "label": "アパレル",
          "roles": {
            "fashion_designer": "ファッション デザイナー",
            "merchandiser": "小売業者",
            "production_manager": "製造マネージャー",
            "textile_technologist": "テキスタイル テクノロジスト",
            "fashion_buyer": "ファッション バイヤー",
            "pattern_maker": "パタンナー",
            "apparel_product_developer": "アパレル製品開発者",
            "fashion_illustrator": "ファッション イラストレーター",
            "quality_control_inspector": "品質管理検査担当者",
            "fashion_marketing_specialist": "ファッション マーケティング スペシャリスト"
          }
        },
        "banking": {
          "label": "銀行",
          "roles": {
            "investment_banker": "投資銀行家",
            "loan_officer": "融資担当者",
            "financial_advisor": "財務顧問",
            "branch_manager": "支店長",
            "risk_manager": "リスク マネージャー",
            "credit_analyst": "クレジット アナリスト",
            "relationship_manager": "リレーションシップ マネージャー",
            "compliance_officer": "コンプライアンス責任者",
            "wealth_manager": "資産管理マネージャー",
            "treasury_analyst": "財務アナリスト"
          }
        },
        "biotechnology": {
          "label": "バイオテクノロジー",
          "roles": {
            "research_scientist": "リサーチ サイエンティスト",
            "bioprocess_engineer": "バイオプロセス エンジニア",
            "regulatory_affairs_specialist": "規制業務スペシャリスト",
            "bioinformatics_specialist": "生物情報学スペシャリスト",
            "clinical_research_associate": "臨床研究アソシエイト",
            "biotechnology_technician": "バイオテクノロジー技術者",
            "quality_assurance_specialist": "品質保証スペシャリスト",
            "biomedical_engineer": "生物医学エンジニア",
            "genetic_counselor": "遺伝カウンセラー",
            "biostatistician": "生物統計学者"
          }
        },
        "chemicals": {
          "label": "化学薬品",
          "roles": {
            "chemical_engineer": "化学エンジニア",
            "process_chemist": "プロセス化学者",
            "environmental_health_and_safety_specialist": "環境衛生安全スペシャリスト",
            "quality_control_manager": "品質管理マネージャー",
            "production_supervisor": "製造スーパーバイザー",
            "r_d_scientist": "R&D サイエンティスト",
            "regulatory_compliance_specialist": "規制遵守スペシャリスト",
            "analytical_chemist": "分析化学者",
            "chemical_plant_operator": "化学プラント オペレーター",
            "formulation_chemist": "製剤化学者"
          }
        },
        "communications": {
          "label": "通信",
          "roles": {
            "public_relations_specialist": "広報スペシャリスト",
            "marketing_manager": "マーケティング マネージャー",
            "social_media_manager": "ソーシャル メディア マネージャー",
            "corporate_communications_manager": "コーポレート コミュニケーション マネージャー",
            "content_strategist": "コンテンツ ストラテジスト",
            "communications_coordinator": "コミュニケーション コーディネーター",
            "media_relations_specialist": "メディア対応スペシャリスト",
            "internal_communications_specialist": "社内コミュニケーション スペシャリスト",
            "speechwriter": "スピーチライター",
            "copywriter": "コピーライター"
          }
        },
        "construction": {
          "label": "建築",
          "roles": {
            "project_manager": "プロジェクト マネージャー",
            "civil_engineer": "土木技師",
            "architect": "建築家",
            "construction_manager": "建設マネージャー",
            "quantity_surveyor": "積算士",
            "site_supervisor": "現場監督",
            "structural_engineer": "構造エンジニア",
            "building_inspector": "建物検査担当者",
            "safety_manager": "安全管理者",
            "estimator": "見積もり担当者"
          }
        },
        "consulting": {
          "label": "コンサルティング",
          "roles": {
            "management_consultant": "経営コンサルタント",
            "strategy_analyst": "戦略アナリスト",
            "it_consultant": "IT コンサルタント",
            "human_resources_consultant": "人事コンサルタント",
            "financial_consultant": "金融コンサルタント",
            "business_analyst": "ビジネス アナリスト",
            "operations_consultant": "オペレーション コンサルタント",
            "marketing_consultant": "マーケティング コンサルタント",
            "environmental_consultant": "環境コンサルタント",
            "legal_consultant": "法律顧問"
          }
        },
        "e_commerce": {
          "label": "E コマース",
          "roles": {
            "e_commerce_manager": "E コマース マネージャー",
            "digital_marketing_specialist": "デジタル マーケティング スペシャリスト",
            "customer_experience_manager": "カスタマー エクスペリエンス マネージャー",
            "marketplace_analyst": "市場アナリスト",
            "supply_chain_manager": "サプライチェーン マネージャー",
            "product_manager": "製品マネージャー",
            "seo_specialist": "SEO スペシャリスト",
            "web_developer": "Web 開発者",
            "data_analyst": "データ アナリスト",
            "content_manager": "コンテンツ マネージャー"
          }
        },
        "education": {
          "label": "教育",
          "roles": {
            "teacher___educator": "教師/教育者",
            "school_administrator": "学校管理者",
            "curriculum_developer": "カリキュラム開発者",
            "education_consultant": "教育コンサルタント",
            "instructional_designer": "指導内容デザイナー",
            "academic_advisor": "指導教員",
            "educational_psychologist": "教育心理学者",
            "special_education_teacher": "特別支援教育教師",
            "school_counselor": "スクール カウンセラー",
            "librarian": "司書"
          }
        },
        "electronics": {
          "label": "電子機器",
          "roles": {
            "electrical_engineer": "電気エンジニア",
            "hardware_designer": "ハードウェア設計者",
            "semiconductor_technician": "半導体技術者",
            "embedded_systems_engineer": "組み込みシステム エンジニア",
            "robotics_engineer": "ロボット工学エンジニア",
            "electronics_technician": "エレクトロニクス技術者",
            "pcb_designer": "プリント基板設計者",
            "systems_engineer": "システム エンジニア",
            "quality_assurance_engineer": "品質保証エンジニア",
            "product_development_engineer": "製品開発エンジニア"
          }
        },
        "energy": {
          "label": "エネルギー",
          "roles": {
            "energy_analyst": "エネルギー アナリスト",
            "power_plant_engineer": "発電所エンジニア",
            "renewable_energy_specialist": "再生可能エネルギー スペシャリスト",
            "energy_trader": "エネルギー取引企業",
            "environmental_specialist": "環境スペシャリスト",
            "energy_consultant": "エネルギー コンサルタント",
            "energy_manager": "エネルギー マネージャー",
            "petroleum_engineer": "石油エンジニア",
            "solar_energy_technician": "太陽エネルギー技術者",
            "wind_turbine_technician": "風力タービン技術者"
          }
        },
        "engineering": {
          "label": "エンジニアリング",
          "roles": {
            "mechanical_engineer": "メカニカル エンジニア",
            "civil_engineer": "土木技師",
            "aerospace_engineer": "航空宇宙エンジニア",
            "chemical_engineer": "化学エンジニア",
            "electrical_engineer": "電気エンジニア",
            "industrial_engineer": "インダストリアル エンジニア",
            "software_engineer": "ソフトウェア エンジニア",
            "structural_engineer": "構造エンジニア",
            "environmental_engineer": "環境エンジニア",
            "biomedical_engineer": "生物医学エンジニア"
          }
        },
        "entertainment": {
          "label": "エンターテイメント",
          "roles": {
            "actor___actress": "俳優/女優",
            "film_director": "映画監督",
            "producer": "プロデューサー",
            "screenwriter": "脚本家",
            "entertainment_lawyer": "芸能関係弁護士",
            "casting_director": "キャスティング ディレクター",
            "cinematographer": "撮影監督",
            "sound_engineer": "音響技師",
            "production_designer": "プロダクション デザイナー",
            "talent_agent": "タレント エージェント"
          }
        },
        "environmental": {
          "label": "環境",
          "roles": {
            "environmental_scientist": "環境サイエンティスト",
            "environmental_engineer": "環境エンジニア",
            "sustainability_consultant": "サステナビリティ コンサルタント",
            "ecologist": "生態学者",
            "environmental_compliance_manager": "環境コンプライアンス マネージャー",
            "conservation_scientist": "環境保護サイエンティスト",
            "environmental_health_and_safety_specialist": "環境衛生安全スペシャリスト",
            "environmental_planner": "環境プランナー",
            "environmental_educator": "環境教育者",
            "wildlife_biologist": "野生生物学者"
          }
        },
        "finance": {
          "label": "財務",
          "roles": {
            "financial_analyst": "金融アナリスト",
            "investment_banker": "投資銀行家",
            "portfolio_manager": "ポートフォリオ マネージャー",
            "financial_planner": "ファイナンシャル プランナー",
            "risk_analyst": "リスク アナリスト",
            "credit_analyst": "クレジット アナリスト",
            "compliance_officer": "コンプライアンス責任者",
            "treasury_analyst": "財務アナリスト",
            "financial_controller": "会計監査役",
            "budget_analyst": "予算アナリスト"
          }
        },
        "food___beverage": {
          "label": "食品および飲料",
          "roles": {
            "chef": "シェフ",
            "food_scientist": "食物サイエンティスト",
            "nutritionist": "栄養士",
            "food_safety_manager": "食品安全管理者",
            "restaurant_manager": "レストラン マネージャー",
            "beverage_manager": "飲料マネージャー",
            "pastry_chef": "パテシエ",
            "sommelier": "ソムリエ",
            "food_technologist": "食品技術者",
            "quality_assurance_manager": "品質保証マネージャー"
          }
        },
        "government": {
          "label": "自治体",
          "roles": {
            "policy_analyst": "政策アナリスト",
            "civil_servant": "公務員",
            "diplomat": "外交官",
            "government_relations_specialist": "政府広報スペシャリスト",
            "legislative_assistant": "立法補佐官",
            "public_administrator": "パブリック アドミニストレーター",
            "city_planner": "都市計画担当",
            "budget_analyst": "予算アナリスト",
            "intelligence_analyst": "インテリジェンス アナリスト",
            "public_affairs_specialist": "広報スペシャリスト"
          }
        },
        "graphic_design": {
          "label": "グラフィック デザイン",
          "roles": {
            "graphic_designer": "グラフィック デザイナー",
            "art_director": "アート ディレクター",
            "illustrator": "イラストレーター",
            "ux_ui_designer": "UX/UI デザイナー",
            "branding_specialist": "ブランディング スペシャリスト",
            "creative_director": "クリエイティブ ディレクター",
            "web_designer": "Web デザイナー",
            "motion_graphics_designer": "モーション グラフィック デザイナー",
            "package_designer": "パッケージ デザイナー",
            "visual_designer": "ビジュアル デザイナー"
          }
        },
        "healthcare": {
          "label": "ヘルスケア",
          "roles": {
            "physician": "医師",
            "nurse_practitioner": "看護師",
            "healthcare_administrator": "ヘルスケア管理者",
            "medical_researcher": "医学研究者",
            "pharmacist": "薬剤師",
            "medical_technologist": "医療技術者",
            "physical_therapist": "理学療法士",
            "occupational_therapist": "作業療法士",
            "registered_nurse": "登録看護師",
            "radiologic_technologist": "放射線技師"
          }
        },
        "hospitality": {
          "label": "ホスピタリティ",
          "roles": {
            "hotel_manager": "ホテル マネージャー",
            "event_planner": "イベント プランナー",
            "restaurant_manager": "レストラン マネージャー",
            "hospitality_consultant": "ホスピタリティ コンサルタント",
            "concierge": "コンシェルジュ",
            "front_desk_manager": "フロント デスク マネージャー",
            "housekeeping_manager": "客室清掃マネージャー",
            "food_and_beverage_manager": "食品および飲料マネージャー",
            "banquet_manager": "宴会マネージャー",
            "guest_services_manager": "ゲスト サービス マネージャー"
          }
        },
        "insurance": {
          "label": "保険",
          "roles": {
            "actuary": "保険数理士",
            "insurance_underwriter": "保険引受人",
            "claims_adjuster": "補償査定人",
            "risk_manager": "リスク マネージャー",
            "insurance_broker": "保険ブローカー",
            "insurance_agent": "保険代理店",
            "loss_control_specialist": "損失管理スペシャリスト",
            "claims_examiner": "保険請求審査官",
            "compliance_officer": "コンプライアンス責任者",
            "customer_service_representative": "カスタマー サービス担当者"
          }
        },
        "legal": {
          "label": "Legal",
          "roles": {
            "lawyer": "弁護士",
            "legal_secretary": "弁護士秘書",
            "paralegal": "パラリーガル",
            "judge": "裁判官",
            "legal_counsel": "法律顧問",
            "corporate_attorney": "企業弁護士",
            "litigation_attorney": "訴訟弁護士",
            "legal_assistant": "弁護士助手",
            "compliance_officer": "コンプライアンス責任者",
            "court_clerk": "裁判所書記官"
          }
        },
        "machinery": {
          "label": "機械",
          "roles": {
            "mechanical_engineer": "メカニカル エンジニア",
            "maintenance_technician": "メンテナンス技術者",
            "production_manager": "製造マネージャー",
            "quality_control_engineer": "品質管理エンジニア",
            "operations_manager": "オペレーション マネージャー",
            "cnc_machinist": "CNC 機械オペレーター",
            "manufacturing_engineer": "製造エンジニア",
            "field_service_technician": "フィールド サービス技術者",
            "tool_and_die_maker": "工具、金型メーカー",
            "equipment_operator": "機器オペレーター"
          }
        },
        "manufacturing": {
          "label": "製造業",
          "roles": {
            "production_manager": "製造マネージャー",
            "quality_assurance_manager": "品質保証マネージャー",
            "supply_chain_manager": "サプライチェーン マネージャー",
            "industrial_engineer": "インダストリアル エンジニア",
            "manufacturing_engineer": "製造エンジニア",
            "operations_manager": "オペレーション マネージャー",
            "maintenance_manager": "メンテナンス マネージャー",
            "process_engineer": "プロセス エンジニア",
            "plant_manager": "工場長",
            "production_planner": "製造プランナー"
          }
        },
        "marketing": {
          "label": "マーケティング",
          "roles": {
            "marketing_manager": "マーケティング マネージャー",
            "digital_marketing_specialist": "デジタル マーケティング スペシャリスト",
            "market_research_analyst": "市場調査アナリスト",
            "brand_manager": "ブランド マネージャー",
            "public_relations_specialist": "広報スペシャリスト",
            "social_media_manager": "ソーシャル メディア マネージャー",
            "content_marketing_specialist": "コンテンツ マーケティング スペシャリスト",
            "marketing_coordinator": "マーケティング コーディネーター",
            "seo_specialist": "SEO スペシャリスト",
            "email_marketing_specialist": "メール マーケティング スペシャリスト"
          }
        },
        "media": {
          "label": "メディア",
          "roles": {
            "journalist": "ジャーナリスト",
            "editor": "編集者",
            "tv_producer": "TV プロデューサー",
            "media_buyer": "メディア バイヤー",
            "multimedia_artist": "マルチメディア アーティスト",
            "broadcast_technician": "放送技術者",
            "news_anchor": "ニュース アンカー",
            "copywriter": "コピーライター",
            "video_editor": "動画編集者",
            "social_media_manager": "ソーシャル メディア マネージャー"
          }
        },
        "mining": {
          "label": "鉱業",
          "roles": {
            "geologist": "地質学者",
            "mining_engineer": "鉱山技師",
            "environmental_specialist__mining_": "環境スペシャリスト (鉱業)",
            "safety_manager__mining_": "安全管理者 (鉱業)",
            "mine_operations_manager": "採鉱オペレーション マネージャー",
            "drilling_engineer": "掘削エンジニア",
            "metallurgist": "冶金学者",
            "mine_surveyor": "鉱山測量士",
            "mineral_processing_engineer": "鉱物処理エンジニア",
            "maintenance_planner": "メンテナンス プランナー"
          }
        },
        "not_for_profit": {
          "label": "非営利",
          "roles": {
            "program_manager": "プログラム マネージャー",
            "fundraiser": "募金活動",
            "advocacy_coordinator": "アドボカシー コーディネーター",
            "grant_writer": "助成金申請ライター",
            "volunteer_coordinator": "ボランティア コーディネーター",
            "community_outreach_manager": "地域社会への支援活動マネージャー",
            "development_director": "開発ディレクター",
            "nonprofit_administrator": "非営利団体管理者",
            "social_worker": "ソーシャル ワーカー",
            "communications_director": "コミュニケーション ディレクター"
          }
        },
        "pharmaceuticals": {
          "label": "医薬品",
          "roles": {
            "pharmaceutical_sales_representative": "医薬品営業担当者",
            "research_scientist": "リサーチ サイエンティスト",
            "regulatory_affairs_specialist": "規制業務スペシャリスト",
            "clinical_trial_manager": "臨床試験マネージャー",
            "pharmacologist": "薬理学者",
            "quality_control_analyst": "品質管理アナリスト",
            "formulation_scientist": "製剤サイエンティスト",
            "medical_science_liaison": "メディカル サイエンス リエゾン",
            "drug_safety_specialist": "医薬品安全スペシャリスト",
            "biostatistician": "生物統計学者"
          }
        },
        "real_estate": {
          "label": "不動産",
          "roles": {
            "real_estate_agent": "不動産業者",
            "property_manager": "物件マネージャー",
            "real_estate_developer": "不動産開発業者",
            "appraiser": "鑑定士",
            "leasing_consultant": "リース コンサルタント",
            "real_estate_broker": "不動産ブローカー",
            "commercial_real_estate_agent": "商業不動産エージェント",
            "real_estate_analyst": "不動産アナリスト",
            "real_estate_marketing_specialist": "不動産マーケティング スペシャリスト",
            "title_examiner": "不動産鑑定士"
          }
        },
        "recreation": {
          "label": "レクリエーション",
          "roles": {
            "recreation_director": "レクリエーション ディレクター",
            "event_coordinator": "イベント コーディネーター",
            "park_ranger": "公園管理者",
            "fitness_instructor": "フィットネス インストラクター",
            "sports_coach": "スポーツ コーチ",
            "recreation_therapist": "レクリエーション療法士",
            "camp_director": "キャンプ指導者",
            "outdoor_education_instructor": "野外教育インストラクター",
            "aquatics_director": "水中競技指導者",
            "adventure_guide": "アドベンチャー ガイド"
          }
        },
        "retail": {
          "label": "リテール",
          "roles": {
            "store_manager": "店長",
            "sales_associate": "店員",
            "merchandiser": "小売業者",
            "retail_buyer": "小売バイヤー",
            "visual_merchandiser": "ビジュアル マーチャンダイザー",
            "inventory_manager": "在庫管理者",
            "customer_service_representative": "カスタマー サービス担当者",
            "cashier": "レジ",
            "retail_manager": "小売マネージャー",
            "loss_prevention_specialist": "万引き G メン"
          }
        },
        "shipping": {
          "label": "配送",
          "roles": {
            "logistics_manager": "物流マネージャー",
            "shipping_coordinator": "配送コーディネーター",
            "port_operations_manager": "港湾運営マネージャー",
            "freight_forwarder": "貨物運送業者",
            "customs_broker": "通関業者",
            "shipping_clerk": "配送係",
            "marine_engineer": "海洋エンジニア",
            "cargo_inspector": "貨物検査官",
            "supply_chain_analyst": "サプライ チェーン アナリスト",
            "deck_officer": "デッキ責任者"
          }
        },
        "technology": {
          "label": "テクノロジー",
          "roles": {
            "software_engineer": "ソフトウェア エンジニア",
            "data_scientist": "データ サイエンティスト",
            "systems_analyst": "システム アナリスト",
            "it_project_manager": "IT プロジェクト マネージャー",
            "network_administrator": "ネットワーク管理者",
            "cybersecurity_analyst": "サイバーセキュリティ アナリスト",
            "devops_engineer": "DevOps エンジニア",
            "product_manager": "製品マネージャー",
            "technical_support_specialist": "テクニカル サポート スペシャリスト",
            "cloud_solutions_architect": "クラウド ソリューション アーキテクト"
          }
        },
        "telecommunications": {
          "label": "電気通信",
          "roles": {
            "network_engineer": "ネットワーク エンジニア",
            "telecommunications_technician": "電気通信技術者",
            "systems_administrator": "システム管理者",
            "rf_engineer": "RF エンジニア",
            "telecom_analyst": "電気通信アナリスト",
            "customer_support_specialist": "カスタマー サポート スペシャリスト",
            "sales_engineer": "セールス エンジニア",
            "telecommunications_manager": "電気通信マネージャー",
            "voip_engineer": "VoIP エンジニア",
            "field_service_technician": "フィールド サービス技術者"
          }
        },
        "transportation": {
          "label": "輸送",
          "roles": {
            "transportation_manager": "輸送マネージャー",
            "fleet_manager": "フリート マネージャー",
            "logistics_coordinator": "ロジスティクス コーディネーター",
            "traffic_analyst": "トラフィック アナリスト",
            "transportation_planner": "輸送プランナー",
            "dispatch_supervisor": "派遣スーパーバイザー",
            "supply_chain_manager": "サプライチェーン マネージャー",
            "safety_coordinator": "安全コーディネーター",
            "public_transit_manager": "公共交通マネージャー",
            "vehicle_maintenance_manager": "車両整備マネージャー"
          }
        },
        "utilities": {
          "label": "ユーティリティ",
          "roles": {
            "utility_manager": "公共事業マネージャー",
            "electrical_engineer": "電気エンジニア",
            "water_treatment_specialist": "水処理スペシャリスト",
            "utility_analyst": "公共事業アナリスト",
            "energy_efficiency_consultant": "エネルギー効率コンサルタント",
            "environmental_compliance_specialist": "環境コンプライアンス スペシャリスト",
            "utility_field_technician": "公共事業現場技術者",
            "gas_controller": "ガス コントローラー",
            "operations_manager": "オペレーション マネージャー",
            "utility_planner": "公共事業プランナー"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "AI でドキュメント リクエストを作成する",
          "prompt_banner": "リクエストの詳細を入力すると、推奨アイテムの一覧が生成されます。",
          "generate_btn": "アイテムを生成する",
          "review_banner": "提供された詳細に基づいて、リクエストに推奨されるアイテムが表示されます。追加したいものを選択してください。",
          "creation_progress": "ドキュメント リクエストの作成中...",
          "generation_progress": "リクエストのアイテムの生成中...",
          "creation_error": {
            "message": "ドキュメント リクエストの作成中にエラーが発生しました。",
            "description": "再試行してください。"
          },
          "generation_error": {
            "message": "リクエストのアイテムの作成中にエラーが発生しました。",
            "description": "再試行してください。"
          },
          "generation_form": {
            "request_name": "リクエスト名",
            "due_date": "締切日",
            "request_purpose": "このリクエストの目的は何ですか?",
            "request_purpose_placeholder": "次のようなことを伝えます:\n- 新入社員のオンボーディングに必要なドキュメントを収集します\n- 政府発行の身分証明書、社会保障番号、その他の関連書類などの個人ドキュメントを要求します。\n 個人データ (名前、メール アドレスなど) は含めないようにしてください。",
            "employment_industry": "どの業界で働いていますか？",
            "employment_role": "業界での役割は何ですか?",
            "remember_industry_role": "業界と役割を覚えておく"
          },
          "create_btn": "リクエストを作成する"
        }
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "common": {
        "actions": "Acties",
        "ai_caution": "AI-aanbevelingen zijn mogelijk niet nauwkeurig. Controleer de gegenereerde items.",
        "all_categories": "Alle categorieën",
        "back": "Terug",
        "beta": "Bèta",
        "type_or_select_one": "Typ of selecteer er een",
        "cancel": "Annuleren",
        "close": "Sluiten",
        "create": "Maken",
        "created_by": "Gemaakt door",
        "created_on": "Gemaakt op",
        "delete": "Verwijderen",
        "description": "Beschrijving",
        "details": "Details",
        "edit_details": "Gegevens bewerken",
        "more": "Meer",
        "my_templates": "Mijn sjablonen",
        "name": "Naam",
        "oops": "Oeps!",
        "optional": "optioneel",
        "preview": "Voorbeeldweergave",
        "retry": "Opnieuw proberen",
        "search": "Zoeken",
        "select": "Selecteren",
        "shared_with_me": "Gedeeld met mij",
        "start_over": "Opnieuw starten",
        "stop": "Stoppen",
        "template": "Sjabloon",
        "templates": "Sjablonen",
        "unknown": "onbekend",
        "was_this_helpful": "Was dit nuttig?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "Er is een fout opgetreden tijdens het laden van de inhoud. Probeer het opnieuw.",
          "no_resources": "Geen inhoud om weer te geven."
        }
      },
      "industries": {
        "accounting": {
          "label": "Boekhouding",
          "roles": {
            "auditor": "Accountant",
            "tax_accountant": "Belastingaccountant",
            "financial_analyst": "Financieel analist",
            "chief_financial_officer__cfo_": "Chief Financial Officer (CFO)",
            "forensic_accountant": "Forensisch accountant",
            "accounts_payable_specialist": "Crediteurenspecialist",
            "accounts_receivable_specialist": "Debiteurenspecialist",
            "internal_auditor": "Interne auditor",
            "controller": "Controleur",
            "budget_analyst": "Budgetanalist"
          }
        },
        "agriculture": {
          "label": "Landbouw",
          "roles": {
            "farm_manager": "Boerderijbeheerder",
            "agronomist": "Agronoom",
            "agricultural_engineer": "Landbouwingenieur",
            "crop_scientist": "Gewaswetenschapper",
            "livestock_manager": "Veehouder",
            "soil_scientist": "Bodemwetenschapper",
            "agricultural_consultant": "Landbouwadviseur",
            "irrigation_specialist": "Irrigatiespecialist",
            "agribusiness_manager": "Manager landbouwbedrijf",
            "agricultural_technician": "Landbouwtechnicus"
          }
        },
        "apparel": {
          "label": "Kleding",
          "roles": {
            "fashion_designer": "Modeontwerper",
            "merchandiser": "Merchandiser",
            "production_manager": "Productiemanager",
            "textile_technologist": "Textieltechnoloog",
            "fashion_buyer": "Inkoper mode",
            "pattern_maker": "Patroonmaker",
            "apparel_product_developer": "Productontwikkelaar kleding",
            "fashion_illustrator": "Mode-illustrator",
            "quality_control_inspector": "Inspecteur kwaliteitscontrole",
            "fashion_marketing_specialist": "Specialist in modemarketing"
          }
        },
        "banking": {
          "label": "Bankieren",
          "roles": {
            "investment_banker": "Investeringsbankier",
            "loan_officer": "Kredietspecialist",
            "financial_advisor": "Financieel adviseur",
            "branch_manager": "Filiaalmanager",
            "risk_manager": "Risicobeheerder",
            "credit_analyst": "Kredietanalist",
            "relationship_manager": "Relatiebeheerder",
            "compliance_officer": "Compliance Officer",
            "wealth_manager": "Vermogensbeheerder",
            "treasury_analyst": "Treasury Analyst"
          }
        },
        "biotechnology": {
          "label": "Biotechnologie",
          "roles": {
            "research_scientist": "Onderzoekwetenschapper",
            "bioprocess_engineer": "Bioprocesingenieur",
            "regulatory_affairs_specialist": "Specialist op het gebied van regelgeving",
            "bioinformatics_specialist": "Bio-informaticaspecialist",
            "clinical_research_associate": "Medewerker klinisch onderzoek",
            "biotechnology_technician": "Biotechnologietechnicus",
            "quality_assurance_specialist": "Specialist in kwaliteitsborging",
            "biomedical_engineer": "Biomedisch ingenieur",
            "genetic_counselor": "Genetisch adviseur",
            "biostatistician": "Biostatisticus"
          }
        },
        "chemicals": {
          "label": "Chemicaliën",
          "roles": {
            "chemical_engineer": "Chemisch ingenieur",
            "process_chemist": "Proceschemicus",
            "environmental_health_and_safety_specialist": "Specialist op het gebied van milieu, gezondheid en veiligheid",
            "quality_control_manager": "Manager kwaliteitscontrole",
            "production_supervisor": "Productiesupervisor",
            "r_d_scientist": "R&D-wetenschapper",
            "regulatory_compliance_specialist": "Specialist op het gebied van naleving van regelgeving",
            "analytical_chemist": "Analytisch chemicus",
            "chemical_plant_operator": "Operator in een chemische fabriek",
            "formulation_chemist": "Formuleringschemicus"
          }
        },
        "communications": {
          "label": "Communicatie",
          "roles": {
            "public_relations_specialist": "Specialist in public relations",
            "marketing_manager": "Marketingmanager",
            "social_media_manager": "Socialmediamanager",
            "corporate_communications_manager": "Manager bedrijfscommunicatie",
            "content_strategist": "Contentstrateeg",
            "communications_coordinator": "Communicatiecoördinator",
            "media_relations_specialist": "Specialist mediarelaties",
            "internal_communications_specialist": "Specialist interne communicatie",
            "speechwriter": "Speechwriter",
            "copywriter": "Copywriter"
          }
        },
        "construction": {
          "label": "Bouw",
          "roles": {
            "project_manager": "Projectmanager",
            "civil_engineer": "Burgerlijk ingenieur",
            "architect": "Architect",
            "construction_manager": "Bouwmanager",
            "quantity_surveyor": "Quantity Surveyor",
            "site_supervisor": "Werfleider",
            "structural_engineer": "Bouwkundig ingenieur",
            "building_inspector": "Bouwinspecteur",
            "safety_manager": "Veiligheidsmanager",
            "estimator": "Schatter"
          }
        },
        "consulting": {
          "label": "Consulting",
          "roles": {
            "management_consultant": "Managementadviseur",
            "strategy_analyst": "Strategieanalist",
            "it_consultant": "IT-consultant",
            "human_resources_consultant": "Adviseur Personeelszaken",
            "financial_consultant": "Financieel adviseur",
            "business_analyst": "Bedrijfsanalist",
            "operations_consultant": "Operationeel adviseur",
            "marketing_consultant": "Marketingconsultant",
            "environmental_consultant": "Milieuadviseur",
            "legal_consultant": "Juridisch adviseur"
          }
        },
        "e_commerce": {
          "label": "E-commerce",
          "roles": {
            "e_commerce_manager": "E-commercemanager",
            "digital_marketing_specialist": "Specialist in digitale marketing",
            "customer_experience_manager": "Manager Klantbeleving",
            "marketplace_analyst": "Marktplaatsanalist",
            "supply_chain_manager": "Supply Chain Manager",
            "product_manager": "Productmanager",
            "seo_specialist": "SEO-specialist",
            "web_developer": "Webontwikkelaar",
            "data_analyst": "Data-analist",
            "content_manager": "Contentbeheerder"
          }
        },
        "education": {
          "label": "Onderwijs",
          "roles": {
            "teacher___educator": "Leraar/docent",
            "school_administrator": "Schoolbestuurder",
            "curriculum_developer": "Curriculumontwikkelaar",
            "education_consultant": "Onderwijsadviseur",
            "instructional_designer": "Ontwikkelaar van lesmateriaal",
            "academic_advisor": "Academisch adviseur",
            "educational_psychologist": "Onderwijspsycholoog",
            "special_education_teacher": "Leraar bijzonder onderwijs",
            "school_counselor": "Schoolbegeleider",
            "librarian": "Bibliothecaris"
          }
        },
        "electronics": {
          "label": "Elektronica",
          "roles": {
            "electrical_engineer": "Elektrotechnisch ingenieur",
            "hardware_designer": "Hardware-ontwerper",
            "semiconductor_technician": "Halfgeleidertechnicus",
            "embedded_systems_engineer": "Ingenieur geïntegreerde systemen",
            "robotics_engineer": "Robotica-ingenieur",
            "electronics_technician": "Elektronicatechnicus",
            "pcb_designer": "PCB-ontwerper",
            "systems_engineer": "Systeemengineer",
            "quality_assurance_engineer": "Kwaliteitsborgingsingenieur",
            "product_development_engineer": "Productontwikkelingsingenieur"
          }
        },
        "energy": {
          "label": "Energie",
          "roles": {
            "energy_analyst": "Energieanalist",
            "power_plant_engineer": "Ingenieur elektriciteitscentrale",
            "renewable_energy_specialist": "Specialist in hernieuwbare energie",
            "energy_trader": "Energiehandelaar",
            "environmental_specialist": "Milieuspecialist",
            "energy_consultant": "Energieadviseur",
            "energy_manager": "Energiemanager",
            "petroleum_engineer": "Petroleumingenieur",
            "solar_energy_technician": "Technicus voor zonne-energie",
            "wind_turbine_technician": "Technicus voor windturbines"
          }
        },
        "engineering": {
          "label": "Engineering",
          "roles": {
            "mechanical_engineer": "Werktuigbouwkundig ingenieur",
            "civil_engineer": "Burgerlijk ingenieur",
            "aerospace_engineer": "Ruimtevaartingenieur",
            "chemical_engineer": "Chemisch ingenieur",
            "electrical_engineer": "Elektrotechnisch ingenieur",
            "industrial_engineer": "Industrieel ingenieur",
            "software_engineer": "Software-ontwikkelaar",
            "structural_engineer": "Bouwkundig ingenieur",
            "environmental_engineer": "Milieukundig ingenieur",
            "biomedical_engineer": "Biomedisch ingenieur"
          }
        },
        "entertainment": {
          "label": "Entertainment",
          "roles": {
            "actor___actress": "Acteur/actrice",
            "film_director": "Filmregisseur",
            "producer": "Producent",
            "screenwriter": "Scenarioschrijver",
            "entertainment_lawyer": "Entertainmentadvocaat",
            "casting_director": "Castingverantwoordelijke",
            "cinematographer": "Cinematograaf",
            "sound_engineer": "Geluidstechnicus",
            "production_designer": "Production Designer",
            "talent_agent": "Talentenjager"
          }
        },
        "environmental": {
          "label": "Milieu",
          "roles": {
            "environmental_scientist": "Milieuwetenschapper",
            "environmental_engineer": "Milieukundig ingenieur",
            "sustainability_consultant": "Adviseur Duurzaamheid",
            "ecologist": "Ecoloog",
            "environmental_compliance_manager": "Manager voor naleving van milieuvoorschriften",
            "conservation_scientist": "Natuurbeschermingswetenschapper",
            "environmental_health_and_safety_specialist": "Specialist op het gebied van milieu, gezondheid en veiligheid",
            "environmental_planner": "Milieuplanner",
            "environmental_educator": "Opleider milieukennis",
            "wildlife_biologist": "Natuurbioloog"
          }
        },
        "finance": {
          "label": "Financiën",
          "roles": {
            "financial_analyst": "Financieel analist",
            "investment_banker": "Investeringsbankier",
            "portfolio_manager": "Portefeuillebeheerder",
            "financial_planner": "Financieel planner",
            "risk_analyst": "Risicoanalist",
            "credit_analyst": "Kredietanalist",
            "compliance_officer": "Compliance Officer",
            "treasury_analyst": "Treasury Analyst",
            "financial_controller": "Financieel controleur",
            "budget_analyst": "Budgetanalist"
          }
        },
        "food___beverage": {
          "label": "Eten en drinken",
          "roles": {
            "chef": "Chefkok",
            "food_scientist": "Voedingswetenschapper",
            "nutritionist": "Voedingsdeskundige",
            "food_safety_manager": "Manager voedselveiligheid",
            "restaurant_manager": "Restaurantmanager",
            "beverage_manager": "Drankmanager",
            "pastry_chef": "Banketbakker",
            "sommelier": "Sommelier",
            "food_technologist": "Voedseltechnoloog",
            "quality_assurance_manager": "Manager kwaliteitsborging"
          }
        },
        "government": {
          "label": "Overheid",
          "roles": {
            "policy_analyst": "Beleidsanalist",
            "civil_servant": "Ambtenaar",
            "diplomat": "Diplomaat",
            "government_relations_specialist": "Specialist overheidsrelaties",
            "legislative_assistant": "Medewerker wetgeving",
            "public_administrator": "Openbaar bestuurder",
            "city_planner": "Stadsplanner",
            "budget_analyst": "Budgetanalist",
            "intelligence_analyst": "Inlichtingenanalist",
            "public_affairs_specialist": "Specialist in openbare zaken"
          }
        },
        "graphic_design": {
          "label": "Grafisch ontwerp",
          "roles": {
            "graphic_designer": "Grafisch ontwerper",
            "art_director": "Artistiek directeur",
            "illustrator": "Illustrator",
            "ux_ui_designer": "UX/UI-ontwerper",
            "branding_specialist": "Brandingspecialist",
            "creative_director": "Creatief directeur",
            "web_designer": "Webdesigner",
            "motion_graphics_designer": "Motion Graphics-ontwerper",
            "package_designer": "Verpakkingsontwerper",
            "visual_designer": "Visueel ontwerper"
          }
        },
        "healthcare": {
          "label": "Gezondheidszorg",
          "roles": {
            "physician": "Arts",
            "nurse_practitioner": "Verpleegkundige",
            "healthcare_administrator": "Administrateur gezondheidszorg",
            "medical_researcher": "Medisch onderzoeker",
            "pharmacist": "Apotheker",
            "medical_technologist": "Medisch technoloog",
            "physical_therapist": "Fysiotherapeut",
            "occupational_therapist": "Ergotherapeut",
            "registered_nurse": "Gediplomeerd verpleegkundige",
            "radiologic_technologist": "Radiologisch technoloog"
          }
        },
        "hospitality": {
          "label": "Horeca",
          "roles": {
            "hotel_manager": "Hotelmanager",
            "event_planner": "Evenementenplanner",
            "restaurant_manager": "Restaurantmanager",
            "hospitality_consultant": "Horeca-adviseur",
            "concierge": "Conciërge",
            "front_desk_manager": "Receptiemanager",
            "housekeeping_manager": "Manager Huishouding",
            "food_and_beverage_manager": "Voedsel- en drankmanager",
            "banquet_manager": "Banketmanager",
            "guest_services_manager": "Manager gastenservice"
          }
        },
        "insurance": {
          "label": "Verzekeringen",
          "roles": {
            "actuary": "Actuaris",
            "insurance_underwriter": "Verzekeraar",
            "claims_adjuster": "Schaderegelaar",
            "risk_manager": "Risicobeheerder",
            "insurance_broker": "Verzekeringsmakelaar",
            "insurance_agent": "Verzekeringsagent",
            "loss_control_specialist": "Specialist verliesbeheersing",
            "claims_examiner": "Claimbeoordelaar",
            "compliance_officer": "Compliance Officer",
            "customer_service_representative": "Klantenservicemedewerker"
          }
        },
        "legal": {
          "label": "Juridisch",
          "roles": {
            "lawyer": "Advocaat",
            "legal_secretary": "Juridisch secretaresse",
            "paralegal": "Juridisch medewerker",
            "judge": "Rechter",
            "legal_counsel": "Juridisch adviseur",
            "corporate_attorney": "Bedrijfsjurist",
            "litigation_attorney": "Procesadvocaat",
            "legal_assistant": "Juridisch assistent",
            "compliance_officer": "Compliance Officer",
            "court_clerk": "Griffier"
          }
        },
        "machinery": {
          "label": "Machines",
          "roles": {
            "mechanical_engineer": "Werktuigbouwkundig ingenieur",
            "maintenance_technician": "Onderhoudstechnieker",
            "production_manager": "Productiemanager",
            "quality_control_engineer": "Ingenieur kwaliteitscontrole",
            "operations_manager": "Operationeel manager",
            "cnc_machinist": "CNC-machinist",
            "manufacturing_engineer": "Productie-ingenieur",
            "field_service_technician": "Technicus voor buitendienst",
            "tool_and_die_maker": "Gereedschaps- en matrijzenmaker",
            "equipment_operator": "Apparatuuroperator"
          }
        },
        "manufacturing": {
          "label": "Productie",
          "roles": {
            "production_manager": "Productiemanager",
            "quality_assurance_manager": "Manager kwaliteitsborging",
            "supply_chain_manager": "Supply Chain Manager",
            "industrial_engineer": "Industrieel ingenieur",
            "manufacturing_engineer": "Productie-ingenieur",
            "operations_manager": "Operationeel manager",
            "maintenance_manager": "Onderhoudsmanager",
            "process_engineer": "Procesingenieur",
            "plant_manager": "Fabrieksmanager",
            "production_planner": "Productieplanner"
          }
        },
        "marketing": {
          "label": "Marketing",
          "roles": {
            "marketing_manager": "Marketingmanager",
            "digital_marketing_specialist": "Specialist in digitale marketing",
            "market_research_analyst": "Marktonderzoeksanalist",
            "brand_manager": "Merkmanager",
            "public_relations_specialist": "Specialist in public relations",
            "social_media_manager": "Socialmediamanager",
            "content_marketing_specialist": "Specialist in digitale marketing",
            "marketing_coordinator": "Marketingcoördinator",
            "seo_specialist": "SEO-specialist",
            "email_marketing_specialist": "Specialist in e-mailmarketing"
          }
        },
        "media": {
          "label": "Media",
          "roles": {
            "journalist": "Journalist",
            "editor": "Redacteur",
            "tv_producer": "Tv-producent",
            "media_buyer": "Media-inkoper",
            "multimedia_artist": "Multimediakunstenaar",
            "broadcast_technician": "Omroeptechnicus",
            "news_anchor": "Nieuwslezer",
            "copywriter": "Copywriter",
            "video_editor": "Videobewerker",
            "social_media_manager": "Socialmediamanager"
          }
        },
        "mining": {
          "label": "Mijnbouw",
          "roles": {
            "geologist": "Geoloog",
            "mining_engineer": "Mijnbouwingenieur",
            "environmental_specialist__mining_": "Milieuspecialist (mijnbouw)",
            "safety_manager__mining_": "Veiligheidsmanager (mijnbouw)",
            "mine_operations_manager": "Manager mijnexploitatie",
            "drilling_engineer": "Booringenieur",
            "metallurgist": "Metallurg",
            "mine_surveyor": "Mijnopzichter",
            "mineral_processing_engineer": "Mineraalverwerkingsingenieur",
            "maintenance_planner": "Onderhoudsplanner"
          }
        },
        "not_for_profit": {
          "label": "Zonder winstoogmerk",
          "roles": {
            "program_manager": "Programmabeheer",
            "fundraiser": "Inzamelingsactie",
            "advocacy_coordinator": "Coördinator belangenbehartiging",
            "grant_writer": "Subsidieschrijver",
            "volunteer_coordinator": "Coördinator vrijwilligers",
            "community_outreach_manager": "Community Outreach-manager",
            "development_director": "Ontwikkelingsdirecteur",
            "nonprofit_administrator": "Beheerder van non-profitorganisaties",
            "social_worker": "Maatschappelijk werker",
            "communications_director": "Directeur Communicatie"
          }
        },
        "pharmaceuticals": {
          "label": "Farmaceutische producten",
          "roles": {
            "pharmaceutical_sales_representative": "Verkoper van farmaceutische producten",
            "research_scientist": "Onderzoekwetenschapper",
            "regulatory_affairs_specialist": "Specialist op het gebied van regelgeving",
            "clinical_trial_manager": "Manager voor klinische onderzoeken",
            "pharmacologist": "Farmacoloog",
            "quality_control_analyst": "Analist kwaliteitscontrole",
            "formulation_scientist": "Formuleringswetenschapper",
            "medical_science_liaison": "Contactpersoon medische wetenschap",
            "drug_safety_specialist": "Specialist op het gebied van geneesmiddelenveiligheid",
            "biostatistician": "Biostatisticus"
          }
        },
        "real_estate": {
          "label": "Vastgoed",
          "roles": {
            "real_estate_agent": "Vastgoedmakelaar",
            "property_manager": "Vastgoedbeheerder",
            "real_estate_developer": "Vastgoedontwikkelaar",
            "appraiser": "Taxateur",
            "leasing_consultant": "Leasingadviseur",
            "real_estate_broker": "Vastgoedmakelaar",
            "commercial_real_estate_agent": "Makelaar voor commercieel onroerend goed",
            "real_estate_analyst": "Vastgoedanalist",
            "real_estate_marketing_specialist": "Specialist in vastgoedmarketing",
            "title_examiner": "Onderzoeker eigendomsaktes"
          }
        },
        "recreation": {
          "label": "Recreatie",
          "roles": {
            "recreation_director": "Directeur recreatie",
            "event_coordinator": "Evenementencoördinator",
            "park_ranger": "Parkwachter",
            "fitness_instructor": "Fitnessinstructeur",
            "sports_coach": "Sportcoach",
            "recreation_therapist": "Recreatietherapeut",
            "camp_director": "Kampdirecteur",
            "outdoor_education_instructor": "Instructeur buitenonderwijs",
            "aquatics_director": "Directeur watersport",
            "adventure_guide": "Avonturengids"
          }
        },
        "retail": {
          "label": "Detailhandel",
          "roles": {
            "store_manager": "Winkelmanager",
            "sales_associate": "Verkoopmedewerker",
            "merchandiser": "Merchandiser",
            "retail_buyer": "Inkoper detailhandel",
            "visual_merchandiser": "Visual Merchandiser",
            "inventory_manager": "Inventarisbeheerder",
            "customer_service_representative": "Klantenservicemedewerker",
            "cashier": "Kassamedewerker",
            "retail_manager": "Detailhandelmanager",
            "loss_prevention_specialist": "Specialist verliespreventie"
          }
        },
        "shipping": {
          "label": "Verzending",
          "roles": {
            "logistics_manager": "Manager logistiek",
            "shipping_coordinator": "Coördinator voor verzending",
            "port_operations_manager": "Manager Havenbeheer",
            "freight_forwarder": "Vrachtvervoerder",
            "customs_broker": "Douane-expediteur",
            "shipping_clerk": "Expeditiemedewerker",
            "marine_engineer": "Scheepsbouwkundig ingenieur",
            "cargo_inspector": "Ladinginspecteur",
            "supply_chain_analyst": "Analist voor de toeleveringsketen",
            "deck_officer": "Dekofficier"
          }
        },
        "technology": {
          "label": "Technologie",
          "roles": {
            "software_engineer": "Software-ontwikkelaar",
            "data_scientist": "Datawetenschapper",
            "systems_analyst": "Systeemanalist",
            "it_project_manager": "IT-projectmanager",
            "network_administrator": "Netwerkbeheerder",
            "cybersecurity_analyst": "Cybersecurity-analist",
            "devops_engineer": "DevOps-ingenieur",
            "product_manager": "Productmanager",
            "technical_support_specialist": "Specialist technische ondersteuning",
            "cloud_solutions_architect": "Architect voor cloudoplossingen"
          }
        },
        "telecommunications": {
          "label": "Telecommunicatie",
          "roles": {
            "network_engineer": "Netwerkingenieur",
            "telecommunications_technician": "Telecommunicatietechnicus",
            "systems_administrator": "Systeembeheerder",
            "rf_engineer": "RF-ingenieur",
            "telecom_analyst": "Telecomanalist",
            "customer_support_specialist": "Specialist voor klantenondersteuning",
            "sales_engineer": "Handelsingenieur",
            "telecommunications_manager": "Telecommunicatiemanager",
            "voip_engineer": "VoIP-ingenieur",
            "field_service_technician": "Technicus voor buitendienst"
          }
        },
        "transportation": {
          "label": "Transport",
          "roles": {
            "transportation_manager": "Transportmanager",
            "fleet_manager": "Wagenparkbeheerder",
            "logistics_coordinator": "Logistiek coördinator",
            "traffic_analyst": "Verkeersanalist",
            "transportation_planner": "Transportplanner",
            "dispatch_supervisor": "Toezichthouder verzendingen",
            "supply_chain_manager": "Supply Chain Manager",
            "safety_coordinator": "Veiligheidscoördinator",
            "public_transit_manager": "Beheerder openbaar vervoer",
            "vehicle_maintenance_manager": "Manager voertuigonderhoud"
          }
        },
        "utilities": {
          "label": "Nutsvoorzieningen",
          "roles": {
            "utility_manager": "Beheerder van nutsvoorzieningen",
            "electrical_engineer": "Elektrotechnisch ingenieur",
            "water_treatment_specialist": "Specialist in waterbehandeling",
            "utility_analyst": "Analist nutsvoorzieningen",
            "energy_efficiency_consultant": "Adviseur energie-efficiëntie",
            "environmental_compliance_specialist": "Specialist in naleving van milieuvoorschriften",
            "utility_field_technician": "Technicus op het gebied van nutsvoorzieningen",
            "gas_controller": "Gascontroleur",
            "operations_manager": "Operationeel manager",
            "utility_planner": "Planner nutsvoorzieningen"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Documentaanvraag maken met AI",
          "prompt_banner": "Voer de gegevens voor uw aanvraag in, zodat we een lijst met aanbevolen artikelen voor u kunnen genereren.",
          "generate_btn": "Items genereren",
          "review_banner": "Op basis van de verstrekte gegevens vindt u hier aanbevolen items voor uw aanvraag. Selecteer degene die u wilt toevoegen.",
          "creation_progress": "Documentaanvraag maken...",
          "generation_progress": "Items genereren voor uw aanvraag...",
          "creation_error": {
            "message": "Er is een fout opgetreden bij het maken van de documentaanvraag.",
            "description": "Probeer het opnieuw."
          },
          "generation_error": {
            "message": "Er is een fout opgetreden bij het genereren van de items voor uw aanvraag.",
            "description": "Probeer het opnieuw."
          },
          "generation_form": {
            "request_name": "Naam van de aanvraag",
            "due_date": "Einddatum",
            "request_purpose": "Wat is het doel van deze aanvraag?",
            "request_purpose_placeholder": "Dingen die u kunt zeggen:\n- Verzamel documenten om een nieuwe medewerker te onboarden.\n- Vraag persoonlijke documenten aan, zoals identiteitsbewijzen, socialezekerheidsdocumenten en andere relevante documenten.\n Vermijd het opnemen van persoonsgegevens (bijv. naam, e-mailadres).",
            "employment_industry": "In welke sector werkt u?",
            "employment_role": "Wat is uw rol in de sector?",
            "remember_industry_role": "Onthoud sector en rol"
          },
          "create_btn": "Aanvraag maken"
        }
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "common": {
        "actions": "Ações",
        "ai_caution": "As recomendações da IA podem não ser precisas. Verifique os itens gerados.",
        "all_categories": "Todas as categorias",
        "back": "Voltar",
        "beta": "Beta",
        "type_or_select_one": "Digite ou selecione um",
        "cancel": "Cancelar",
        "close": "Fechar",
        "create": "Criar",
        "created_by": "Criado por",
        "created_on": "Criado em",
        "delete": "Excluir",
        "description": "Descrição",
        "details": "Detalhes",
        "edit_details": "Editar detalhes",
        "more": "Mais",
        "my_templates": "Meus modelos",
        "name": "Nome",
        "oops": "Ops!",
        "optional": "opcional",
        "preview": "Visualizar",
        "retry": "Tentar novamente",
        "search": "Pesquisar",
        "select": "Selecionar",
        "shared_with_me": "Compartilhado comigo",
        "start_over": "Começar do início",
        "stop": "Parar",
        "template": "Modelo",
        "templates": "Modelos",
        "unknown": "desconhecido",
        "was_this_helpful": "Isso foi útil?"
      },
      "package_editor": {
        "errors": {
          "err_loading_resource": "Ocorreu um erro ao carregar o conteúdo. Tente novamente.",
          "no_resources": "Nenhum conteúdo para mostrar."
        }
      },
      "industries": {
        "accounting": {
          "label": "Contabilidade",
          "roles": {
            "auditor": "Auditor",
            "tax_accountant": "Contador fiscal",
            "financial_analyst": "Analista financeiro",
            "chief_financial_officer__cfo_": "Diretor Executivo Financeiro (CFO)",
            "forensic_accountant": "Contador forense",
            "accounts_payable_specialist": "Especialista em contas a pagar",
            "accounts_receivable_specialist": "Especialista em contas a receber",
            "internal_auditor": "Auditor interno",
            "controller": "Controlador",
            "budget_analyst": "Analista de orçamento"
          }
        },
        "agriculture": {
          "label": "Agricultura",
          "roles": {
            "farm_manager": "Gerente de fazenda",
            "agronomist": "Agrônomo",
            "agricultural_engineer": "Engenheiro agrônomo",
            "crop_scientist": "Cientista agrícola",
            "livestock_manager": "Gerente de pecuária",
            "soil_scientist": "Cientista do solo",
            "agricultural_consultant": "Consultor agrícola",
            "irrigation_specialist": "Especialista em irrigação",
            "agribusiness_manager": "Gerente de agronegócio",
            "agricultural_technician": "Técnico agrícola"
          }
        },
        "apparel": {
          "label": "Vestuário",
          "roles": {
            "fashion_designer": "Designer de moda",
            "merchandiser": "Comerciante",
            "production_manager": "Gerente de produção",
            "textile_technologist": "Tecnólogo têxtil",
            "fashion_buyer": "Comprador de moda",
            "pattern_maker": "Criador de padrões",
            "apparel_product_developer": "Desenvolvedor de produtos de vestuário",
            "fashion_illustrator": "Ilustrador de moda",
            "quality_control_inspector": "Inspetor de controle de qualidade",
            "fashion_marketing_specialist": "Especialista em marketing de moda"
          }
        },
        "banking": {
          "label": "Serviços bancários",
          "roles": {
            "investment_banker": "Banqueiro de investimento",
            "loan_officer": "Diretor de empréstimo",
            "financial_advisor": "Consultor financeiro",
            "branch_manager": "Gerente de filial",
            "risk_manager": "Gerente de risco",
            "credit_analyst": "Analista de crédito",
            "relationship_manager": "Gerente de relacionamento",
            "compliance_officer": "Diretor de conformidade",
            "wealth_manager": "Gerente de patrimônio",
            "treasury_analyst": "Analista de tesouraria"
          }
        },
        "biotechnology": {
          "label": "Biotecnologia",
          "roles": {
            "research_scientist": "Cientista pesquisador",
            "bioprocess_engineer": "Engenheiro de bioprocessos",
            "regulatory_affairs_specialist": "Especialista em assuntos regulatórios",
            "bioinformatics_specialist": "Especialista em bioinformática",
            "clinical_research_associate": "Associado de pesquisa clínica",
            "biotechnology_technician": "Técnico em biotecnologia",
            "quality_assurance_specialist": "Especialista em garantia de qualidade",
            "biomedical_engineer": "Engenheiro biomédico",
            "genetic_counselor": "Conselheiro genético",
            "biostatistician": "Bioestatístico"
          }
        },
        "chemicals": {
          "label": "Produtos químicos",
          "roles": {
            "chemical_engineer": "Engenheiro químico",
            "process_chemist": "Químico de processos",
            "environmental_health_and_safety_specialist": "Especialista em saúde e segurança ambiental",
            "quality_control_manager": "Gerente de controle de qualidade",
            "production_supervisor": "Supervisor de produção",
            "r_d_scientist": "Cientista de P&D",
            "regulatory_compliance_specialist": "Especialista em conformidade regulatória",
            "analytical_chemist": "Químico analítico",
            "chemical_plant_operator": "Operador de fábrica de produtos químicos",
            "formulation_chemist": "Químico de formulação"
          }
        },
        "communications": {
          "label": "Comunicações",
          "roles": {
            "public_relations_specialist": "Especialista em relações públicas",
            "marketing_manager": "Gerente de marketing",
            "social_media_manager": "Gerente de mídias sociais",
            "corporate_communications_manager": "Gerente de comunicações corporativas",
            "content_strategist": "Estrategista de conteúdo",
            "communications_coordinator": "Coordenador de comunicação",
            "media_relations_specialist": "Especialista em relações com a mídia",
            "internal_communications_specialist": "Especialista em comunicação interna",
            "speechwriter": "Redator de discursos",
            "copywriter": "Redator"
          }
        },
        "construction": {
          "label": "Construção",
          "roles": {
            "project_manager": "Gerente de projeto",
            "civil_engineer": "Engenheiro civil",
            "architect": "Arquiteto",
            "construction_manager": "Gerente de construção",
            "quantity_surveyor": "Pesquisador quantitativo",
            "site_supervisor": "Supervisor de local",
            "structural_engineer": "Engenheiro estrutural",
            "building_inspector": "Inspetor de construção",
            "safety_manager": "Gerente de segurança",
            "estimator": "Estimador"
          }
        },
        "consulting": {
          "label": "Consultoria",
          "roles": {
            "management_consultant": "Consultor de gestão",
            "strategy_analyst": "Analista de estratégia",
            "it_consultant": "Consultor de TI",
            "human_resources_consultant": "Consultor de Recursos Humanos",
            "financial_consultant": "Consultor financeiro",
            "business_analyst": "Analista de negócios",
            "operations_consultant": "Consultor de operações",
            "marketing_consultant": "Consultor de marketing",
            "environmental_consultant": "Consultor ambiental",
            "legal_consultant": "Consultor jurídico"
          }
        },
        "e_commerce": {
          "label": "Comércio eletrônico",
          "roles": {
            "e_commerce_manager": "Gerente de comércio eletrônico",
            "digital_marketing_specialist": "Especialista em marketing digital",
            "customer_experience_manager": "Gerente de experiência do cliente",
            "marketplace_analyst": "Analista de mercado",
            "supply_chain_manager": "Gerente da cadeia de suprimentos",
            "product_manager": "Gerente de produto",
            "seo_specialist": "Especialista em SEO",
            "web_developer": "Desenvolvedor Web",
            "data_analyst": "Analista de dados",
            "content_manager": "Gerente de conteúdo"
          }
        },
        "education": {
          "label": "Educação",
          "roles": {
            "teacher___educator": "Professor/educador",
            "school_administrator": "Administrador escolar",
            "curriculum_developer": "Desenvolvedor de currículo",
            "education_consultant": "Consultor educacional",
            "instructional_designer": "Designer instrucional",
            "academic_advisor": "Consultor acadêmico",
            "educational_psychologist": "Psicólogo educacional",
            "special_education_teacher": "Professor de educação especial",
            "school_counselor": "Conselheiro escolar",
            "librarian": "Bibliotecário"
          }
        },
        "electronics": {
          "label": "Eletrônicos",
          "roles": {
            "electrical_engineer": "Engenheiro elétrico",
            "hardware_designer": "Designer de hardware",
            "semiconductor_technician": "Técnico de semicondutores",
            "embedded_systems_engineer": "Engenheiro de sistemas incorporados",
            "robotics_engineer": "Engenheiro de robótica",
            "electronics_technician": "Técnico em eletrônica",
            "pcb_designer": "Designer de PCB",
            "systems_engineer": "Engenheiro de sistemas",
            "quality_assurance_engineer": "Engenheiro de garantia de qualidade",
            "product_development_engineer": "Engenheiro de desenvolvimento de produto"
          }
        },
        "energy": {
          "label": "Energia",
          "roles": {
            "energy_analyst": "Analista de energia",
            "power_plant_engineer": "Engenheiro de usina elétrica",
            "renewable_energy_specialist": "Especialista em energia renovável",
            "energy_trader": "Comerciante de energia",
            "environmental_specialist": "Especialista ambiental",
            "energy_consultant": "Consultor de energia",
            "energy_manager": "Gerente de energia",
            "petroleum_engineer": "Engenheiro petrolífero",
            "solar_energy_technician": "Técnico em energia solar",
            "wind_turbine_technician": "Técnico de turbina eólica"
          }
        },
        "engineering": {
          "label": "Engenharia",
          "roles": {
            "mechanical_engineer": "Engenheiro mecânico",
            "civil_engineer": "Engenheiro civil",
            "aerospace_engineer": "Engenheiro aeroespacial",
            "chemical_engineer": "Engenheiro químico",
            "electrical_engineer": "Engenheiro elétrico",
            "industrial_engineer": "Engenheiro industrial",
            "software_engineer": "Engenheiro de software",
            "structural_engineer": "Engenheiro estrutural",
            "environmental_engineer": "Engenheiro ambiental",
            "biomedical_engineer": "Engenheiro biomédico"
          }
        },
        "entertainment": {
          "label": "Entretenimento",
          "roles": {
            "actor___actress": "Ator/atriz",
            "film_director": "Diretor de cinema",
            "producer": "Produtor",
            "screenwriter": "Roteirista",
            "entertainment_lawyer": "Advogado de entretenimento",
            "casting_director": "Diretor de elenco",
            "cinematographer": "Cineasta",
            "sound_engineer": "Engenheiro de som",
            "production_designer": "Designer de produção",
            "talent_agent": "Agente de talentos"
          }
        },
        "environmental": {
          "label": "Ambiental",
          "roles": {
            "environmental_scientist": "Cientista ambiental",
            "environmental_engineer": "Engenheiro ambiental",
            "sustainability_consultant": "Consultor de sustentabilidade",
            "ecologist": "Ecologista",
            "environmental_compliance_manager": "Gerente de conformidade ambiental",
            "conservation_scientist": "Cientista de conservação",
            "environmental_health_and_safety_specialist": "Especialista em saúde e segurança ambiental",
            "environmental_planner": "Planejador ambiental",
            "environmental_educator": "Educador ambiental",
            "wildlife_biologist": "Biólogo da vida selvagem"
          }
        },
        "finance": {
          "label": "Finanças",
          "roles": {
            "financial_analyst": "Analista financeiro",
            "investment_banker": "Banqueiro de investimento",
            "portfolio_manager": "Gerente de portfólio",
            "financial_planner": "Planejador financeiro",
            "risk_analyst": "Analista de risco",
            "credit_analyst": "Analista de crédito",
            "compliance_officer": "Diretor de conformidade",
            "treasury_analyst": "Analista de tesouraria",
            "financial_controller": "Controlador financeiro",
            "budget_analyst": "Analista de orçamento"
          }
        },
        "food___beverage": {
          "label": "Alimentação e bebidas",
          "roles": {
            "chef": "Chef",
            "food_scientist": "Cientista de alimentos",
            "nutritionist": "Nutricionista",
            "food_safety_manager": "Gerente de segurança alimentar",
            "restaurant_manager": "Gerente de restaurante",
            "beverage_manager": "Gerente de bebidas",
            "pastry_chef": "Confeiteiro",
            "sommelier": "Sommelier",
            "food_technologist": "Tecnólogo de alimentos",
            "quality_assurance_manager": "Gerente de garantia de qualidade"
          }
        },
        "government": {
          "label": "Governo",
          "roles": {
            "policy_analyst": "Analista de políticas",
            "civil_servant": "Funcionário público",
            "diplomat": "Diplomata",
            "government_relations_specialist": "Especialista em relações governamentais",
            "legislative_assistant": "Assistente legislativo",
            "public_administrator": "Administrador público",
            "city_planner": "Planejador urbano",
            "budget_analyst": "Analista de orçamento",
            "intelligence_analyst": "Analista de inteligência",
            "public_affairs_specialist": "Especialista em relações públicas"
          }
        },
        "graphic_design": {
          "label": "Design gráfico",
          "roles": {
            "graphic_designer": "Designer gráfico",
            "art_director": "Diretor de arte",
            "illustrator": "Ilustrador",
            "ux_ui_designer": "Designer de UX/UI",
            "branding_specialist": "Especialista em identidade visual",
            "creative_director": "Diretor criativo",
            "web_designer": "Web designer",
            "motion_graphics_designer": "Designer de animação",
            "package_designer": "Designer de pacotes",
            "visual_designer": "Designer visual"
          }
        },
        "healthcare": {
          "label": "Assistência médica",
          "roles": {
            "physician": "Médico",
            "nurse_practitioner": "Enfermeira",
            "healthcare_administrator": "Administrador de saúde",
            "medical_researcher": "Pesquisador médico",
            "pharmacist": "Farmacêutico",
            "medical_technologist": "Tecnólogo médico",
            "physical_therapist": "Fisioterapeuta",
            "occupational_therapist": "Terapeuta ocupacional",
            "registered_nurse": "Enfermeira registrada",
            "radiologic_technologist": "Tecnólogo radiológico"
          }
        },
        "hospitality": {
          "label": "Hospitalidade",
          "roles": {
            "hotel_manager": "Gerente do hotel",
            "event_planner": "Planejador de eventos",
            "restaurant_manager": "Gerente de restaurante",
            "hospitality_consultant": "Consultor de hospitalidade",
            "concierge": "Concierge",
            "front_desk_manager": "Gerente de recepção",
            "housekeeping_manager": "Gerente de limpeza",
            "food_and_beverage_manager": "Gerente de alimentos e bebidas",
            "banquet_manager": "Gerente de banquetes",
            "guest_services_manager": "Gerente de serviços ao hóspede"
          }
        },
        "insurance": {
          "label": "Seguro",
          "roles": {
            "actuary": "Atuário",
            "insurance_underwriter": "Fornecedor de seguros",
            "claims_adjuster": "Ajustador de reivindicações",
            "risk_manager": "Gerente de risco",
            "insurance_broker": "Corretor de seguros",
            "insurance_agent": "Agente de seguros",
            "loss_control_specialist": "Especialista em controle de perdas",
            "claims_examiner": "Examinador de reivindicações",
            "compliance_officer": "Diretor de conformidade",
            "customer_service_representative": "Representante de atendimento ao cliente"
          }
        },
        "legal": {
          "label": "Legal",
          "roles": {
            "lawyer": "Advogado",
            "legal_secretary": "Secretário jurídico",
            "paralegal": "Paralegal",
            "judge": "Juiz",
            "legal_counsel": "Consultor jurídico",
            "corporate_attorney": "Advogado corporativo",
            "litigation_attorney": "Advogado de litígio",
            "legal_assistant": "Assistente legal",
            "compliance_officer": "Diretor de conformidade",
            "court_clerk": "Oficial de justiça"
          }
        },
        "machinery": {
          "label": "Maquinário",
          "roles": {
            "mechanical_engineer": "Engenheiro mecânico",
            "maintenance_technician": "Técnico de manutenção",
            "production_manager": "Gerente de produção",
            "quality_control_engineer": "Engenheiro de controle de qualidade",
            "operations_manager": "Gerente de operações",
            "cnc_machinist": "Maquinista de CNC",
            "manufacturing_engineer": "Engenheiro de fabricação",
            "field_service_technician": "Técnico de serviço de campo",
            "tool_and_die_maker": "Fabricante de ferramentas e matrizes",
            "equipment_operator": "Operador de equipamento"
          }
        },
        "manufacturing": {
          "label": "Fabricação",
          "roles": {
            "production_manager": "Gerente de produção",
            "quality_assurance_manager": "Gerente de garantia de qualidade",
            "supply_chain_manager": "Gerente da cadeia de suprimentos",
            "industrial_engineer": "Engenheiro industrial",
            "manufacturing_engineer": "Engenheiro de fabricação",
            "operations_manager": "Gerente de operações",
            "maintenance_manager": "Gerente de manutenção",
            "process_engineer": "Engenheiro de processos",
            "plant_manager": "Gerente da fábrica",
            "production_planner": "Planejador de produção"
          }
        },
        "marketing": {
          "label": "Marketing",
          "roles": {
            "marketing_manager": "Gerente de marketing",
            "digital_marketing_specialist": "Especialista em marketing digital",
            "market_research_analyst": "Analista de pesquisa de mercado",
            "brand_manager": "Gerente de marca",
            "public_relations_specialist": "Especialista em relações públicas",
            "social_media_manager": "Gerente de mídias sociais",
            "content_marketing_specialist": "Especialista em marketing de conteúdo",
            "marketing_coordinator": "Coordenador de marketing",
            "seo_specialist": "Especialista em SEO",
            "email_marketing_specialist": "Especialista em marketing por email"
          }
        },
        "media": {
          "label": "Mídia",
          "roles": {
            "journalist": "Jornalista",
            "editor": "Editor",
            "tv_producer": "Produtor de TV",
            "media_buyer": "Comprador de mídia",
            "multimedia_artist": "Artista multimídia",
            "broadcast_technician": "Técnico de transmissão",
            "news_anchor": "Âncora de notícias",
            "copywriter": "Redator",
            "video_editor": "Editor de vídeo",
            "social_media_manager": "Gerente de mídias sociais"
          }
        },
        "mining": {
          "label": "Mineração",
          "roles": {
            "geologist": "Geólogo",
            "mining_engineer": "Engenheiro de mineração",
            "environmental_specialist__mining_": "Especialista em meio ambiente (mineração)",
            "safety_manager__mining_": "Gerente de segurança (mineração)",
            "mine_operations_manager": "Gerente de operações de minas",
            "drilling_engineer": "Engenheiro de perfuração",
            "metallurgist": "Metalúrgico",
            "mine_surveyor": "Topógrafo de minas",
            "mineral_processing_engineer": "Engenheiro de processamento mineral",
            "maintenance_planner": "Planejador de manutenção"
          }
        },
        "not_for_profit": {
          "label": "Sem fins lucrativos",
          "roles": {
            "program_manager": "Gerenciador de programas",
            "fundraiser": "Arrecadador de fundos",
            "advocacy_coordinator": "Coordenador de advocacia",
            "grant_writer": "Escritor de proposta de subsídio",
            "volunteer_coordinator": "Coordenador voluntário",
            "community_outreach_manager": "Gerente de divulgação comunitária",
            "development_director": "Diretor de desenvolvimento",
            "nonprofit_administrator": "Administrador sem fins lucrativos",
            "social_worker": "Assistente social",
            "communications_director": "Diretor de comunicações"
          }
        },
        "pharmaceuticals": {
          "label": "Produtos farmacêuticos",
          "roles": {
            "pharmaceutical_sales_representative": "Representante de vendas farmacêuticas",
            "research_scientist": "Cientista pesquisador",
            "regulatory_affairs_specialist": "Especialista em assuntos regulatórios",
            "clinical_trial_manager": "Gerente de ensaios clínicos",
            "pharmacologist": "Farmacologista",
            "quality_control_analyst": "Analista de controle de qualidade",
            "formulation_scientist": "Cientista de formulação",
            "medical_science_liaison": "Ligação médica científica",
            "drug_safety_specialist": "Especialista em segurança de medicamentos",
            "biostatistician": "Bioestatístico"
          }
        },
        "real_estate": {
          "label": "Imóveis",
          "roles": {
            "real_estate_agent": "Corretor de imóveis",
            "property_manager": "Gerente de propriedades",
            "real_estate_developer": "Desenvolvedor imobiliário",
            "appraiser": "Avaliador",
            "leasing_consultant": "Consultor de locação",
            "real_estate_broker": "Corretor de imóveis",
            "commercial_real_estate_agent": "Agente imobiliário comercial",
            "real_estate_analyst": "Analista imobiliário",
            "real_estate_marketing_specialist": "Especialista em marketing imobiliário",
            "title_examiner": "Examinador de títulos"
          }
        },
        "recreation": {
          "label": "Recreação",
          "roles": {
            "recreation_director": "Diretor de recreação",
            "event_coordinator": "Coordenador de eventos",
            "park_ranger": "Guarda florestal",
            "fitness_instructor": "Instrutor de fitness",
            "sports_coach": "Treinador esportivo",
            "recreation_therapist": "Terapeuta recreativo",
            "camp_director": "Diretor do acampamento",
            "outdoor_education_instructor": "Instrutor de educação ao ar livre",
            "aquatics_director": "Diretor de esportes aquáticos",
            "adventure_guide": "Guia de aventura"
          }
        },
        "retail": {
          "label": "Varejo",
          "roles": {
            "store_manager": "Gerente de loja",
            "sales_associate": "Vendedor",
            "merchandiser": "Comerciante",
            "retail_buyer": "Comprador de varejo",
            "visual_merchandiser": "Visual Merchandiser",
            "inventory_manager": "Gerente de estoque",
            "customer_service_representative": "Representante de atendimento ao cliente",
            "cashier": "Caixa",
            "retail_manager": "Gerente de varejo",
            "loss_prevention_specialist": "Especialista em prevenção de perdas"
          }
        },
        "shipping": {
          "label": "Envio",
          "roles": {
            "logistics_manager": "Gerente de logística",
            "shipping_coordinator": "Coordenador de envios",
            "port_operations_manager": "Gerente de operações portuárias",
            "freight_forwarder": "Despachante",
            "customs_broker": "Despachante aduaneiro",
            "shipping_clerk": "Encarregado de envio",
            "marine_engineer": "Engenheiro naval",
            "cargo_inspector": "Inspetor de carga",
            "supply_chain_analyst": "Analista de cadeia de suprimentos",
            "deck_officer": "Oficial de convés"
          }
        },
        "technology": {
          "label": "Tecnologia",
          "roles": {
            "software_engineer": "Engenheiro de software",
            "data_scientist": "Cientista de dados",
            "systems_analyst": "Analista de sistemas",
            "it_project_manager": "Gerente de projetos de TI",
            "network_administrator": "Administrador de rede",
            "cybersecurity_analyst": "Analista de segurança cibernética",
            "devops_engineer": "Engenheiro de DevOps",
            "product_manager": "Gerente de produto",
            "technical_support_specialist": "Especialista em suporte técnico",
            "cloud_solutions_architect": "Arquiteto de soluções em nuvem"
          }
        },
        "telecommunications": {
          "label": "Telecomunicações",
          "roles": {
            "network_engineer": "Engenheiro de rede",
            "telecommunications_technician": "Técnico de telecomunicações",
            "systems_administrator": "Administrador de sistemas",
            "rf_engineer": "Engenheiro de RF",
            "telecom_analyst": "Analista de telecomunicações",
            "customer_support_specialist": "Especialista em suporte ao cliente",
            "sales_engineer": "Engenheiro de vendas",
            "telecommunications_manager": "Gerente de telecomunicações",
            "voip_engineer": "Engenheiro de VoIP",
            "field_service_technician": "Técnico de serviço de campo"
          }
        },
        "transportation": {
          "label": "Transporte",
          "roles": {
            "transportation_manager": "Gerente de transporte",
            "fleet_manager": "Gerente de frota",
            "logistics_coordinator": "Coordenador de logística",
            "traffic_analyst": "Analista de tráfego",
            "transportation_planner": "Planejador de transporte",
            "dispatch_supervisor": "Supervisor de expedição",
            "supply_chain_manager": "Gerente da cadeia de suprimentos",
            "safety_coordinator": "Coordenador de segurança",
            "public_transit_manager": "Gerente de transporte público",
            "vehicle_maintenance_manager": "Gerente de manutenção de veículos"
          }
        },
        "utilities": {
          "label": "Serviços públicos",
          "roles": {
            "utility_manager": "Gerente de serviços públicos",
            "electrical_engineer": "Engenheiro elétrico",
            "water_treatment_specialist": "Especialista em tratamento de água",
            "utility_analyst": "Analista de serviços públicos",
            "energy_efficiency_consultant": "Consultor de eficiência energética",
            "environmental_compliance_specialist": "Especialista em conformidade ambiental",
            "utility_field_technician": "Técnico de campo de serviços públicos",
            "gas_controller": "Controlador de gás",
            "operations_manager": "Gerente de operações",
            "utility_planner": "Planejador de serviços públicos"
          }
        }
      },
      "use_resourcegen_drawer": {
        "sharefile_requestlist": {
          "title": "Criar solicitação de documento com IA",
          "prompt_banner": "Insira os detalhes da sua solicitação para que possamos gerar uma lista de itens recomendados para você.",
          "generate_btn": "Generate items",
          "review_banner": "Com base nos detalhes fornecidos, aqui estão os itens recomendados para sua solicitação. Selecione aqueles que você gostaria de adicionar.",
          "creation_progress": "Criando solicitação de documento...",
          "generation_progress": "Gerando itens para sua solicitação...",
          "creation_error": {
            "message": "Ocorreu um erro ao criar a solicitação de documento.",
            "description": "Tente novamente."
          },
          "generation_error": {
            "message": "Ocorreu um erro ao gerar os itens para sua solicitação.",
            "description": "Tente novamente."
          },
          "generation_form": {
            "request_name": "Nome da solicitação",
            "due_date": "Data de vencimento",
            "request_purpose": "Qual é o propósito dessa solicitação?",
            "request_purpose_placeholder": "Coisas que você pode dizer:\n- Colete documentos para integrar uma nova contratação.\n- Solicite documentos pessoais como identidade governamental, previdência social e outros documentos relevantes.\n Evite incluir quaisquer dados pessoais (por exemplo, nome, endereço de email).",
            "employment_industry": "Em qual setor você trabalha?",
            "employment_role": "Qual é a sua função no setor?",
            "remember_industry_role": "Lembrar setor e função"
          },
          "create_btn": "Criar solicitação"
        }
      }
    }
  }
];